import { AddOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Fade,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { IDailyContentPlaylist } from "../../../../types/NendaTypes";
import ConfirmationDialog from "../ConfirmationDialog";
import CreateEditPlaylist from "../components/DailyPlaylist/CreateEditPlaylist";
import PlaylistsTable from "../components/DailyPlaylist/PlaylistsTable";
import { isAllTimeIntervalsValidWithoutOverlaps } from "../components/DailyPlaylist/helpers/validation";
import { CustomerPortalState, store } from "../store";
import {
  createPlaylist,
  deletePlaylist,
  getPlaylists,
  selectCreateEditPlaylistModalIsOpen,
  selectDeletePlaylist,
  selectEditPlaylist,
  selectPlaylistsByPremiseId,
  setCreateEditPlaylistModalIsOpen,
  setDeletePlaylist,
  setEditPlaylist,
  updatePlaylist,
} from "../store/reducers/dailyContentPlaylistReducer";
import {
  selectNavigatedPremiseId,
  selectNavigationScope,
} from "../store/reducers/workspaceReducer";
import DefaultDialog from "../ui-components/dialog/dialog";
import { selectContentChannelsByNavigatedPremise } from "../store/reducers/contentChannelReducer";
import {
  getCompanySignagePlaylists,
  getPremiseSignagePlaylists,
  selectSignagePlaylistsByNavigationLevel,
} from "../store/reducers/signagePlaylistReducer";
import HelpWidget from "../ui-components/display/HelpWidget";

const DailyPlaylists: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const premise = useSelector(selectNavigatedPremiseId);
  const scope = useSelector(selectNavigationScope);
  const playlists = useSelector((state: CustomerPortalState) =>
    selectPlaylistsByPremiseId(state, premise || "")
  );
  const { dispatch } = store;
  const createModalIsOpen = useSelector(selectCreateEditPlaylistModalIsOpen);
  const editPlaylistId = useSelector(selectEditPlaylist);
  const selectedPlaylistToEdit = playlists.find(
    (p) => p._id === editPlaylistId
  );
  const deletePlaylistId = useSelector(selectDeletePlaylist);

  const initPlaylist = useMemo(
    () => ({
      name: "",
      premise: premise,
      timeline: [],
    }),
    [premise]
  );

  const [playlist, setPlaylist] = useState<IDailyContentPlaylist>(initPlaylist);

  useEffect(() => {
    if (playlist._id !== selectedPlaylistToEdit?._id) {
      setPlaylist(selectedPlaylistToEdit || initPlaylist);
    }
  }, [selectedPlaylistToEdit, playlist._id, initPlaylist]);

  const playlistCreateEditIsValid = useMemo(() => {
    return !!playlist.name && !!playlist.premise && !!playlist.timeline;
  }, [playlist]);

  const handleCreateEditPlaylistModalClose = ({
    isConfirmed,
  }: {
    isConfirmed: boolean;
  }) => {
    if (isConfirmed && playlistCreateEditIsValid) {
      if (selectedPlaylistToEdit?._id)
        return dispatch(
          updatePlaylist({ ...playlist, _id: selectedPlaylistToEdit?._id })
        );
      if (!selectedPlaylistToEdit?._id)
        return dispatch(createPlaylist({ ...playlist }));
    }
    dispatch(setCreateEditPlaylistModalIsOpen(false));
    dispatch(setEditPlaylist(null));
    setPlaylist(initPlaylist);
    return;
  };

  const handleCreatePlaylistModalOpen = () => {
    dispatch(setCreateEditPlaylistModalIsOpen(true));
  };

  const handleDeletePlaylist = (isConfirmed: boolean) => {
    if (!premise || !deletePlaylistId) return;
    if (isConfirmed) {
      dispatch(
        deletePlaylist({ premiseId: premise, playlistId: deletePlaylistId })
      );
    } else {
      dispatch(setDeletePlaylist(null));
    }
  };

  useEffect(() => {
    const premisePlaylists = playlists.filter((p) => p.premise === premise);
    if (premisePlaylists.length > 0 || !premise) return;

    dispatch(getPlaylists(premise));
  }, [premise, playlists, dispatch]);

  const playlistIsValid = useMemo(() => {
    if (!playlist.timeline) return false;

    return (
      isAllTimeIntervalsValidWithoutOverlaps(
        playlist.timeline.map((t) => t.interval)
      ) && playlistCreateEditIsValid
    );
  }, [playlist.timeline, playlistCreateEditIsValid]);
  const signagePlaylists = useSelector(selectSignagePlaylistsByNavigationLevel);
  const contentChannels = useSelector(selectContentChannelsByNavigatedPremise);

  useEffect(() => {
    if (scope.company) {
      store.dispatch(getCompanySignagePlaylists(scope.company));
    }
    if (scope.premise) {
      store.dispatch(getPremiseSignagePlaylists(scope.premise));
    }
  }, [scope.company, scope.premise]);
  return (
    <Fade in={true}>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Stack
              direction={"row"}
              gap="2rem"
              alignItems={"center"}
              mb="2rem"
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                  gap: "1rem",
                },
              })}
            >
              <Typography
                variant="h3"
                sx={(theme) => ({
                  textTransform: "capitalize",
                  color: theme.palette.text.primary,
                })}
              >
                {t("customerportal.pages.daily_playlists.title")}
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddOutlined />}
                onClick={handleCreatePlaylistModalOpen}
              >
                {t("customerportal.pages.daily_playlists.create_playlist")}
              </Button>
            </Stack>
            <Typography variant="body1" maxWidth={"30rem"}>
              {t("customerportal.pages.daily_playlists.helper_text")}
            </Typography>
            <DefaultDialog
              width="80vw"
              open={createModalIsOpen || !!editPlaylistId}
              disabled={!playlistIsValid}
              onClose={handleCreateEditPlaylistModalClose}
              title={
                editPlaylistId
                  ? (t(
                      "customerportal.pages.daily_playlists.edit_playlist"
                    ) as string)
                  : (t(
                      "customerportal.pages.daily_playlists.create_new_playlist"
                    ) as string)
              }
            >
              <CreateEditPlaylist
                playlistState={playlist}
                setPlaylistState={setPlaylist}
                contentChannels={contentChannels}
                signagePlaylists={signagePlaylists}
              />
            </DefaultDialog>
            <ConfirmationDialog
              title={t(
                "customerportal.pages.daily_playlists.delete_confirmation.title"
              )}
              isOpen={!!deletePlaylistId}
              onClose={handleDeletePlaylist}
              content={t(
                "customerportal.pages.daily_playlists.delete_confirmation.description"
              )}
            />
          </Box>
          <Box>
            <HelpWidget
              title="Help"
              details={
                <Box>
                  <Typography variant="body2">
                    <strong>
                      {t("customerportal.pages.daily_playlists.help.title")}
                    </strong>
                  </Typography>
                  <Typography variant="body1">
                    {t("customerportal.pages.daily_playlists.help.section_one")}
                  </Typography>
                  <Typography variant="body1">
                    {t("customerportal.pages.daily_playlists.help.section_two")}{" "}
                    <Link href={`/scheduling/schedule/premise/${premise}`}>
                      {t("customerportal.pages.daily_playlists.help.link")}
                    </Link>
                  </Typography>
                </Box>
              }
            />
          </Box>
          {/* Modal */}
        </Box>
        {/* Main */}
        <PlaylistsTable dailyPlaylists={playlists} />
      </Box>
    </Fade>
  );
};

export default DailyPlaylists;
