import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { IRoom } from "@nendaTypes/NendaTypes";
import { t } from "i18next";

const PlayContentModalScreenSelect = ({
  selectedScreens,
  setSelectedScreens,
  screens,
}: {
  selectedScreens: string[];
  setSelectedScreens: (value: string[]) => void;
  screens: IRoom[];
}) => {
  //Divide screens into 2 columns
  const halfLength = Math.ceil(screens.length / 2);
  const firstHalf = screens.slice(0, halfLength);
  const secondHalf = screens.slice(halfLength, screens.length);

  const handleToggle = (value: string) => {
    const currentIndex = selectedScreens.indexOf(value);
    const newChecked = [...selectedScreens];

    if (currentIndex === -1) {
      newChecked.push(value.toString());
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedScreens(newChecked);
  };

  const handleToggleAll = () => {
    if (selectedScreens.length === screens.length) {
      setSelectedScreens([]);
    } else {
      setSelectedScreens(screens.map((screen) => screen._id));
    }
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button variant={"outlined"} onClick={handleToggleAll}>
          {t("common.select_all")}
        </Button>
      </Box>
      <Grid container spacing={2} columns={{ xs: 1, md: 2 }}>
        <Grid item xs={1}>
          <ScreenColumnLists
            items={firstHalf}
            onScreenSelect={handleToggle}
            selectedScreens={selectedScreens}
          />
        </Grid>
        <Grid item xs={1}>
          <ScreenColumnLists
            items={secondHalf}
            onScreenSelect={handleToggle}
            selectedScreens={selectedScreens}
          />
        </Grid>
      </Grid>
    </>
  );
};

const ScreenColumnLists = ({
  items,
  onScreenSelect,
  selectedScreens,
}: {
  items: IRoom[];
  onScreenSelect: (value: string) => void;
  selectedScreens: string[];
}) => {
  return (
    <List
      dense
      dir="ltr"
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
    >
      {items.map((item) => {
        const labelId = `${item.name || item._id}`;
        const onScreenClick = () => {
          return onScreenSelect(item._id);
        };
        return (
          <ListItem key={item._id} onClick={onScreenClick}>
            <ListItemButton>
              <Checkbox
                edge="start"
                checked={selectedScreens.includes(item._id)}
                inputProps={{ "aria-labelledby": labelId }}
              />
              <ListItemText id={labelId} primary={`${item.name || item._id}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default PlayContentModalScreenSelect;
