import {
  Box,
  Button,
  Fade,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Selecto from "react-selecto";
import {
  IDailyContentPlaylist,
  LoadingStatus,
  NendaProduct,
} from "../../../../types/NendaTypes";
import { SetError } from "../../../redux/error.redux";
import AssignPlaylistModalContent from "../components/WeeklyPlanner/AssignPlaylistModalContent";
import WeekSchedule from "../components/WeeklyPlanner/WeekSchedule";
import { CustomerPortalState, store } from "../store";
import {
  ScreenSelection,
  createAndAssignPlaylist,
  getPlaylists,
  selectStatus,
} from "../store/reducers/dailyContentPlaylistReducer";
import {
  batchPutWeeklyScheduleForScreens,
  getIsLoading,
  getScreens,
  selectScreensByProductAndPremise,
} from "../store/reducers/screenReducer";
import {
  selectNavigatedPremiseId,
  selectNavigationScope,
} from "../store/reducers/workspaceReducer";
import DefaultDialog from "../ui-components/dialog/dialog";
import HelpWidget from "../ui-components/display/HelpWidget";
import {
  getCompanySignagePlaylists,
  getPremiseSignagePlaylists,
  selectSignagePlaylistsByNavigationLevel,
} from "../store/reducers/signagePlaylistReducer";
import { selectContentChannelsByNavigatedPremise } from "../store/reducers/contentChannelReducer";

export type SelectionAddedRemovedProps = {
  added: { id: string; day: number }[];
  removed: { id: string; day: number }[];
};

const WeeklyPlanner: React.FC = () => {
  const selectoRef = useRef<Selecto>(null);
  const scope = useSelector(selectNavigationScope);
  const screensIsLoading = useSelector(getIsLoading);
  const playlistLoadingStatus = useSelector(selectStatus);
  const premise = useSelector(selectNavigatedPremiseId);
  const [showSelectPlaylistModal, toggleShowSelectPlaylistModal] =
    useState(false);
  const [selectableScreens, setSelectableScreens] = useState<ScreenSelection[]>(
    []
  );

  const [playlistToAssign, setPlaylistToAssign] = useState<string | null>(null);
  const initPlaylist = {
    name: "",
    premise: premise,
    timeline: [],
  };
  const [playlist, setPlaylist] = useState<IDailyContentPlaylist>(initPlaylist);
  const allselectableScreens = useSelector((state: CustomerPortalState) =>
    selectScreensByProductAndPremise(state, NendaProduct.SIGNAGE, premise)
  );

  useEffect(() => {
    setSelectableScreens(
      allselectableScreens.map((s) => ({
        _id: s._id,
        name: s.name,
        selected: [],
        schedule: {
          0: s.signage.schedule.content?.weekly[0]?.toString() || "",
          1: s.signage.schedule.content?.weekly[1]?.toString() || "",
          2: s.signage.schedule.content?.weekly[2]?.toString() || "",
          3: s.signage.schedule.content?.weekly[3]?.toString() || "",
          4: s.signage.schedule.content?.weekly[4]?.toString() || "",
          5: s.signage.schedule.content?.weekly[5]?.toString() || "",
          6: s.signage.schedule.content?.weekly[6]?.toString() || "",
        },
      }))
    );
  }, [allselectableScreens]);

  const toggleSelectPlaylistHandler = () => {
    toggleShowSelectPlaylistModal(!showSelectPlaylistModal);
  };

  const handleSetPlaylistToAssign = (playlistId: string | null) => {
    setPlaylistToAssign(playlistId);
  };

  const validatePlaylist = () => {
    const errors: string[] = [];
    if (playlist.name === "") {
      errors.push("Name");
    }
    if (!playlist.premise) {
      errors.push("Premise");
    }
    if (!playlist.timeline.length) {
      errors.push("Channels");
    }
    return errors;
  };

  const handleSelectPlaylistClose = ({ isConfirmed }) => {
    const screensCopy = [...selectableScreens];

    if (isConfirmed && playlistToAssign) {
      store.dispatch(
        batchPutWeeklyScheduleForScreens(
          screensCopy.map((s) => {
            const updatedSchedule = { ...s.schedule };
            s.selected.forEach((day) => {
              updatedSchedule[day] = playlistToAssign;
            });
            Object.keys(updatedSchedule).forEach((key) => {
              if (!updatedSchedule[key]) {
                delete updatedSchedule[key];
              }
            });
            return {
              screenId: s._id,
              schedule: updatedSchedule,
            };
          })
        )
      );
    }

    const errors = validatePlaylist();

    if (isConfirmed && errors.length > 0 && !playlistToAssign) {
      store.dispatch(
        SetError(
          `Please fill in all the required fields before continuing: ${errors
            .map((e) => e)
            .join(" and ")} is required`
        )
      );
      return;
    }
    if (isConfirmed && !playlistToAssign) {
      const selectedScreens = screensCopy.filter((s) => s.selected.length > 0);

      store.dispatch(
        createAndAssignPlaylist({
          createPlaylist: playlist,
          selectedScreens,
        })
      );
    }

    setPlaylist(initPlaylist);
    selectoRef.current?.setSelectedTargets([]);
    toggleShowSelectPlaylistModal(false);
    setPlaylistToAssign(null);
  };

  const handleUnassignPlaylists = () => {
    const screenCopy = [...selectableScreens];

    const screensToUpdate = screenCopy.filter((s) => s.selected.length > 0);
    const updatePayload = screensToUpdate.map((s) => {
      const updatedSchedule = { ...s.schedule };
      s.selected.forEach((day) => {
        updatedSchedule[day] = "";
      });
      Object.keys(updatedSchedule).forEach((key) => {
        if (!updatedSchedule[key]) {
          delete updatedSchedule[key];
        }
      });
      return {
        screenId: s._id,
        schedule: updatedSchedule,
      };
    });

    store.dispatch(batchPutWeeklyScheduleForScreens(updatePayload));

    handleClearSelection();
  };

  const handleClearSelection = () => {
    const screenCopy = [...selectableScreens];
    screenCopy.forEach((s) => {
      s.selected = [];
    });
    setPlaylistToAssign(null);
    setSelectableScreens(screenCopy);
    selectoRef.current?.setSelectedTargets([]);
  };

  const onSelectChange = ({ added, removed }: SelectionAddedRemovedProps) => {
    const screensCopy = [...selectableScreens];
    added.forEach((item) => {
      const screen = screensCopy.find((s) => s._id === item.id);
      if (screen) {
        screen.selected.push(item.day);
      }
    });
    removed.forEach((item) => {
      const screen = screensCopy.find((s) => s._id === item.id);
      if (screen) {
        screen.selected = screen.selected.filter((d) => d !== item.day);
      }
    });
    setSelectableScreens(screensCopy);
  };

  useEffect(() => {
    if (!premise || allselectableScreens.length > 0 || screensIsLoading) return;

    store.dispatch(getScreens(premise));
  }, [premise]);
  const showActionBar = !!selectableScreens.find((s) => s.selected.length > 0);

  useEffect(() => {
    if (playlistLoadingStatus === LoadingStatus.LOADING || !premise) return;

    store.dispatch(getPlaylists(premise));
  }, [premise]);

  const signagePlaylists = useSelector(selectSignagePlaylistsByNavigationLevel);
  const contentChannels = useSelector(selectContentChannelsByNavigatedPremise);

  useEffect(() => {
    if (scope.company) {
      store.dispatch(getCompanySignagePlaylists(scope.company));
    }
    if (scope.premise) {
      store.dispatch(getPremiseSignagePlaylists(scope.premise));
    }
  }, [scope.company, scope.premise]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Stack
          mb="2rem"
          sx={(theme) => ({
            maxWidth: "50%",
            whiteSpace: "pre-line",
            [theme.breakpoints.down("md")]: { maxWidth: "100%" },
          })}
        >
          <Typography
            variant="h3"
            sx={(theme) => ({
              textTransform: "capitalize",
              color: theme.palette.text.primary,
              mb: "1rem",
            })}
          >
            {t("customerportal.pages.schedule.title")}
          </Typography>
          <Typography variant="body1">
            {t("customerportal.pages.schedule.description")}
          </Typography>
        </Stack>
        <Box>
          <HelpWidget
            title={t("common.help")}
            details={
              <Box>
                <Typography variant="body2">
                  <strong>
                    {t("customerportal.pages.schedule.help.title")}
                  </strong>
                </Typography>
                <br />
                <Typography variant="body1">
                  {t("customerportal.pages.schedule.help.section_one")}
                </Typography>
                <br />
                <Typography variant="body1">
                  {t("customerportal.pages.schedule.help.section_two")}
                </Typography>
                <br />
                <Typography variant="body1">
                  {t("customerportal.pages.schedule.help.section_three")}
                </Typography>
                <Typography variant="body1">
                  {t("customerportal.pages.schedule.help.section_four")}
                </Typography>
              </Box>
            }
          />
        </Box>
      </Box>
      <Stack mb="6rem">
        <WeekSchedule
          screens={selectableScreens}
          onChange={onSelectChange}
          selectoRef={selectoRef}
        />
      </Stack>
      <WeekScheduleActionBar
        open={showActionBar}
        handleClearSelection={handleClearSelection}
        showSetPlaylistModal={toggleSelectPlaylistHandler}
        onUnassignPlaylist={handleUnassignPlaylists}
      />
      <DefaultDialog
        width="55vw"
        open={showSelectPlaylistModal}
        onClose={handleSelectPlaylistClose}
        title="Select Playlist"
        disabled={
          playlistLoadingStatus === LoadingStatus.LOADING ||
          !premise ||
          (!playlist.timeline.length && !playlistToAssign)
        }
      >
        <AssignPlaylistModalContent
          onSetPlaylist={handleSetPlaylistToAssign}
          playlist={playlist}
          setPlaylist={setPlaylist}
          selectedPlaylistId={playlistToAssign}
          contentChannels={contentChannels}
          signagePlaylists={signagePlaylists}
        />
      </DefaultDialog>
    </Box>
  );
};

const WeekScheduleActionBar = ({
  open,
  handleClearSelection,
  showSetPlaylistModal,
  onUnassignPlaylist,
}: {
  open: boolean;
  handleClearSelection: () => void;
  showSetPlaylistModal: () => void;
  onUnassignPlaylist: () => void;
}) => {
  const buttonList = [
    {
      label: t("customerportal.pages.schedule.clear_selection"),
      onClick: handleClearSelection,
      variant: "text",
    },
    {
      label: t("customerportal.pages.schedule.unassign_playlist"),
      onClick: onUnassignPlaylist,
      variant: "text",
    },
    {
      label: t("customerportal.pages.schedule.assign_playlist"),
      onClick: showSetPlaylistModal,
      variant: "outlined",
    },
  ];

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        px: "2rem",
        py: "1rem",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {open && (
        <Fade in={open}>
          <Box
            sx={(theme) => ({
              display: "inline-flex",
              justifyContent: "flex-end",
              gap: "1rem",
              background: theme.palette.background.paper,
              borderRadius: "0.5rem",
              px: "1rem",
              py: "1rem",
              boxShadow: theme.shadows[24],
            })}
          >
            {buttonList.map((button, i) => {
              return (
                <Button
                  variant={button.variant as any}
                  onClick={button.onClick}
                  key={button.label + button.variant + i}
                >
                  {button.label}
                </Button>
              );
            })}
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default WeeklyPlanner;
