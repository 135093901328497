import {
  CollectionsOutlined,
  DateRangeOutlined,
  InfoOutlined,
  MovieFilterOutlined,
  SubscriptionsOutlined,
  SystemUpdateAltOutlined,
} from "@mui/icons-material";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { withoutTime } from "@shared/dateTimeUtils";
import { add } from "date-fns";
import { t } from "i18next";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ContentManagmentData,
  IRoom,
  LoadingStatus,
  NendaProduct,
  Resource,
  RoomTypeCategory,
  Scope,
} from "../../../../../../types/NendaTypes";
import { CustomerPortalState, store } from "../../../store";
import {
  GetCompanyAssets,
  GetPremiseAssets,
  selectAssetsBasedOnNavigationScope,
} from "../../../store/reducers/assetReducer";
import {
  GetContentByPremiseId,
  selectContentByPremiseId,
  selectPremiseById,
  selectRoomTypesByPremiseGroupId,
  SendContentOrder,
} from "../../../store/reducers/organizationUnitReducer";
import {
  batchGetTimelineForScreen,
  getScreens,
  selectScreensForPremise,
  selectTimelines,
} from "../../../store/reducers/screenReducer";
import { GetStreamChannels } from "../../../store/reducers/streamChannelReducer";
import {
  selectNavigatedCompanyId,
  selectNavigatedPremiseId,
} from "../../../store/reducers/workspaceReducer";
import { paletteLight } from "../../../theme/palette";
import ActionGrid, { ActionGridItemProps } from "../../ActionGrid";
import CreateEditPromotionModal from "../../Bookings/create/CreateEditPromotionModal";
import PermissionsGate from "../../PermissionGate";
import ContentManagerModal from "./components/streamingContentManager/ContentManagerModal";
import EditPremiseDialog from "./EditPremiseDialog";
import ScreenOverviewList from "./ScreenOverviewList";
import StreamingAppScreensList from "./StreamingAppScreensList";
import { TogglePromotionModal } from "@client/components/CustomerPortal/store/reducers/customizationReducer";

export type ContentManagerState = {
  selectedRooms: string[];
  selectedRoomCategory: RoomTypeCategory | undefined;
  selectedPackages: string[];
  acceptTerms: boolean;
  initialStep: number;
};

export type RoomWithContentAndType = IRoom & {
  packages: ContentManagmentData[] | [];
  type: RoomTypeCategory | undefined;
};

export const initialContentManagerState: ContentManagerState = {
  selectedRooms: [],
  selectedRoomCategory: undefined,
  selectedPackages: [],
  acceptTerms: false,
  initialStep: 0,
};

const PremiseDashboard = () => {
  const premiseId = useSelector(selectNavigatedPremiseId);
  const companyId = useSelector(selectNavigatedCompanyId);
  const premiseContent = useSelector((state: CustomerPortalState) =>
    selectContentByPremiseId(state, premiseId as string)
  );

  const premise = useSelector((state: CustomerPortalState) =>
    selectPremiseById(state, premiseId as string)
  );
  const [contentManagerState, setContentManagerState] =
    useState<ContentManagerState>(initialContentManagerState);

  const handleSetSelectedRooms = (selectedRooms: string[]) => {
    setContentManagerState((prevState) => ({
      ...prevState,
      selectedRooms,
    }));
  };
  const handleSetSelectedRoomCategory = (
    selectedRoomCategory: RoomTypeCategory
  ) => {
    setContentManagerState((prevState) => ({
      ...prevState,
      selectedRoomCategory,
      selectedPackages: [],
    }));
  };
  const baseProduct =
    contentManagerState.selectedRoomCategory &&
    premiseContent?.availablePackages[
      contentManagerState.selectedRoomCategory
    ]?.find((p) => p.isBaseProduct);

  const baseProductId = baseProduct?.id;

  if (baseProductId && contentManagerState.selectedPackages.length === 0) {
    setContentManagerState((prevState) => ({
      ...prevState,
      selectedPackages: [...prevState.selectedPackages, baseProductId],
    }));
  }

  const handleSetSelectedPackages = (selectedPackage: string) => {
    let packages: string[] = [];

    const selectedPackageIsBaseProduct =
      contentManagerState.selectedRoomCategory
        ? premiseContent?.availablePackages[
            contentManagerState.selectedRoomCategory
          ]?.find((p) => p.isBaseProduct && p.id === selectedPackage)
        : undefined;

    if (
      !selectedPackageIsBaseProduct &&
      contentManagerState.selectedPackages.includes(selectedPackage)
    ) {
      if (baseProductId === selectedPackage) return;
      packages = contentManagerState.selectedPackages.filter(
        (id) => id !== selectedPackage
      );
    } else if (selectedPackageIsBaseProduct) {
      //If a baseproduct is already selected, replace it with the new baseProduct
      packages = [
        ...contentManagerState.selectedPackages.filter(
          (id) =>
            !premiseContent?.availablePackages[
              contentManagerState.selectedRoomCategory || RoomTypeCategory.ROOM
            ]?.find((p) => p.isBaseProduct && p.id === id)
        ),
        selectedPackage,
      ];
    } else {
      packages = [...contentManagerState.selectedPackages, selectedPackage];
    }
    setContentManagerState((prevState) => ({
      ...prevState,
      selectedPackages: packages,
    }));
  };
  const handleAcceptTerms = (accept: boolean) => {
    setContentManagerState((prevState) => ({
      ...prevState,
      acceptTerms: accept,
    }));
  };

  const handleClearContentManagerState = () => {
    setContentManagerState(initialContentManagerState);
  };

  const signageEnabled = !!premise?.nendaProducts.includes(
    NendaProduct.SIGNAGE
  );
  const promotionsEnabled = !!premise?.signageConfig?.arePromotionsEnabled;

  const streamingEnabled = !!premise?.nendaProducts.includes(
    NendaProduct.STREAMING_APP
  );
  const [showContentManager, setShowContentManager] = useState(false);

  const handleShowContentManager = () => {
    setShowContentManager(true);
  };
  const handleCloseContentManager = () => {
    setShowContentManager(false);
    if (showContentManager) {
      setContentManagerState(initialContentManagerState);
    }
  };

  const isContentManagerEnabled =
    streamingEnabled && premise?.streamingContentManagerEnabled === true;

  const isContentManagerAvailable = isContentManagerEnabled && !!premiseContent;

  const screenLoading = useSelector(
    (state: CustomerPortalState) => state.screen.isLoading
  );
  const premiseLoading = useSelector(
    (state: CustomerPortalState) => state.organizationUnits.isLoading
  );
  const companyAssetsIsLoading = useSelector(
    (state: CustomerPortalState) =>
      state.asset.paginatedCompanyAssets.status === LoadingStatus.LOADING
  );
  const premiseAssetsIsLoading = useSelector(
    (state: CustomerPortalState) =>
      state.asset.paginatedPremiseAssets.status === LoadingStatus.LOADING
  );
  const assetsLoading = companyAssetsIsLoading || premiseAssetsIsLoading;

  const isLoading = screenLoading || premiseLoading || assetsLoading;

  const allScreens = useSelector((state: CustomerPortalState) => {
    return selectScreensForPremise(state, premiseId as string);
  });
  const signageScreens = useMemo(() => {
    return allScreens.filter((screen) =>
      screen.nendaProducts.includes(NendaProduct.SIGNAGE)
    );
  }, [allScreens]);

  const streamingAppScreens = useMemo(() => {
    return allScreens.filter((screen) =>
      screen.nendaProducts.includes(NendaProduct.STREAMING_APP)
    );
  }, [allScreens]);

  const assets = useSelector(selectAssetsBasedOnNavigationScope);
  const timelines = useSelector(selectTimelines);

  useEffect(() => {
    if (!premise || screenLoading) return;
    store.dispatch(getScreens(premise._id));
  }, [JSON.stringify(premise)]);

  useEffect(() => {
    if (premise?.nendaProducts?.includes(NendaProduct.SIGNAL_DELIVERY)) {
      store.dispatch(GetStreamChannels());
    }
  }, [premise?._id, premise?.nendaProducts]);

  useEffect(() => {
    if (!premiseId || premiseAssetsIsLoading) return;
    store.dispatch(
      GetPremiseAssets(premiseId || "", {
        filter: {
          product: NendaProduct.SIGNAGE,
        },
      })
    );
  }, [premiseId]);

  useEffect(() => {
    if (!companyId || companyAssetsIsLoading) return;
    store.dispatch(
      GetCompanyAssets(companyId || "", {
        filter: {
          product: NendaProduct.SIGNAGE,
        },
      })
    );
  }, [companyId]);

  useEffect(() => {
    if (premiseId && !premiseContent && isContentManagerEnabled) {
      store.dispatch(GetContentByPremiseId(premiseId));
    }
  }, [premiseId, premiseContent, isContentManagerEnabled]);

  useEffect(() => {
    if (signageScreens && signageScreens.length > 0 && signageEnabled) {
      const start = withoutTime(new Date());
      const end = add(start, { days: +1 });
      const ids = signageScreens?.map((s) => s._id);

      store.dispatch(
        batchGetTimelineForScreen({
          ids: ids,
          query: {
            from: start.toISOString(),
            to: end.toISOString(),
          },
        })
      );
    }
  }, [signageScreens, signageEnabled]);

  const navigate = useNavigate();

  const premiseActions: ActionGridItemProps[] = useMemo(() => {
    return [
      {
        title:
          "customerportal.pages.dashboard.cta.quick_nav.quick_change_channel.title",
        description:
          "customerportal.pages.dashboard.cta.quick_nav.quick_change_channel.description",
        color: paletteLight.primary?.light,
        onClick: () => {
          navigate(`/channels/premise/${premiseId}`);
        },
        buttonText:
          "customerportal.pages.dashboard.cta.quick_nav.quick_change_channel.button",
        icon: SystemUpdateAltOutlined,
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanEdit],
        },
        isVisible: signageEnabled,
      },
      {
        title:
          "customerportal.pages.dashboard.cta.quick_nav.week_planner.title",
        description:
          "customerportal.pages.dashboard.cta.quick_nav.week_planner.description",
        color: paletteLight.secondary?.main,
        onClick: () => {
          navigate(`/scheduling/schedule/premise/${premiseId}`);
        },
        buttonText:
          "customerportal.pages.dashboard.cta.quick_nav.week_planner.button",
        icon: DateRangeOutlined,
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanEdit],
        },
        mobileStyle: { display: "none" },
        isVisible: signageEnabled,
      },
      {
        title:
          "customerportal.pages.dashboard.cta.quick_nav.to_media_library.title",
        description:
          "customerportal.pages.dashboard.cta.quick_nav.to_media_library.description",
        color: paletteLight.secondary?.light,
        onClick: () => {
          navigate(`/media-library/premise/${premiseId}`);
        },
        buttonText:
          "customerportal.pages.dashboard.cta.quick_nav.to_media_library.button",
        icon: CollectionsOutlined,
        restriction: {
          resource: Resource.Asset,
          scopes: [Scope.CanEdit],
        },
        mobileStyle: { display: "none" },
        isVisible: signageEnabled,
      },
      {
        title:
          "customerportal.pages.dashboard.cta.quick_nav.create_signage_promotion.title",
        description:
          "customerportal.pages.dashboard.cta.quick_nav.create_signage_promotion.description",
        color: paletteLight.primary?.main,
        onClick: () => {
          store.dispatch(TogglePromotionModal());
        },
        buttonText:
          "customerportal.pages.dashboard.cta.quick_nav.create_signage_promotion.button",
        icon: SubscriptionsOutlined,
        restriction: {
          resource: Resource.SignagePromotion,
          scopes: [Scope.CanEdit],
        },
        isVisible: signageEnabled && promotionsEnabled,
      },
      {
        title:
          "customerportal.pages.dashboard.cta.quick_nav.content_manager.title",
        description:
          "customerportal.pages.dashboard.cta.quick_nav.content_manager.description",
        color: paletteLight.primary?.main,
        onClick: () => {
          handleShowContentManager();
        },
        buttonText:
          "customerportal.pages.dashboard.cta.quick_nav.content_manager.button",
        icon: MovieFilterOutlined,
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanEdit],
        },
        isVisible: isContentManagerAvailable,
      },
    ];
  }, [isContentManagerAvailable, signageEnabled]);

  const theme = useTheme();

  const externalInformation = [
    {
      title: "customerportal.pages.dashboard.external_ad_service_id",
      value: premise?.adnuntiusSiteId,
    },
  ];

  const roomTypes = useSelector((state: CustomerPortalState) =>
    selectRoomTypesByPremiseGroupId(state, premise?.parent || "")
  );

  const renderDashboardComponents = () => {
    const components: ReactNode[] = [];

    if (signageScreens && signageEnabled) {
      components.push(
        <ScreenOverviewList screens={signageScreens} timelines={timelines} />
      );
    }
    if (streamingAppScreens && streamingEnabled) {
      components.push(
        <StreamingAppScreensList
          key={"streaming-app-screens"}
          screens={roomsWithContent}
          setContentManagerState={setContentManagerState}
          contentManagerState={contentManagerState}
          toggleContentManager={handleShowContentManager}
          roomTypes={roomTypes}
          isContentManagerEnabled={isContentManagerAvailable}
        />
      );
    }
    return components;
  };

  const roomTypeRoomExists = allScreens.some(
    (room) =>
      roomTypes.find((roomType) => roomType._id === room.roomType)?.category ===
      RoomTypeCategory.ROOM
  );

  const roomTypeCommonAreaExists = allScreens.some(
    (room) =>
      roomTypes.find((roomType) => roomType._id === room.roomType)?.category ===
      RoomTypeCategory.COMMON_AREA
  );

  const existingRoomCategories = useMemo(() => {
    const categories: RoomTypeCategory[] = [];
    if (roomTypeRoomExists) {
      categories.push(RoomTypeCategory.ROOM);
    }
    if (roomTypeCommonAreaExists) {
      categories.push(RoomTypeCategory.COMMON_AREA);
    }
    return categories;
  }, [roomTypeRoomExists, roomTypeCommonAreaExists]);

  if (
    existingRoomCategories.length === 1 &&
    !contentManagerState.selectedRoomCategory
  ) {
    setContentManagerState({
      ...contentManagerState,
      selectedRoomCategory: existingRoomCategories[0],
      initialStep: contentManagerState.selectedRooms.length > 0 ? 2 : 1,
    });
  }

  const roomsWithContent: RoomWithContentAndType[] = streamingAppScreens?.map(
    (room) => {
      const roomType = roomTypes?.find(
        (roomType) => roomType._id === room.roomType
      );
      const roomTypeCategory = roomType?.category;

      if (!roomTypeCategory || !premiseContent) {
        return {
          ...room,
          packages: [],
          type: undefined,
        };
      }

      const contentPackageIds =
        premiseContent.roomContent?.find(
          (content) => content.roomId === room._id
        )?.currentContent || [];

      const packagesByCategory =
        premiseContent.availablePackages[roomTypeCategory] || [];

      const packages = contentPackageIds?.map((packageId) =>
        packagesByCategory.find((packageData) => packageData.id === packageId)
      ) as unknown as ContentManagmentData[];

      return {
        ...room,
        packages: packages,
        type: roomTypeCategory,
      };
    }
  );

  const selectedPackages = premiseContent?.availablePackages[
    contentManagerState.selectedRoomCategory || RoomTypeCategory.ROOM
  ]?.filter((p) => contentManagerState.selectedPackages.includes(p.id));

  const selectedUpsalesProductIds = useMemo(() => {
    return selectedPackages?.map((p) => p.upsalesProduct) || [];
  }, [selectedPackages]);

  const handleSubmitContentOrder = () => {
    if (premiseId && contentManagerState.selectedRooms.length) {
      const orderRows = contentManagerState.selectedRooms.map((room) => ({
        roomId: room,
        content: selectedUpsalesProductIds,
      }));
      store.dispatch(
        SendContentOrder(premiseId, {
          orderRows,
        })
      );
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PermissionsGate
            restriction={{
              resource: Resource.Premise,
              scopes: [Scope.CanAdministrate],
            }}
          >
            <>
              {premise?.adnuntiusSiteId && (
                <Tooltip
                  title={
                    <Box sx={{ padding: "0.5rem" }}>
                      {externalInformation.map((info) => (
                        <Box
                          key={info.title}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mb: "1rem",
                          }}
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            {t(info.title)}:
                          </Typography>
                          <Typography>{info.value}</Typography>
                        </Box>
                      ))}
                    </Box>
                  }
                >
                  <InfoOutlined sx={{ color: theme.palette.primary.light }} />
                </Tooltip>
              )}
            </>
          </PermissionsGate>
        </Box>
        {!!premise && <EditPremiseDialog premise={premise} />}
      </Box>
      <Box>
        <ActionGrid type={Resource.Premise} items={premiseActions} />
        <CreateEditPromotionModal
          isLoading={isLoading}
          assets={assets}
          screens={signageScreens}
        />
        {showContentManager && premiseContent && (
          <ContentManagerModal
            open={showContentManager}
            onClose={handleCloseContentManager}
            content={premiseContent}
            rooms={roomsWithContent}
            contentManagerState={contentManagerState}
            roomTypeCategories={existingRoomCategories}
            handleSetSelectedRoomCategory={handleSetSelectedRoomCategory}
            handleSetSelectedRooms={handleSetSelectedRooms}
            handleSetSelectedPackage={handleSetSelectedPackages}
            handleSetAcceptTerms={handleAcceptTerms}
            handleClearContentManagerState={handleClearContentManagerState}
            onSubmit={handleSubmitContentOrder}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", gap: "2rem" }}>
        <Typography variant="h3">
          {t("customerportal.pages.dashboard.title")}
        </Typography>
      </Box>
      {renderDashboardComponents().map((component) => component)}
    </Box>
  );
};

export default PremiseDashboard;
