import { AccessTime } from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { memo, ReactNode } from "react";
import DailyTimeScale from "./DailyTimeScale";
import PlaylistTimeline from "./PlaylistTimeline";
import { IDailyContentPlaylist } from "../../../../../types/NendaTypes";
import {
  setDeletePlaylist,
  setEditPlaylist,
} from "../../store/reducers/dailyContentPlaylistReducer";
import { store } from "../../store";
import { playlistToContentTimelineIntervals } from "../../../../utils/timeline";

const PlaylistsTable = memo(
  ({ dailyPlaylists }: { dailyPlaylists: IDailyContentPlaylist[] }) => {
    return (
      <Box
        sx={(theme) => ({
          my: "2rem",
          p: "1rem",
          background: theme.palette.transparent.white,
          borderRadius: "0.2rem",
        })}
      >
        <DailyPlaylistRow
          startEl={
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                px: "0.5rem",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              })}
            >
              <Typography
                variant="caption"
                sx={(theme) => ({
                  textTransform: "uppercase",
                  color: theme.palette.grey[500],
                })}
                fontSize={"0.8rem"}
              >
                {t("customerportal.pages.daily_playlists.playlist_name")}
              </Typography>
            </Box>
          }
          endEl={
            <>
              <Typography
                variant="caption"
                sx={(theme) => ({
                  textTransform: "uppercase",
                  color: theme.palette.grey[500],
                })}
                fontSize={"0.8rem"}
              >
                {t("customerportal.pages.daily_playlists.playlists")}
              </Typography>
            </>
          }
        />
        <DailyPlaylistRow
          startEl={
            <Box
              sx={(theme) => ({
                px: "0.5rem",

                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              })}
            >
              <AccessTime sx={{ height: "1rem" }} />
            </Box>
          }
          endEl={<DailyTimeScale />}
          divider={false}
        />
        {dailyPlaylists.map((playlist) => {
          return (
            <DailyPlaylistRow
              key={playlist._id}
              startEl={<PlaylistTitle playlist={playlist} />}
              endEl={
                <PlaylistTimeline
                  playlist={playlistToContentTimelineIntervals(playlist)}
                />
              }
            />
          );
        })}
      </Box>
    );
  }
);

PlaylistsTable.displayName = "PlaylistsTable";

const PlaylistTitle = ({ playlist }: { playlist: IDailyContentPlaylist }) => {
  const onEditPlaylist = () => {
    store.dispatch(setEditPlaylist(playlist._id));
  };

  const onDeletePlaylist = () => {
    store.dispatch(setDeletePlaylist(playlist._id));
  };
  return (
    <Box
      sx={{
        display: "flex",
        p: "0.5rem",
        alignItems: "center",
        gap: "1rem",
        "& .actions": {
          display: "none",
          cursor: "pointer",
        },
        "&:hover": {
          "& .actions": {
            display: "flex",
          },
        },
      }}
    >
      <Typography variant="body2">{playlist.name}</Typography>
      <Stack
        direction={"row"}
        spacing={"0.5rem"}
        px="0.5rem"
        className="actions"
      >
        <Typography
          onClick={onEditPlaylist}
          variant="body1"
          fontSize={"0.7rem"}
          sx={(theme) => ({
            color: theme.palette.grey[500],
            transition: "all 0.1s ease-in-out",

            "&:hover": {
              color: theme.palette.primary.main,
            },
          })}
        >
          {t("common.edit")}
        </Typography>
        <Typography
          onClick={onDeletePlaylist}
          variant="body1"
          fontSize={"0.7rem"}
          sx={(theme) => ({
            color: theme.palette.grey[500],
            transition: "all 0.1s ease-in-out",

            "&:hover": {
              color: theme.palette.error.main,
            },
          })}
        >
          {t("common.delete")}
        </Typography>
      </Stack>
    </Box>
  );
};

const DailyPlaylistRow = ({
  divider = true,
  startEl,
  endEl,
}: {
  divider?: boolean;
  startEl: ReactNode;
  endEl: ReactNode;
}) => {
  return (
    <Grid
      container
      columns={{ xs: 1, md: 3 }}
      sx={{
        borderBottom: divider ? "1px solid #ccc" : "none",
        py: "0.5rem",
        alignItems: "center",
      }}
    >
      <Grid item xs={1} md={1}>
        {startEl}
      </Grid>
      <Grid item xs={1} md={2}>
        {endEl}
      </Grid>
    </Grid>
  );
};

export default PlaylistsTable;
