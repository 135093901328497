import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalEpic, CustomerPortalState } from "..";
import {
  IUser,
  LoadingStatus,
  SimpleUser,
} from "../../../../../types/NendaTypes";
import { IListResponse } from "../../../../../types/RequestTypes";
import { companyService } from "../../../../http/companyService";
import { CreateUserPayload, userService } from "../../../../http/user.service";
import { handleError, SetError } from "./errorReducer";
import { SetNotification } from "./notificationReducer";

export interface UserState {
  users: IListResponse<SimpleUser> & { loadingStatus?: "idle" | "success" };
  createUser: {
    isLoading: boolean;
  };
  deleteUser: {
    isLoading: boolean;
    showConfirmationDialog: boolean;
    userToDelete: SimpleUser["_id"] | null;
    userDeleted: SimpleUser["_id"];
  };
}

export const initialState: UserState = {
  users: {
    data: [],
    page: 0,
    pageSize: 0,
    filteredCount: 0,
    totalCount: 0,
    loadingStatus: LoadingStatus.IDLE,
  },
  createUser: {
    isLoading: false,
  },
  deleteUser: {
    isLoading: false,
    showConfirmationDialog: false,
    userToDelete: null,
    userDeleted: false,
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUsers() {},
    getUsersByCompany(_state, _action: PayloadAction<string>) {},
    getUsersSuccess(state, action: PayloadAction<IListResponse<IUser>>) {
      state.users = { ...action.payload, loadingStatus: "success" };
    },
    createUser(state, _action: PayloadAction<CreateUserPayload>) {
      state.createUser.isLoading = true;
    },
    createUserSuccess(state, action: PayloadAction<SimpleUser>) {
      state.createUser.isLoading = false;
      state.users.data.push(action.payload);
    },
    creatUserFailure(state) {
      state.createUser.isLoading = false;
    },
    updateUser(state, _action: PayloadAction<Partial<IUser>>) {
      state.createUser.isLoading = true;
    },
    updateUserSuccess(state, action: PayloadAction<SimpleUser>) {
      state.createUser.isLoading = false;
      state.users.data = state.users.data.map((u) => {
        if (u._id === action.payload._id) {
          return action.payload;
        }
        return u;
      });
    },
    updateUserFailure(state) {
      state.createUser.isLoading = false;
    },
    deleteUser(_state, _action: PayloadAction<string>) {},
    deleteUserSuccess(state, action: PayloadAction<SimpleUser>) {
      state.users.data = state.users.data.filter(
        (u) => u._id !== action.payload._id
      );
    },
  },
});

// Selectors
export const selectUsers = (
  state: CustomerPortalState
): IListResponse<SimpleUser> => {
  return state.user.users;
};

export const selectShowDeleteUserConfirmation = (
  state: CustomerPortalState
) => {
  return state.user.deleteUser.showConfirmationDialog;
};

// Epics
const getUsers$: CustomerPortalEpic = (action$) =>
  action$.pipe(
    filter(usersSlice.actions.getUsers.match),
    switchMap(() => {
      return userService.getUsers().pipe(
        mergeMap((response) => {
          return [getUsersSuccess(response)];
        }),
        catchError((err) => handleError(err))
      );
    })
  );

const getUsersByCompany$: CustomerPortalEpic = (action$) => {
  return action$.pipe(
    filter(usersSlice.actions.getUsersByCompany.match),
    switchMap((a) => {
      return companyService.getUsers(a.payload).pipe(
        mergeMap((response) => {
          return [getUsersSuccess(response.data)];
        }),
        catchError((err) => handleError(err))
      );
    })
  );
};

const updateUser$: CustomerPortalEpic = (action$) => {
  return action$.pipe(
    filter(usersSlice.actions.updateUser.match),
    switchMap((a) => {
      return userService.updateUser(a.payload._id, a.payload).pipe(
        mergeMap((response) => {
          return [updateUserSuccess(response)];
        }),
        catchError((err) => {
          return [
            updateUserFailure(),
            SetError("Failed to update user: " + err.message),
          ];
        })
      );
    })
  );
};

const createUser$: CustomerPortalEpic = (action$) => {
  return action$.pipe(
    filter(usersSlice.actions.createUser.match),
    switchMap((a) => {
      return userService.createUser(a.payload).pipe(
        mergeMap((response) => {
          return [
            createUserSuccess(response),
            SetNotification(`User ${response.email} created`),
          ];
        }),
        catchError((err) => {
          return [
            creatUserFailure(),
            SetError("Failed to create user: " + err.message),
          ];
        })
      );
    })
  );
};

const deleteUser$: CustomerPortalEpic = (action$) => {
  return action$.pipe(
    filter(usersSlice.actions.deleteUser.match),
    switchMap((a) => {
      return userService.deleteUser(a.payload).pipe(
        mergeMap((response) => {
          return [
            deleteUserSuccess(response),
            SetNotification(`User ${response.email} deleted`),
          ];
        }),
        catchError((err) => handleError(err))
      );
    })
  );
};

export const userEpics = [
  getUsers$,
  getUsersByCompany$,
  createUser$,
  deleteUser$,
  updateUser$,
];
export const {
  getUsers,
  getUsersByCompany,
  getUsersSuccess,
  createUser,
  createUserSuccess,
  creatUserFailure,
  deleteUser,
  deleteUserSuccess,
  updateUserSuccess,
  updateUserFailure,
  updateUser,
} = usersSlice.actions;

export default usersSlice.reducer;
