import { Add } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import {
  IContentChannel,
  ISignagePlaylist,
  ScheduleContentRefType,
} from "../../../../../types/NendaTypes";
import posterPlaceholderImage from "../../assets/images/poster-placeholder.png";
import {
  selectCategorizedContentChannelsByNavigatedPremise,
  selectContentChannelsByNavigatedPremise,
} from "../../store/reducers/contentChannelReducer";
import DisabledFeatures from "../Content/components/DisabledFeatures";
import { isContentChannel } from "../DailyPlaylist/PlaylistTimeline";

const SmallContentChannelCarousel = ({
  handleAddContent,
  channelSelectTitle,
}: {
  handleAddContent: (
    contentId: string,
    contentRefType: ScheduleContentRefType
  ) => void;
  channelSelectTitle: string;
}) => {
  const categorizedContentChannels = useSelector(
    selectCategorizedContentChannelsByNavigatedPremise
  );
  const allContentChannels = useSelector(
    selectContentChannelsByNavigatedPremise
  );
  const categorizedContentChannelsWithAll = {
    ...categorizedContentChannels,
    All: allContentChannels,
  };
  const [selectedCategory, setSelectedCategory] = useState<string>(
    Object.keys(categorizedContentChannelsWithAll)[0]
  );
  const handleSelectCategory = (category: string) => {
    setSelectedCategory(category);
  };

  return (
    <Box>
      <Stack direction="row" gap="0.5rem" px="1rem" pb="0.5rem">
        {Object.keys(categorizedContentChannelsWithAll).map((category) => {
          return (
            <Box
              key={category}
              onClick={() => handleSelectCategory(category)}
              sx={(theme) => ({
                padding: "0.5rem 1rem",
                borderRadius: "0.2rem",
                cursor: "pointer",
                background:
                  selectedCategory === category
                    ? theme.palette.transparent.purple
                    : "transparent",
              })}
            >
              <Typography variant="body2">{category}</Typography>
            </Box>
          );
        })}
      </Stack>
      <Stack direction={"row"} gap="0.5rem" px="1rem">
        <ChannelCarousel
          contentItems={categorizedContentChannelsWithAll[selectedCategory]}
          handleAddChontent={handleAddContent}
          channelSelectTitle={channelSelectTitle}
        />
      </Stack>
    </Box>
  );
};
const ChannelCarouselItem = ({
  content,
  onAddContent,
  channelSelectTitle,
}: {
  content: IContentChannel | ISignagePlaylist;
  onAddContent: (
    contentId: string,
    contentRefType: ScheduleContentRefType
  ) => void;
  channelSelectTitle: string;
}) => {
  const isContentChannelType = isContentChannel(content);
  const handleAddChannel = () => {
    onAddContent(
      content._id,
      isContentChannelType
        ? ScheduleContentRefType.CONTENT_CHANNEL
        : ScheduleContentRefType.SIGNAGE_PLAYLIST
    );
  };

  return (
    <Box
      sx={{
        height: "12rem",
        backgroundColor: "white",
        mr: "0.5rem",
        backgroundImage: `url(${
          isContentChannelType ? content?.posterUrl : posterPlaceholderImage
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        position: "relative",
        "&:hover": {
          "& .overlay-add": {
            visibility: "visible",
          },
        },
        "& .overlay-add": {
          cursor: "pointer",
          visibility: "hidden",
          backgroundColor: "rgba(0,0,0,0.9)",
        },
      }}
    >
      {isContentChannel(content) && (
        <DisabledFeatures
          permissions={content.adPermissions}
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            zIndex: 1,
            top: 0,
            right: 0,
            backgroundColor: "white",
            p: "0.2rem",
          }}
        />
      )}
      <Box
        className="overlay-add"
        onClick={handleAddChannel}
        sx={{
          position: "absolute",
          inset: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Add sx={{ fill: "white" }} />
          <Typography variant="body2" color="white">
            {channelSelectTitle ||
              t("customerportal.pages.daily_playlists.add_channel")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          background: "rgba(0,0,0,0.7)",
          height: "4rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          px: "0.5rem",
        }}
      >
        <Typography variant="body1" textAlign={"center"}>
          {content.name}
        </Typography>
      </Box>
    </Box>
  );
};

const ChannelCarousel = ({
  contentItems,
  handleAddChontent,
  channelSelectTitle,
}: {
  contentItems: (IContentChannel | ISignagePlaylist)[];
  handleAddChontent: (
    contentId: string,
    contentRefType: ScheduleContentRefType
  ) => void;
  channelSelectTitle: string;
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 4, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Carousel partialVisible={true} responsive={responsive}>
        {contentItems?.map((contentItem) => (
          <ChannelCarouselItem
            key={contentItem._id}
            content={contentItem}
            onAddContent={handleAddChontent}
            channelSelectTitle={channelSelectTitle}
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default SmallContentChannelCarousel;
