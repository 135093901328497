import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import DigitalSignageTable from "../components/DigitalSignagePlaylist/DigitalSignageTable";
import { CustomerPortalState, store } from "../store";
import {
  getCompanySignagePlaylists,
  getPremiseSignagePlaylists,
  selectCompanySignagePlaylists,
  selectPremiseSignagePlaylists,
} from "../store/reducers/signagePlaylistReducer";
import {
  selectCurrentNavigationLevel,
  selectNavigationScope,
} from "../store/reducers/workspaceReducer";
import HelpWidget from "../ui-components/display/HelpWidget";

const DigitalSignagePlaylistListPage = () => {
  const scope = useSelector(selectNavigationScope);
  const navigate = useNavigate();
  const navigationLevel = useSelector(selectCurrentNavigationLevel);
  const companyPlaylists = useSelector((state: CustomerPortalState) =>
    scope.company ? selectCompanySignagePlaylists(state, scope.company) : []
  );
  const premisePlaylists = useSelector((state: CustomerPortalState) =>
    scope.premise ? selectPremiseSignagePlaylists(state, scope.premise) : []
  );

  const playlistsToDisplay =
    navigationLevel === "company"
      ? companyPlaylists
      : [...companyPlaylists, ...premisePlaylists];

  useEffect(() => {
    if (scope.company && !companyPlaylists.length) {
      store.dispatch(getCompanySignagePlaylists(scope.company));
    }
    if (scope.premise && !premisePlaylists.length) {
      store.dispatch(getPremiseSignagePlaylists(scope.premise));
    }
  }, [
    scope.company,
    scope.premise,
    companyPlaylists.length,
    premisePlaylists.length,
  ]);

  const handleNavigateToCreate = () => {
    navigate(`create`);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            alignItems: "baseline",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Typography variant="h3">
            {t("customerportal.pages.digital_signage.page_title")}
          </Typography>
          <Button
            variant="contained"
            onClick={handleNavigateToCreate}
            color="primary"
            sx={{ width: isMobile ? "100%" : "auto", mb: "1rem" }}
          >
            {t("customerportal.pages.digital_signage.create_playlist")}
          </Button>
        </Box>
        <Box>
          <HelpWidget
            title="Help"
            details={
              <Box>
                <Typography variant="body2">
                  <strong>
                    {t("customerportal.pages.digital_signage.help.page_title")}
                  </strong>
                </Typography>
                <Typography variant="body1">
                  {t(
                    "customerportal.pages.digital_signage.help.help_widget_section_one"
                  )}
                </Typography>
                <Typography variant="body1">
                  {t(
                    "customerportal.pages.digital_signage.help.help_widget_section_two"
                  )}
                </Typography>
              </Box>
            }
          />
        </Box>
      </Box>
      <Typography variant="body1">
        {t("customerportal.pages.digital_signage.help.page_description")}
      </Typography>
      <DigitalSignageTable playlists={playlistsToDisplay} />
      <Outlet />
    </Box>
  );
};

export default DigitalSignagePlaylistListPage;
