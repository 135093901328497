import { addMinutes, isBefore } from "date-fns";
import { Hour } from "../../../../../../types/HelperTypes";
import { ITimeInterval } from "../../../../../../types/NendaTypes";
import {
  dateToHourMinuteObject,
  hourMinuteObjectToDate,
} from "../../../../../utils/timeUtil";

export const findAvailableSlot = (
  timeSlots: ITimeInterval[],
  minDurationInMinutes: number,
  maxDurationInMinutes: number
) => {
  if (!timeSlots || timeSlots.length === 0) {
    return {
      start: { hour: 8, minute: 0 },
      end: { hour: 16, minute: 0 },
    };
  }
  const startOfDayTime = hourMinuteObjectToDate({ hour: 0, minute: 0 });
  const endOfDayTime = hourMinuteObjectToDate({ hour: 24 as Hour, minute: 0 });

  // Sort timeSlots by start time
  const sortedTimeSlots = [...timeSlots].sort((a, b) => {
    const startDateA = hourMinuteObjectToDate(a.start);
    const startDateB = hourMinuteObjectToDate(b.start);

    return isBefore(startDateA, startDateB) ? -1 : 1;
  });

  // Check the time range between start of day and first timeSlot start
  const firstTimeSlot = sortedTimeSlots[0];
  const firstTimeSlotStart = hourMinuteObjectToDate(firstTimeSlot.start);
  const startOfDayToFirstTimeSlotDuration =
    firstTimeSlotStart.getTime() - startOfDayTime.getTime();
  const startOfDayToFirstTimeSlotDurationInMinutes = Math.floor(
    startOfDayToFirstTimeSlotDuration / 1000 / 60
  );
  const lastTimeSlot = sortedTimeSlots[sortedTimeSlots.length - 1];
  const lastTimeSlotEnd = hourMinuteObjectToDate(lastTimeSlot.end);
  const lastTimeSlotToEndOfDayDuration =
    endOfDayTime.getTime() - lastTimeSlotEnd.getTime();
  const lastTimeSlotToEndOfDayDurationInMinutes = Math.floor(
    lastTimeSlotToEndOfDayDuration / 1000 / 60
  );
  //Check start of day to first time slot
  if (startOfDayToFirstTimeSlotDurationInMinutes >= minDurationInMinutes) {
    return {
      start: dateToHourMinuteObject(startOfDayTime),
      end:
        startOfDayToFirstTimeSlotDurationInMinutes >= maxDurationInMinutes
          ? dateToHourMinuteObject(
              addMinutes(startOfDayTime, maxDurationInMinutes)
            )
          : firstTimeSlot.start,
    };
  }

  //Check between time slots if multiple time slots
  if (sortedTimeSlots.length > 1) {
    for (let i = 0; i < sortedTimeSlots.length - 1; i++) {
      const currentTimeSlot = sortedTimeSlots[i];
      const nextTimeSlot = sortedTimeSlots[i + 1];

      const currentTimeSlotEnd = hourMinuteObjectToDate(currentTimeSlot.end);
      const nextTimeSlotStart = hourMinuteObjectToDate(nextTimeSlot.start);

      const timeSlotToNextTimeSlotDuration =
        nextTimeSlotStart.getTime() - currentTimeSlotEnd.getTime();
      const timeSlotToNextTimeSlotDurationInMinutes =
        timeSlotToNextTimeSlotDuration / 1000 / 60;

      if (timeSlotToNextTimeSlotDurationInMinutes >= minDurationInMinutes) {
        return {
          start: currentTimeSlot.end,
          end:
            timeSlotToNextTimeSlotDurationInMinutes >= maxDurationInMinutes
              ? dateToHourMinuteObject(
                  addMinutes(currentTimeSlotEnd, maxDurationInMinutes)
                )
              : nextTimeSlot.start,
        };
      }
    }
  }

  //Check between last time slot and end of day
  if (lastTimeSlotToEndOfDayDurationInMinutes >= minDurationInMinutes) {
    if (lastTimeSlot.end.hour === 0 && lastTimeSlot.end.minute === 0) {
      return null;
    }

    return {
      start: lastTimeSlot.end,
      end:
        lastTimeSlotToEndOfDayDurationInMinutes >= maxDurationInMinutes
          ? dateToHourMinuteObject(
              addMinutes(
                hourMinuteObjectToDate(lastTimeSlot.end),
                maxDurationInMinutes
              )
            )
          : dateToHourMinuteObject(startOfDayTime),
    };
  }

  // If no suitable slot is found, return null
  return null;
};
