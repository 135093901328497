import { Box, InputLabel, TextField, TextFieldVariants } from "@mui/material";
import themeOptions from "../../theme/theme";

const TextInputWithLabel = ({
  variant = "outlined",
  label,
  placeholder,
  ...props
}) => {
  const propsWithDefaultValues = {
    ...props,
    InputProps: {
      disableUnderline: true,
      ...props.InputProps,
      disabled: props.disabled,
    },
  };

  if (variant === "outlined") {
    if (propsWithDefaultValues.InputProps) {
      // outlined variant does not support disableUnderline
      delete propsWithDefaultValues.InputProps.disableUnderline;
    }
  }

  return (
    <Box>
      <Box sx={{ px: "0.5rem" }}>
        <InputLabel
          sx={{
            fontSize: "0.7rem",
            color: themeOptions.palette?.text.primary,
            letterSpacing: "0.03rem",
            mb: 0,
          }}
        >
          {label}
        </InputLabel>
      </Box>
      <TextField
        placeholder={placeholder || ""}
        variant={variant as TextFieldVariants}
        {...propsWithDefaultValues}
        sx={{
          ...props.sx,
        }}
      />
    </Box>
  );
};

export default TextInputWithLabel;
