import React from "react";
import { Route, Routes } from "react-router-dom";

import { ErrorBar } from "../ErrorBar/ErrorBar";
import { Loader } from "../Loader/Loader";
import { NotificationBar } from "../NotificationBar/NotificationBar";
import { RoomManagementPage } from "../../pages/RoomManagementPage/RoomManagementPage";
import { LoginPage } from "../../pages/Login/Login";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import { Provider, useSelector } from "react-redux";
import Home from "./Home";
import { ProtectedRoute } from "../ProtectedRoute/ProtectedRoute";
// import { reactPlugin } from "../../utils/telemetry";
import { ThemeProvider, createTheme } from "@mui/material";

export const AdminPortal = () => {
  const error = useSelector((state: AppState) => state.error?.currentError);
  const loading = useSelector((state: AppState) => state.loading);

  return (
    <>
      {error ? <ErrorBar /> : null}
      <NotificationBar />
      <Loader loading={loading} />
      <Routes>
        <Route path="/room-management" element={<RoomManagementPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/*"
          element={<ProtectedRoute childRouteElement={<Home />} />}
        />
      </Routes>
    </>
  );
};

export const AdminPortalIndex = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#557aff",
      },
      secondary: {
        main: "#0F007A",
      },
    },
  });

  return (
    // <AppInsightsErrorBoundary
    //   onError={() => <h1>I believe something went wrong</h1>}
    //   appInsights={reactPlugin}
    // >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AdminPortal />
      </ThemeProvider>
    </Provider>
    // </AppInsightsErrorBoundary>
  );
};
