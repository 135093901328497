import { t } from "i18next";
import {
  IOrganizationUnit,
  NendaProduct,
} from "../../../../../../../types/NendaTypes";
import { OverlapResult, initCreateSetupErrors } from "../SetupCustomerDialog";
import {
  CreateRoomsRangeParameters,
  CreateRoomsRangeParametersWithError,
  RoomError,
} from "../../../../store/reducers/customerSetupReducer";
import { range } from "../../../../../../utils/array";

export function validateRoomOverlaps(
  arr: CreateRoomsRangeParameters[],
  checkIndex: number
): OverlapResult | null {
  const { to, from } = arr[checkIndex];
  const currentTo = Number(to);
  const currentFrom = Number(from);
  const overlaps = { to: false, from: false };

  for (let i = 0; i < arr.length; i++) {
    if (i === checkIndex) continue;
    const { to: toExisting, from: fromExisting } = arr[i];
    const existingTo = Number(toExisting);
    const existingFrom = Number(fromExisting);

    const roomRange = existingTo - existingFrom;

    const rangeOfExistingRoomNumbers: number[] =
      roomRange < 0 ? [] : range(existingTo - existingFrom + 1, existingFrom);

    const fromOverlap = rangeOfExistingRoomNumbers.includes(currentFrom);
    const toOverlap = rangeOfExistingRoomNumbers.includes(currentTo);

    overlaps.from = fromOverlap || overlaps.from;
    overlaps.to = toOverlap || overlaps.to;
  }

  if (overlaps.from || overlaps.to) {
    return { index: checkIndex, overlaps };
  }

  return null;
}

export const validateRooms = ({
  rooms,
  products,
}: {
  rooms: CreateRoomsRangeParameters[];
  products: NendaProduct[];
}): CreateRoomsRangeParametersWithError[] => {
  const roomsWithErrors = rooms.map((room, i) => {
    const overlapResult = validateRoomOverlaps(rooms, i);

    const from = Number(room.from);
    const to = Number(room.to);

    const errors: RoomError = { from: "", to: "", roomType: "" };
    if (overlapResult?.overlaps.from) {
      errors.from = t("form.validation.account_setup.rooms_overlap");
    }
    if (overlapResult?.overlaps.to) {
      errors.to = t("form.validation.account_setup.rooms_overlap");
    }
    if (products.includes(NendaProduct.STREAMING_APP) && !room.roomType) {
      errors.roomType = t("form.validation.account_setup.room_type_required");
    }
    if (from <= 0) {
      errors.from = t("form.validation.account_setup.from_required");
    }
    if (to <= 0) {
      errors.to = t("form.validation.account_setup.to_required");
    }
    if (from > to) {
      errors.to = t("form.validation.account_setup.to_greater_than_from");
    }

    return { ...room, errors };
  });

  return roomsWithErrors;
};

export const companyDependantProducts = [
  NendaProduct.SIGNAGE,
  NendaProduct.FRONTLAYER,
];

export const validateGeneralForm = ({
  premises,
  premiseName,
  password,
  company,
  products,
  premiseGroup,
}: {
  premises: IOrganizationUnit[];
  premiseName: string;
  password: string;
  company: string;
  products: NendaProduct[];
  premiseGroup: string;
}) => {
  const initErrors = initCreateSetupErrors;
  const errors = { ...initErrors };

  const premiseNameExists = premises.some(
    (p) => p.name.toLowerCase() === premiseName.trim().toLowerCase()
  );

  if (!premiseName) {
    errors.name =
      t("form.validation.account_setup.premise_required") ||
      "Premise name is required";
  }
  if (premiseNameExists) {
    errors.name =
      t("form.validation.account_setup.premise_exists") ||
      "Premise name already exists";
  }
  if (!password) {
    errors.password =
      t("form.validation.account_setup.password_required") ||
      "Password is required";
  }
  if (!company && companyDependantProducts.some((p) => products.includes(p))) {
    errors.company =
      t("form.validation.account_setup.company_required") ||
      "Company is required";
  }
  if (!premiseGroup) {
    errors.premiseGroup =
      t("form.validation.account_setup.premise_group_required") ||
      "Premise group is required";
  }
  if (!products.length) {
    errors.products =
      t("form.validation.account_setup.products_required") ||
      "At least one product is required";
  }

  return errors;
};
