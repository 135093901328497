import { Box, SxProps, Tooltip, useTheme } from "@mui/material";
import { t } from "i18next";
import AdsDisabled from "../../../assets/images/svg/ad-block-icon.svg";
import PromotionsAdsDisabled from "../../../assets/images/svg/promotions-blocked.svg";

import {
  IAdPermission,
  IContentChannel,
} from "../../../../../../types/NendaTypes";

export const signageDisabled = (contentChannel: IContentChannel) => {
  return (
    !contentChannel.adPermissions.signage.fullTakeoverAllowed &&
    !contentChannel.adPermissions.signage.bannerAllowed
  );
};

export const adsDisabled = (contentChannel: IContentChannel) => {
  return (
    !contentChannel.adPermissions.external.fullTakeoverAllowed &&
    !contentChannel.adPermissions.external.bannerAllowed
  );
};

const DisabledFeatures = ({
  permissions,
  sx,
  iconColor,
}: {
  permissions: IAdPermission;
  sx: SxProps;
  iconColor?: string;
}) => {
  const theme = useTheme();

  const adsAreDisabled =
    !permissions.external.fullTakeoverAllowed &&
    !permissions.external.bannerAllowed;
  const signageIsDisabled =
    !permissions.signage.fullTakeoverAllowed &&
    !permissions.signage.bannerAllowed;

  const signageOrAdsIsDisabled = adsAreDisabled || signageIsDisabled;

  const items = [
    {
      value: signageIsDisabled,
      styles: {
        height: "1.3rem",
        fill: iconColor || theme.palette.primary.main,
        color: iconColor || theme.palette.primary.main,
      },
      icon: PromotionsAdsDisabled,
      description: "customerportal.notifications.signage_disabled",
    },
    {
      value: adsAreDisabled,
      styles: {
        width: "1.3rem",
        fill: iconColor || theme.palette.primary.main,
      },
      icon: AdsDisabled,
      description: "customerportal.notifications.ads_disabled",
    },
  ];

  return (
    <>
      {signageOrAdsIsDisabled && (
        <Box sx={{ ...sx }}>
          {items.map((item) => {
            return (
              item.value && (
                <Tooltip title={t(item.description)} enterDelay={1000}>
                  <Box>
                    <item.icon
                      style={{
                        ...item.styles,
                        height: "1.3rem",
                        margin: "0.2rem",
                      }}
                    />
                  </Box>
                </Tooltip>
              )
            );
          })}
        </Box>
      )}
    </>
  );
};

export default DisabledFeatures;
