import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  getEverySecondHourOfDay,
  getHoursAndHalfHoursOfDay,
} from "../../../../utils/timeline";

const DailyTimeScale = () => {
  const fractions = getHoursAndHalfHoursOfDay();
  const mobileFractions = getEverySecondHourOfDay();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fractionsToRender = isMobile ? mobileFractions : fractions;
  return (
    <Box
      sx={{
        display: "flex",
        height: "1rem",
        borderRight: "1px solid #ccc",
      }}
    >
      {fractionsToRender.map((fraction, i) => {
        const isEvenHour = fraction.value.minute === 0;
        return (
          <Tooltip title={fraction.prettyTime} key={fraction.prettyTime + i}>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                height: "100%",
                alignItems: "flex-end",
              }}
            >
              {isEvenHour ? (
                <Box
                  sx={(theme) => ({
                    borderLeft: "1px solid #ccc",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      borderColor: theme.palette.primary.light,
                    },
                  })}
                >
                  <Typography
                    variant="body1"
                    fontSize={"0.7rem"}
                    sx={{ cursor: "default" }}
                  >
                    {fraction.value.hour}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={(theme) => ({
                    borderLeft: "1px solid #ccc",
                    width: "100%",
                    height: "10px",
                    display: isMobile ? "none" : "flex",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      borderColor: theme.palette.primary.light,
                    },
                  })}
                ></Box>
              )}
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default DailyTimeScale;
