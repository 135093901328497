import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { t } from "i18next";
import { useSelector } from "react-redux";
import {
  AssetType,
  LoadingStatus,
  NendaProduct,
} from "../../../../../../../types/NendaTypes";
import { store } from "../../../../store";
import {
  UploadFileAndCreateAsset,
  getAssetUploadStatus,
} from "../../../../store/reducers/companyReducer";
import TextInput from "../../../../ui-components/input/TextInput";
import { FileUpload } from "../../../FileUpload";
import constants from "@shared/constants";
import { FileUploadOutlined } from "@mui/icons-material";

const style = {
  textField: {
    maxWidth: "50ch",
    width: "100%",
  },
};

export const VALID_ASSET_FILE_TYPES = [
  "video/mp4",
  "video/avi",
  "image/png",
  "image/jpeg",
];

export const PROMOTION_UPLOAD_REQUEST_ID = "promotion";
interface CreateAssetFormProps {
  named?: boolean;
  submitText?: string;
  description?: string;
  requestId?: string;
  companyId: string;
  premiseId?: string;
  onClose: () => void;
}

export const CreateAssetForm: React.FC<CreateAssetFormProps> = ({
  named = true,
  submitText,
  description,
  requestId = PROMOTION_UPLOAD_REQUEST_ID,
  companyId,
  premiseId,
  onClose,
}) => {
  const [operation, setOperation] = useState<"upload" | "create">("upload");
  const [name, setName] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const status = useSelector(getAssetUploadStatus);
  const nameValidated = named ? !!name : true;

  const validateInput = () => nameValidated && files && files.length > 0;

  const reset = () => {
    setName("");
    setOperation("upload");
    setFiles([]);
  };

  useEffect(() => {
    if (status === "succeeded") {
      reset();
      onClose();
    }
  }, [status]);

  const assetTypeFromFile = (file: File): AssetType | undefined => {
    if (!file) {
      return undefined;
    }
    const typeCategory = file.type.split("/")[0];
    switch (typeCategory) {
      case "image":
        return AssetType.IMAGE;
      case "video":
        return AssetType.VIDEO;
      default:
        return undefined;
    }
  };

  const createAsset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const assetType = assetTypeFromFile(files[0]);
    if (!assetType) return;

    store.dispatch(
      UploadFileAndCreateAsset({
        companyId,
        image: files[0],
        scope: NendaProduct.SIGNAGE,
        assetType,
        assetName: name,
        premiseId,
        requestId,
      })
    );
  };

  const { SIGNAGE_SLOT_DURATION: maxDuration } = constants.Signage();
  const minDuration = 5;

  return (
    <Box sx={{ width: "100%", my: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          width: "100%",
        }}
      >
        {named && (
          <TextInput
            sx={style.textField}
            id="name"
            label="Name"
            variant="standard"
            value={name}
            autoComplete="off"
            required
            onChange={(e) => setName(e.target.value)}
          />
        )}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Box>
            {operation === "upload" && (
              <FileUpload
                files={files}
                onFileChange={setFiles}
                allowedFileTypes={VALID_ASSET_FILE_TYPES}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant={"caption"}>
              {description ||
                t("customerportal.pages.promotions.video_duration", {
                  minDuration,
                  maxDuration,
                })}
            </Typography>
          </Box>
        </Stack>

        <LoadingButton
          sx={{ mt: 1, width: isMobile ? "100%" : undefined }}
          disabled={!validateInput()}
          onClick={createAsset}
          loading={status === LoadingStatus.LOADING}
          variant="contained"
          endIcon={<FileUploadOutlined />}
        >
          {submitText || t("common.create")}
        </LoadingButton>
      </Box>
    </Box>
  );
};
