import { t } from "i18next";
import { Hour, Minute } from "../../types/HelperTypes";
import { Time } from "../../types/NendaTypes";

export const formatDurationInSeconds = (durationInSeconds: number): string => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const formattedHours = hours > 0 ? `${hours}h ` : "";
  const formattedMinutes = minutes > 0 ? `${minutes}m ` : "";
  const formattedSeconds = seconds > 0 ? `${seconds.toFixed(1)}s` : "";

  return formattedHours + formattedMinutes + formattedSeconds;
};

export function formatDurationInMinutes(minutes: number): string {
  if (minutes < 60) {
    return `${minutes} ${
      minutes === 1 ? t("common.minute") : t("common.minutes")
    }`;
  } else if (minutes < 1440) {
    // 1440 minutes = 1 day
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (remainingMinutes === 0) {
      return `${hours} ${hours === 1 ? t("common.hour") : t("common.hours")}`;
    }
    return `${hours} ${hours === 1 ? t("common.hour") : t("common.hours")} ${t(
      "common.and"
    )} ${remainingMinutes} ${
      remainingMinutes === 1 ? t("common.minute") : t("common.minutes")
    }`;
  } else {
    const days = Math.floor(minutes / 1440);
    const remainingMinutes = minutes % 1440;
    if (remainingMinutes === 0) {
      return `${days} ${days === 1 ? t("common.day") : t("common.days")}`;
    }
    return `${days} ${days === 1 ? t("common.day") : t("common.days")} ${t(
      "common.and"
    )} ${remainingMinutes} ${
      remainingMinutes === 1 ? t("common.minute") : t("common.minutes")
    }`;
  }
}

type SelectedDays = string[];

export interface ScheduleOptions {
  startDate?: string | null;
  endDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  selectedDays?: SelectedDays | [];
}

export function generateScheduleDescription({
  startDate,
  endDate,
  startTime,
  endTime,
  selectedDays,
}: ScheduleOptions): string {
  let description = "";

  // Check if there are selected days
  if (selectedDays?.length) {
    if (selectedDays.length === 7) {
      // If all days are selected, it's "Every day"
      description = "Daily";
    } else {
      // If specific days are selected, list them
      description = `${selectedDays.map((day) => day.slice(0, 3)).join(", ")}`;
    }
  } else {
    description += "never";
  }

  // Check if there's a time range
  if (!!startTime && !!endTime) {
    // If there are selected days, append "Between" to the description
    if (description) {
      description += ` between ${startTime} to ${endTime}`;
    }
  } else {
    description += ` during no time of the day`;
  }

  // Check if there's a date range
  if (!!startDate && !!endDate) {
    // If there's already a description, append "From" and "to" to the date range
    if (description) {
      description += ` from ${startDate} to ${endDate}`;
    }
  } else {
    description += ` from no date to no date`;
  }

  return description;
}

export function getDays(days: string[]): string {
  let description = "";

  // Check if there are selected days
  if (days && days.length > 0) {
    if (days.length) {
      description = "No days selected";
    }
    if (days.length === 7) {
      // If all days are selected, it's "Every day"
      description = "Every day";
    } else {
      // If specific days are selected, list them
      description = `${days.map((day) => day.slice(0, 3)).join(", ")}`;
    }
  }
  return description;
}

export function dateWithoutTimezone(inputDate: string | Date): Date {
  let date: Date;
  if (typeof inputDate === "string") {
    date = new Date(inputDate);
  } else if (inputDate instanceof Date) {
    date = new Date(inputDate.getTime());
  } else {
    throw new Error("Invalid input");
  }

  // Tweak the timezone offset
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date;
}

export const dateStringWithoutTimezone = (dateInput: Date) => {
  const date = new Date(dateInput);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  const string = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  return string;
};

export function getFormattedTimeFromDate(input: Date): string {
  const hours = input.getHours().toString().padStart(2, "0");
  const minutes = input.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
}

export function getFormattedTimeFromISOStringDate(input: string): string {
  const match = input.match(/\d{2}:\d{2}/);

  const formattedTime = match ? match[0] : null;

  return formattedTime || "";
}

export function isoDateWithoutTimeZone(date: Date | string): string {
  const dateInput = date instanceof Date ? date : new Date(date);

  const timestamp = dateInput.getTime() - dateInput.getTimezoneOffset() * 60000;
  const correctDate = new Date(timestamp);
  // correctDate.setUTCHours(0, 0, 0, 0); // uncomment this if you want to remove the time
  return correctDate.toISOString();
}

export function dateToHourMinuteObject(date): Time {
  if (!(date instanceof Date)) {
    throw new Error("Invalid input: must be a Date object");
  }

  const hour = date.getHours() as Hour;
  const minute = date.getMinutes() as Minute;

  return { hour, minute };
}

// Function to convert {hour, minute} object to Date object
export function hourMinuteObjectToDate(hourMinuteObject: Time) {
  if (!hourMinuteObject) {
    return new Date();
  }
  const { hour, minute } = hourMinuteObject;
  if (typeof hour !== "number" || typeof minute !== "number") {
    throw new Error(
      "Invalid input: {hour, minute} object must have numeric values"
    );
  }

  const date = new Date();
  date.setHours(hour);
  date.setMinutes(minute);
  date.setSeconds(0); // Optional: You can set seconds and milliseconds to 0 if needed

  return date;
}

export function timeToMinutes(time: { hour: number; minute: number }): number {
  return time.hour * 60 + time.minute;
}

export function timeToMinutesByType(
  time: { hour: number; minute: number },
  type: "start" | "end"
): number {
  if (time.hour === 0 && time.minute === 0 && type === "end") {
    return 1440;
  } else {
    return time.hour * 60 + time.minute;
  }
}

export function minutesToTime(totalMinutes: number): {
  hour: Hour;
  minute: Minute;
} {
  // Ensure totalMinutes wraps around a 24-hour clock
  const wrappedMinutes = ((totalMinutes % 1440) + 1440) % 1440;
  const hour = Math.floor(wrappedMinutes / 60);
  const minute = Math.floor(wrappedMinutes % 60);
  return { hour, minute } as Time;
}

export function roundTimeTo30Minutes(time: { hour: Hour; minute: Minute }): {
  hour: Hour;
  minute: Minute;
} {
  let totalMinutes = time.hour * 60 + time.minute;
  totalMinutes = Math.round(totalMinutes / 30) * 30;
  const hour = (Math.floor(totalMinutes / 60) % 24) as Hour;
  const minute = (totalMinutes % 60) as Minute;
  return { hour, minute };
}
