import { Sort } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { t } from "i18next";
import { SetStateAction } from "react";
import {
  ContentTimeline,
  IDailyContentPlaylist,
  ITimeLineItem,
} from "../../../../../types/NendaTypes";
import { sortTimeLineItems } from "./helpers/sortSlots";
import { isValidTimeIntervalAndOverlaps } from "./helpers/validation";
import ChannelRangeSelectItem from "./ChannelRangeSelectItem";

const ChannelRangeSelect = ({
  timeline,
  handleChange,
}: {
  timeline: ContentTimeline;
  handleChange: (value: SetStateAction<IDailyContentPlaylist>) => void;
}) => {
  const handleSortTimeSlots = () => {
    const sortedTimeSlots = sortTimeLineItems(
      JSON.parse(JSON.stringify(timeline))
    );
    handleChange((prevState) => ({ ...prevState, timeline: sortedTimeSlots }));
  };

  return (
    <Box
      sx={{
        my: "0.5rem",
        p: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          borderBottom: "1px solid #e7e7e7",
        }}
      >
        <Typography variant="subtitle1">
          {t("customerportal.pages.daily_playlists.playlist_channels")}
        </Typography>
        <Button
          startIcon={<Sort />}
          variant="text"
          onClick={handleSortTimeSlots}
        >
          Sort selection
        </Button>
      </Box>
      {timeline.map((tl: ITimeLineItem, index) => {
        const isValid = isValidTimeIntervalAndOverlaps(
          tl.interval,
          timeline.map((t) => t.interval).filter((_, i) => i !== index) // Exclude current item
        );
        return (
          <ChannelRangeSelectItem
            key={timeline.length + tl.content + index}
            index={index}
            timelineItem={tl}
            handleChange={handleChange}
            isForbidden={!isValid}
          />
        );
      })}
    </Box>
  );
};

export default ChannelRangeSelect;
