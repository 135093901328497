import { Box, Button, Fade, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import {
  IContentChannel,
  NendaProduct,
  ScheduleContentRefType,
} from "../../../../types/NendaTypes";
import channelPosterPlaceholder from "../assets/images/poster-placeholder.png";

import DisabledFeatures from "../components/Content/components/DisabledFeatures";
import PlayContentModal from "../components/Content/components/PlayContentModal";
import { CustomerPortalState, store } from "../store";
import { selectCategorizedContentChannelsByNavigatedPremise } from "../store/reducers/contentChannelReducer";
import {
  getScreens,
  selectScreensByProductAndPremise,
} from "../store/reducers/screenReducer";
import { selectNavigationScope } from "../store/reducers/workspaceReducer";
import { isContentChannel } from "../components/DailyPlaylist/PlaylistTimeline";
import { SetContentOverrideModal } from "../store/reducers/customizationReducer";

const Channels = () => {
  const scope = useSelector(selectNavigationScope);

  const screens = useSelector((state: CustomerPortalState) =>
    selectScreensByProductAndPremise(
      state,
      NendaProduct.SIGNAGE,
      scope.premise || ""
    )
  );

  const channelsByCategory = useSelector(
    selectCategorizedContentChannelsByNavigatedPremise
  );

  useEffect(() => {
    if (scope.premise) {
      store.dispatch(getScreens(scope.premise || ""));
    }
  }, [scope.premise]);

  const handleContentOverrideModalClick = (
    id: string,
    contentRefType: ScheduleContentRefType
  ) => {
    store.dispatch(SetContentOverrideModal(id, contentRefType));
  };

  return (
    <Fade in={true}>
      <Box>
        <PlayContentModal screens={screens} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            mb: "1rem",
          }}
        >
          <Typography
            variant="h3"
            sx={(theme) => ({
              textTransform: "capitalize",
              color: theme.palette.text.primary,
            })}
          >
            {t("customerportal.pages.content_channels.title")}
          </Typography>
          <Typography
            sx={{ paddingBottom: "1rem", maxWidth: "40rem" }}
            variant="body1"
          >
            {t("customerportal.pages.content_channels.helper_text")}
          </Typography>
        </Box>
        {Object.keys(channelsByCategory).map((category) => (
          <Box sx={{ paddingBottom: "1rem" }} key={category}>
            <Typography sx={{ paddingBottom: ".5rem" }} variant="h6">
              {category}
            </Typography>
            <Carousel
              infinite={false}
              partialVisible={true}
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 3000, min: 2001 },
                  items: 7,
                  slidesToSlide: 7,
                  partialVisibilityGutter:
                    channelsByCategory[category].length <= 7 ? 0 : 20,
                },
                prettyLargeDesktop: {
                  breakpoint: { max: 2000, min: 1560 },
                  items: 6,
                  slidesToSlide: 6,
                  partialVisibilityGutter:
                    channelsByCategory[category].length <= 6 ? 0 : 20,
                },
                desktop: {
                  breakpoint: { max: 1560, min: 1024 },
                  items: 5,
                  slidesToSlide: 5,
                  partialVisibilityGutter:
                    channelsByCategory[category].length <= 5 ? 0 : 20,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 700 },
                  items: 3,
                },
                mobile: {
                  breakpoint: { max: 700, min: 0 },
                  items: 2,
                },
              }}
            >
              {channelsByCategory[category].map((channel: IContentChannel) => {
                return (
                  <Box
                    key={channel._id}
                    sx={{
                      mr: "12px",
                      height: "250px",
                      maxWidth: "180px",
                      borderRadius: "0.3rem",
                      overflow: "hidden",
                      position: "relative",
                      "&:hover .overlay": {
                        opacity: 1,
                      },
                    }}
                  >
                    {isContentChannel(channel) && (
                      <DisabledFeatures
                        permissions={channel.adPermissions}
                        sx={{
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1,
                          top: 0,
                          right: 0,
                          backgroundColor: "white",
                          p: "0.2rem",
                        }}
                      />
                    )}

                    <Box
                      sx={(theme) => ({
                        height: "100%",
                        width: "100%",
                        backgroundImage: `url(${
                          channel.posterUrl ||
                          channel.logo?.locator ||
                          channelPosterPlaceholder
                        })`,
                        backgroundColor: theme.palette.primary.dark,
                        backgroundSize: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      })}
                    />
                    <Box
                      className="overlay"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 0,
                        backgroundColor: "rgba(0,0,0,0.5)",
                        transition: "opacity 0.3s",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                        justifyContent: "center",
                        px: "0.5rem",
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleContentOverrideModalClick(
                            channel._id,
                            ScheduleContentRefType.CONTENT_CHANNEL
                          )
                        }
                        variant="contained"
                      >
                        {t("common.play_now")}
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "rgba(0,0,0,0.5)",
                        textAlign: "center",
                        py: "0.5rem",
                        px: "0.2rem",
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        color: "white",
                      }}
                    >
                      <Typography variant="button">{channel.name}</Typography>
                    </Box>
                  </Box>
                );
              })}
            </Carousel>
          </Box>
        ))}
      </Box>
    </Fade>
  );
};

export default Channels;
