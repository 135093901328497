import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  ModalProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import { CustomDialogAction } from "../../components/Bookings/create/CreateEditPromotionModal";

type DefaultDialogProps = {
  onClose: (params: { isConfirmed: boolean }) => void;
  disabled?: boolean;
  defaultSaveButton?: boolean;
  width?: string;
  title?: string;
  customActions?: CustomDialogAction[];
  showCancelButton?: boolean;
} & ModalProps;

const DefaultDialog: React.FC<DefaultDialogProps> = ({
  showCancelButton = true,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleAction = ({ isConfirmed }) => {
    props.onClose({ isConfirmed });
  };

  const dialogProps = { ...props };
  delete dialogProps.defaultSaveButton;
  delete dialogProps.customActions;
  delete dialogProps.title;

  return (
    <Dialog
      {...dialogProps}
      fullScreen={isMobile}
      sx={{
        "& .MuiDialog-paper": {
          width: props.width || "50vw",
          maxWidth: "100%",
          transform: "translateZ(0)",
          position: "relative",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 100,
          left: 0,
          height: "3rem",
          display: "flex",
          p: "1.5rem 1rem 1.5rem 1.5rem",
          justifyContent: props.title ? "space-between" : "flex-end",
          backgroundColor: "white",
        }}
      >
        {props.title && <Typography variant="h6">{props.title}</Typography>}
        <CloseOutlined
          onClick={() => handleAction({ isConfirmed: false })}
          sx={{
            fill: theme.palette.primary.main,
            cursor: "pointer",
          }}
        />
      </Box>
      <Container
        sx={(theme) => ({
          background: theme.palette.common.white,
          display: "flex",
          flex: 1,
          overflowY: "scroll",
          flexDirection: "column",
          mt: "4rem",
          height: "100%",
          [theme.breakpoints.up("xl")]: {
            padding: "2rem 4rem",
          },
          "&::-webkit-scrollbar": {
            backgroundColor: "transparent",
            position: "absolute",
            width: "0.5rem",
          },
          "&::-webkit-scrollbar-thumb": {
            background: theme.palette.transparent.blue,
            borderRadius: "0.5rem",
          },
        })}
      >
        {props?.children}
      </Container>

      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          padding: "1rem",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          boxShadow: "10px -16px 125px 0px rgba(0,0,0,0.1)",

          [theme.breakpoints.down("md")]: {},
        })}
      >
        {showCancelButton && (
          <Button
            variant="text"
            onClick={() => handleAction({ isConfirmed: false })}
            sx={{
              padding: isMobile ? "0.8rem 1rem" : "0.3rem 0.5rem",
              width: isMobile ? "100%" : "auto",
            }}
          >
            {t("common.close")}
          </Button>
        )}
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          {props.defaultSaveButton !== false && (
            <Button
              disabled={props.disabled}
              variant="contained"
              onClick={() => handleAction({ isConfirmed: true })}
              sx={{
                padding: isMobile ? "0.8rem 1rem" : "0.3rem 0.5rem",
                width: isMobile ? "100%" : "auto",
              }}
            >
              {t("common.save")}
            </Button>
          )}
          {props.customActions && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                width: isMobile ? "100%" : "auto",
              }}
            >
              {props.customActions?.map((action) => (
                <Button
                  key={action.label}
                  disabled={action.disabled}
                  variant={action.variant || "contained"}
                  onClick={() => action.onClick()}
                  sx={{
                    padding: isMobile ? "0.8rem 1rem" : "0.3rem 0.5rem",
                    width: isMobile ? "100%" : "auto",
                    display:
                      action?.visible === undefined
                        ? "block"
                        : action?.visible
                        ? "block"
                        : "none",
                  }}
                >
                  {action.label}
                </Button>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default DefaultDialog;
