import { ArrowForward } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  NavigationLevel,
  NendaProduct,
} from "../../../../../../../types/NendaTypes";
import { CustomerPortalState, store } from "../../../../store";
import {
  getPermissions,
  selectUserRole,
} from "../../../../store/reducers/authReducer";
import {
  CustomizationState,
  ToggleMenu,
} from "../../../../store/reducers/customizationReducer";
import { MenuItem } from "../../../menuItems";
import { validateRules } from "../NavGroup";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

type NavItemProps = {
  item: MenuItem;
  level: number;
  isSubMenuItem?: boolean;
  products: NendaProduct[];
  navigationLevel: NavigationLevel;
};

const NavItem = ({
  item,
  isSubMenuItem,
  products,
  navigationLevel,
}: NavItemProps) => {
  const userRole = useSelector(selectUserRole);
  const permissions = useSelector(getPermissions);
  const theme = useTheme();
  const customization: CustomizationState = useSelector<
    CustomerPortalState,
    CustomizationState
  >((state) => state.customization);

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} />
  ) : (
    <FiberManualRecordIcon />
  );

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleLinkClick = () => {
    if (isMobile) store.dispatch(ToggleMenu());
  };

  const listItemProps = {
    component: forwardRef((props, _ref) => (
      <Link
        {...props}
        to={`${item.url}`}
        target={itemTarget}
        onClick={handleLinkClick}
      />
    )),
  };

  listItemProps.component.displayName = "ListItemButton";

  const params = useParams();
  const [selected, setSelected] = useState<string>();
  const isSelected = selected == item.id;
  const url = params["*"];

  useEffect(() => {
    // Check if the URL contains the item.id
    if (url?.includes(item.id)) {
      // Deselect the previously selected item
      if (selected && selected !== item.id) {
        setSelected(undefined);
      }
      // Select the current item
      setSelected(item.id);
    } else if (selected === item.id) {
      // Deselect the current item if it's not in the URL
      setSelected(undefined);
    }
  }, [url, item, selected]);

  const isValid = validateRules(
    item,
    products || [],
    navigationLevel || [],
    userRole,
    permissions
  );
  if (!isValid) return null;

  const showOpenIcons = isSubMenuItem && !customization.opened;
  const showClosedIcons = !isSubMenuItem;
  const showIcon = showOpenIcons || showClosedIcons;

  const listItemStyles = {
    overflow: "hidden",
    display: "flex",
    justifyContent: "flex-start",
    height: "3rem",
    alignItems: "center",
    color: isSubMenuItem
      ? theme.palette.text.primary + " !important"
      : theme.palette.text.secondary + " !important",
    margin: "0.5rem 0 0.5rem 0",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    "&.Mui-selected": {
      background: isSubMenuItem
        ? theme.palette.gradient.lightBlue + " !important"
        : theme.palette.common.white + " !important",
      "&:hover": {
        backgroundColor: theme.palette.background.default + " !important",
        color: theme.palette.text.secondary + " !important",
      },
      color: isSubMenuItem
        ? theme.palette.text.secondary + " !important"
        : theme.palette.text.primary,
    },
  };

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={listItemStyles}
      selected={isSelected}
    >
      {showIcon && (
        <div
          style={{
            color: "inherit",
            display: "flex",
            justifyContent: "center",
            width: isSubMenuItem ? 14 : 24,
            margin: customization.opened ? "0 1rem 0 0" : "0 auto",
          }}
        >
          {itemIcon}
        </div>
      )}
      {customization.opened && (
        <>
          <ListItemText
            sx={{
              whiteSpace: "nowrap",
            }}
            primary={
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography color="inherit" variant={"body2"}>
                  {item.title}
                </Typography>
                {isSelected && (
                  <ArrowForward
                    style={{
                      width: 18,
                      height: 18,
                      fill: isSubMenuItem
                        ? theme.palette.common.white
                        : theme.palette.text.primary,
                    }}
                  />
                )}
              </div>
            }
          />
          {item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </>
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
