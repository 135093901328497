import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AssetType, IAsset, NavigationLevel } from "@nendaTypes/NendaTypes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentNavigationLevel,
  selectNavigationScope,
} from "../../store/reducers/workspaceReducer";
import DefaultDialog from "../../ui-components/dialog/dialog";
import { CreateAssetForm } from "../Premise/Signage/Asset/CreateAssetForm";
import SelectableAssetsGrid from "./DigitalSignageAssetGrid";
import { SIGNAGE_UPLOAD_REQUEST_ID } from "../../Pages/DigitalSignagePlaylist";
import { CustomerPortalState, store } from "../../store";
import { useVideoDuration } from "@client/hooks/useVideoFileInformation";
import { ClearUploadedFiles } from "../../store/reducers/companyReducer";
import { t } from "i18next";

type DigitalSignageAddAssetModalProps = {
  onClose: (assetId: string, duration?: number) => void;
  assets: IAsset[];
  disabled?: boolean;
};

const DigitalSignageAddAssetModal = (
  props: DigitalSignageAddAssetModalProps
) => {
  const navigationLevel = useSelector(selectCurrentNavigationLevel);

  const scope = useSelector(selectNavigationScope);
  const [selectedAssetItem, setSelectedAssetItem] = useState<{
    id: string;
    duration?: number;
  } | null>(null);
  const [filter, setFilter] = useState<AssetType | null>(null);
  const uploadedAssets = useSelector(
    (state: CustomerPortalState) => state.company.uploadedFiles
  );
  const latestUploadedAssetUrl = uploadedAssets[SIGNAGE_UPLOAD_REQUEST_ID];
  const latestUploadedAsset = props.assets.find(
    (a) => a.locator === latestUploadedAssetUrl
  );
  const { duration } = useVideoDuration(latestUploadedAsset);

  const [open, setOpen] = useState<boolean>(false);
  const [showUploadAssetForm, setShowUploadAssetForm] = useState(false);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => {
    setOpen(false);
    setShowUploadAssetForm(false);
  };

  const onConfirm = (
    isConfirmed: boolean,
    item?: { id: string; duration?: number } | null
  ) => {
    if (isConfirmed && item) {
      props.onClose(item.id, item.duration);
      setSelectedAssetItem(null);
      if (latestUploadedAsset) {
        store.dispatch(ClearUploadedFiles(SIGNAGE_UPLOAD_REQUEST_ID));
      }
    }
    handleCloseDialog();
  };
  useEffect(() => {
    if (latestUploadedAsset) {
      const needsDuration = latestUploadedAsset?.type === AssetType.VIDEO;
      if (needsDuration && !duration) {
        return;
      }

      onConfirm(true, {
        id: latestUploadedAsset._id,
        duration: duration || 0,
      });
    }
  }, [latestUploadedAsset, duration]);

  const filteredAssets = props.assets.filter((asset) => {
    if (!filter) {
      return true;
    }
    return asset.type === filter;
  });
  const sortedAssets = filteredAssets.sort((a, b) => {
    return (
      new Date(b.updatedAt as Date).getTime() -
      new Date(a.updatedAt as Date).getTime()
    );
  });

  const handleSelectAsset = (assetId: string, duration?: number) => {
    setSelectedAssetItem({ id: assetId, duration });
  };

  const handleSetFilter = (filter: AssetType | null) => {
    setFilter(filter);
    setSelectedAssetItem(null);
  };

  const filters = [
    {
      title: "All",
      value: null,
      onClick: () => {
        handleSetFilter(null);
      },
    },
    {
      title: "Image",
      value: AssetType.IMAGE,
      onClick: () => {
        handleSetFilter(AssetType.IMAGE);
      },
    },
    {
      title: "Video",
      value: AssetType.VIDEO,
      onClick: () => {
        handleSetFilter(AssetType.VIDEO);
      },
    },
  ];

  const handleToggleUploadAssetModal = () => {
    setShowUploadAssetForm(!showUploadAssetForm);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Button onClick={handleOpenDialog} disabled={props.disabled}>
        {t("customerportal.pages.digital_signage.select_asset")}
      </Button>
      <DefaultDialog
        showCancelButton={false}
        open={open}
        onClose={() => onConfirm(false)}
        title="Add asset"
        defaultSaveButton={false}
        width={isMobile ? "100%" : "80%"}
        customActions={[
          {
            label: "Add",
            onClick: () => onConfirm(true, selectedAssetItem),
            disabled: !selectedAssetItem?.id,
          },
        ]}
      >
        <>
          <Box
            sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
          >
            <AssetModalFilter selectedFilter={filter} filters={filters} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flex: 1,
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleToggleUploadAssetModal}
                sx={{ width: !isMobile ? undefined : "100%", my: "1rem" }}
              >
                {showUploadAssetForm
                  ? t(
                      "customerportal.pages.digital_signage.select_asset_from_library"
                    )
                  : t("customerportal.pages.digital_signage.upload_asset")}
              </Button>
            </Box>
          </Box>
          {showUploadAssetForm ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ maxWidth: "80%", width: "100%", mb: "2rem" }}>
                <CreateAssetForm
                  named={false}
                  submitText="Upload"
                  description={
                    t(
                      "customerportal.pages.digital_signage.supported_file_types"
                    ) as string
                  }
                  onClose={handleToggleUploadAssetModal}
                  premiseId={
                    navigationLevel === NavigationLevel.PREMISE
                      ? scope.premise
                      : undefined
                  }
                  companyId={scope.company || ""}
                  requestId={SIGNAGE_UPLOAD_REQUEST_ID}
                />
              </Box>
            </Box>
          ) : (
            <SelectableAssetsGrid
              assets={sortedAssets}
              onSelectAsset={handleSelectAsset}
              selectedAssetId={selectedAssetItem?.id}
            />
          )}
        </>
      </DefaultDialog>
    </>
  );
};
type AssetModalFilter = {
  title: string;
  value: string | null;
  onClick: () => void;
};

const AssetModalFilter = ({
  filters,
  selectedFilter,
}: {
  filters: AssetModalFilter[];
  selectedFilter: string | null;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        borderBottom: "1px solid #e0e0e0",
        pb: "1rem",
        mb: "0.5rem",
      }}
    >
      <Typography
        variant="caption"
        sx={{ textTransform: "uppercase", fontSize: "0.6rem", color: "gray" }}
      >
        Asset type
      </Typography>
      {filters.map((item) => {
        const isSelected = selectedFilter === item.value;
        return (
          <Button
            size="small"
            key={item.value}
            variant={isSelected ? "contained" : "outlined"}
            onClick={item.onClick}
          >
            {item.title}
          </Button>
        );
      })}
    </Box>
  );
};

export default DigitalSignageAddAssetModal;
