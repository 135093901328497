import { Box, Chip, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { AssetType, NendaProduct } from "../../../../../../../types/NendaTypes";
import { getPrettyTimeFromTimeObject } from "../../../../../../utils/timeline";
import { CustomerPortalState } from "../../../../store";
import { selectAllAssets } from "../../../../store/reducers/assetReducer";
import { selectScreensByProduct } from "../../../../store/reducers/screenReducer";
import { CreateUpdateObject } from "../CreateEditPromotionModal";
import { FOREVER_END_DATE } from "./DateRangeSelect";
import { days } from "./DaySelect";
import { t } from "i18next";
import LabelValueRow from "../../../../ui-components/display/LabelValueRow";
import ClickableImagePreview from "@client/components/CustomerPortal/ui-components/media/ClickableImagePreview";

const ConfirmPromotion = ({
  promotionState,
}: {
  promotionState: CreateUpdateObject & { screens: string[] };
}) => {
  const theme = useTheme();
  const assets = useSelector(selectAllAssets);
  const screens = useSelector((state: CustomerPortalState) =>
    selectScreensByProduct(state, NendaProduct.SIGNAGE)
  );
  const asset = assets.find(
    (a) => a._id == promotionState?.playlist?.[0]?.asset
  );
  const assetDuration = promotionState?.playlist?.[0]?.durationInSeconds;
  const promotionEnd = promotionState.schedule?.date.end;
  const isEndForever = promotionEnd === format(FOREVER_END_DATE, "yyyy-MM-dd");
  const prettyEndDisplayText = isEndForever
    ? t("common.no_end_date")
    : promotionEnd;
  const startTime =
    promotionState.schedule?.time.start &&
    getPrettyTimeFromTimeObject(promotionState.schedule?.time.start, 0);
  const endTime =
    promotionState.schedule?.time.end &&
    getPrettyTimeFromTimeObject(promotionState.schedule?.time.end, 0);

  const prettyTimeRange = () => {
    if (
      promotionState.schedule?.time.start.hour === 0 &&
      promotionState.schedule?.time.start.minute === 0 &&
      promotionState.schedule?.time.end.hour === 0 &&
      promotionState.schedule?.time.end.minute === 0
    ) {
      return t("form.validation.promotions.schedule.no_time_rule");
    }
    return `${startTime} - ${endTime}`;
  };

  const isAllDays = promotionState.schedule?.days?.length === 7;
  const prettyDays = isAllDays
    ? t("form.validation.promotions.schedule.no_day_of_week_rule")
    : promotionState.schedule?.days
        .map(
          (di) => " " + days.find((d) => d.dayIndex === di)?.label.slice(0, 3)
        )
        .toString();

  return (
    <Box
      sx={{
        minWidth: "20rem",
        width: "100%",
        display: "flex",
        gap: "1rem",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{ width: "50%", [theme.breakpoints.down("md")]: { width: "100%" } }}
      >
        <ClickableImagePreview
          url={asset?.locator || ""}
          type={asset?.type || AssetType.IMAGE}
          thumbnail={asset?.thumbnailLocator}
        />
      </Box>
      <Box
        sx={{
          py: "1rem",
          width: "50%",
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: { width: "100%" },
        }}
      >
        <LabelValueRow
          label={t("common.file.name")}
          value={promotionState?.name}
        />
        <LabelValueRow label={t("common.media")} value={asset?.name} />
        <LabelValueRow
          label={t("common.file.media_duration")}
          value={
            assetDuration
              ? `${assetDuration + ` ${t("common.second.other")}`}`
              : undefined
          }
        />
        <LabelValueRow
          label={t("customerportal.pages.promotions.date_start")}
          value={`${promotionState.schedule?.date.start}`}
        />
        <LabelValueRow
          label={t("customerportal.pages.promotions.date_end")}
          value={`${prettyEndDisplayText}`}
        />
        <LabelValueRow
          label={t("customerportal.pages.promotions.time_range")}
          value={`${prettyTimeRange()}`}
        />
        <LabelValueRow label={t("common.days")} value={prettyDays} />
        <LabelValueRow
          label={t("customerportal.pages.promotions.prioritized")}
          value={`${
            promotionState.isHighPriority ? t("common.yes") : t("common.no")
          }`}
        />
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            [theme.breakpoints.down("md")]: { flexWrap: "wrap" },
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Gilroy-Bold !important",
              color: theme.palette.grey[500],
            }}
          >
            {t("customerportal.pages.dashboard.screens")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.5rem",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {promotionState.screens.map((screen) => {
              const screenName = screens.find((s) => s._id === screen)?.name;
              return (
                <Chip
                  key={screen}
                  label={screenName}
                  color="primary"
                  size="small"
                  sx={{
                    "& .MuiChip-labelSmall": {
                      fontSize: "0.6rem",
                      transform: "translateY(1px)",
                    },
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmPromotion;
