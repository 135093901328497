import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useState } from "react";
import SortableItem from "./SortableItem";

interface SortableListProps<T> {
  items: T[];
  renderItem: (item: T, index: number, listeners: any) => React.ReactNode;
  onChange?: (items: T[]) => void;
  disabled?: boolean;
}

function SortableList<T extends { id: string }>({
  items,
  renderItem,
  onChange,
  disabled,
}: SortableListProps<T>) {
  const [currentItems, setCurrentItems] = useState<T[]>(items);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setCurrentItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);

        const newItems = arrayMove(items, oldIndex, newIndex);

        if (onChange) {
          onChange(newItems);
        }

        return newItems;
      });
    }
  };

  if (currentItems !== items) {
    setCurrentItems(items);
  }
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={currentItems.map((item) => item.id)}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        {currentItems.map((item, index) => (
          <SortableItem
            key={item.id}
            id={item.id}
            data={item}
            index={index}
            renderItem={renderItem}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}

export default SortableList;
