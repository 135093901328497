import { ITimeInterval, Time } from "@nendaTypes/NendaTypes";
import { TimelineItemWithId } from "../DragAndDropPlaylist";
import { Hour } from "@nendaTypes/HelperTypes";
import {
  minutesToTime,
  roundTimeTo30Minutes,
  timeToMinutes,
  timeToMinutesByType,
} from "@client/utils/timeUtil";

export const calculateLeftPosition = (time: Time): number => {
  if (!time) return 0;
  return ((time.hour + time.minute / 60) / hoursInADay) * 100;
};
const hoursInADay = 24;

export const calculateWidth = (startTime: Time, endTime: Time): number => {
  const startPercentage = calculateLeftPosition(startTime);
  const endPercentage =
    calculateLeftPosition(
      endTime.hour === 0 && endTime.minute === 0
        ? { hour: 24 as Hour, minute: 0 }
        : endTime
    ) - 0.1; // - 0.1 is a hack to make space between ranges that join together

  return endPercentage - startPercentage;
};

export const calculateTimeFromPositionPercent = (
  positionPercent: number
): Time => {
  const totalMinutes = (positionPercent / 100) * 24 * 60;
  return minutesToTime(totalMinutes) as Time;
};

export function calculateTimeFromResize(
  newWidthInPixels: number,
  handle: string,
  containerWidth: number,
  item: ITimeInterval
): ITimeInterval {
  // Total minutes in a day
  const totalMinutesInDay = 24 * 60;

  // Calculate the duration in minutes represented by the new width
  const durationMinutes =
    (newWidthInPixels / containerWidth) * totalMinutesInDay;

  if (handle === "e") {
    // Resizing from the east/right side
    // Start time remains the same, calculate new end time
    const startMinutes = timeToMinutes(item.start);
    const newEndMinutes = startMinutes + durationMinutes;
    const newEndTime = minutesToTime(newEndMinutes);

    return {
      start: roundTimeTo30Minutes(item.start) as Time,
      end: roundTimeTo30Minutes(newEndTime) as Time,
    };
  } else if (handle === "w") {
    // Resizing from the west/left side
    // End time remains the same, calculate new start time
    const endMinutes = timeToMinutes(item.end);
    const newStartMinutes = endMinutes - durationMinutes;
    const newStartTime = minutesToTime(newStartMinutes);

    return {
      start: roundTimeTo30Minutes(newStartTime) as Time,
      end: roundTimeTo30Minutes(item.end) as Time,
    };
  } else {
    // Invalid handle, return the original
    return item;
  }
}
export function pixelsPerMinutes(
  minutes: number,
  containerWidth: number
): number {
  // Total minutes in a day
  const totalMinutesInDay = 24 * 60;

  // Calculate the pixels per minute
  const pixelsPerMinute = containerWidth / totalMinutesInDay;

  // Calculate the pixels for the given duration
  const pixels = pixelsPerMinute * minutes;

  return pixels;
}

export function calculateMaxConstraint(
  timelineItems: TimelineItemWithId[],
  currentTimelineItem: TimelineItemWithId,
  direction: "e" | "w",
  timelineStartMinutes: number = 0,
  timelineEndMinutes: number = 24 * 60
): number {
  const currentStartMinutes = timeToMinutes(currentTimelineItem.interval.start);
  const currentEndMinutes = timeToMinutes(currentTimelineItem.interval.end);

  // Include only intervals on the same contentChannel, excluding the current interval
  const otherIntervals = timelineItems.filter(
    (item) =>
      item.contentChannel === currentTimelineItem.contentChannel &&
      item.id !== currentTimelineItem.id // Use item.id to exclude current item
  );

  if (direction === "e") {
    // Resizing to the east/right
    const intervalsAfter = otherIntervals.filter(
      (item) => timeToMinutes(item.interval.start) >= currentEndMinutes
    );

    let nextIntervalStartMinutes = timelineEndMinutes;

    if (intervalsAfter.length > 0) {
      nextIntervalStartMinutes = Math.min(
        ...intervalsAfter.map((item) => timeToMinutes(item.interval.start))
      );
    }

    const maxEndMinutes = Math.min(
      nextIntervalStartMinutes,
      timelineEndMinutes
    );

    return maxEndMinutes;
  } else if (direction === "w") {
    // Resizing to the west/left
    const intervalsBefore = otherIntervals.filter(
      (item) => timeToMinutes(item.interval.end) <= currentStartMinutes
    );

    let previousIntervalEndMinutes = timelineStartMinutes;

    if (intervalsBefore.length > 0) {
      previousIntervalEndMinutes = Math.max(
        ...intervalsBefore.map((item) => timeToMinutes(item.interval.end))
      );
    }

    const minStartMinutes = Math.max(
      previousIntervalEndMinutes,
      timelineStartMinutes
    );

    return minStartMinutes;
  }

  return direction === "e" ? currentEndMinutes : currentStartMinutes;
}
export function intervalsOverlap(
  interval1: ITimeInterval,
  interval2: ITimeInterval
): boolean {
  const start1 = timeToMinutesByType(interval1.start, "start");
  const end1 = timeToMinutesByType(interval1.end, "end");
  const start2 = timeToMinutesByType(interval2.start, "start");
  const end2 = timeToMinutesByType(interval2.end, "end");

  return start1 < end2 && start2 < end1;
}
export function getOverlapInterval(
  currentInterval: ITimeInterval,
  otherInterval: ITimeInterval
): { start: number; end: number } | null {
  const start1 = timeToMinutesByType(currentInterval.start, "start");
  const end1 = timeToMinutesByType(currentInterval.end, "end");
  const start2 = timeToMinutesByType(otherInterval.start, "start");
  const end2 = timeToMinutesByType(otherInterval.end, "end");

  const overlapStart = Math.max(start1, start2);
  const overlapEnd = Math.min(end1, end2);

  if (overlapStart < overlapEnd) {
    return {
      start: overlapStart,
      end: overlapEnd,
    };
  } else {
    // No overlap
    return null;
  }
}
export function adjustItemInterval(
  item: TimelineItemWithId,
  newStartMinutes: number,
  newEndMinutes: number,
  allItems: TimelineItemWithId[],
  minDuration: number = 30
): TimelineItemWithId {
  // Round times to nearest 30 minutes
  let newStartTime = minutesToTime(newStartMinutes);
  let newEndTime = minutesToTime(newEndMinutes);
  newStartTime = roundTimeTo30Minutes(newStartTime);
  newEndTime = roundTimeTo30Minutes(newEndTime);

  const updatedItem: TimelineItemWithId = {
    ...item,
    interval: {
      start: newStartTime,
      end: newEndTime,
    },
  };

  // Check for overlaps with other items on the same contentChannel
  const overlappingItems = allItems.filter(
    (otherItem) =>
      otherItem.id !== item.id &&
      intervalsOverlap(updatedItem.interval, otherItem.interval)
  );

  if (overlappingItems.length === 0) {
    // No overlaps, return updatedItem
    return updatedItem;
  } else {
    // Overlaps exist, adjust the item's interval
    const overlappingItem = overlappingItems[0]; // Handle one overlap for simplicity

    // Find overlapping interval
    const overlap = getOverlapInterval(
      updatedItem.interval,
      overlappingItem.interval
    );

    if (!overlap) {
      // No actual overlap detected (safety check)
      return updatedItem;
    }

    // Amount of time to adjust from start or end
    const timeToRemoveFromStart =
      overlap.end - timeToMinutesByType(updatedItem.interval.start, "start");
    const timeToRemoveFromEnd =
      timeToMinutesByType(updatedItem.interval.end, "end") - overlap.start;

    if (timeToRemoveFromStart <= timeToRemoveFromEnd) {
      // Adjust start time forward
      newStartMinutes = timeToMinutesByType(
        overlappingItem.interval.end,
        "end"
      );
      updatedItem.interval.start = minutesToTime(newStartMinutes);
    } else {
      // Adjust end time backward
      newEndMinutes = timeToMinutesByType(
        overlappingItem.interval.start,
        "start"
      );
      updatedItem.interval.end = minutesToTime(newEndMinutes);
    }

    // Ensure minimum duration
    const adjustedDuration =
      timeToMinutesByType(updatedItem.interval.end, "end") -
      timeToMinutesByType(updatedItem.interval.start, "start");

    if (adjustedDuration < minDuration) {
      // If the item becomes too short, revert to original times
      return item;
    }

    // Return adjusted item
    return updatedItem;
  }
}

export const percentWidthToPixels = (percent: number, scaleWidth: number) => {
  return (percent / 100) * scaleWidth;
};
