import { ContentCopy, SyncOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  MenuItem,
  Popover,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { SyntheticEvent, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Company,
  IOrganizationUnit,
  NendaProduct,
} from "../../../../../../types/NendaTypes";
import { capitalizeString } from "../../../../../utils/string";
import { getCompanies } from "../../../store/reducers/companyReducer";
import InputSelect from "../../../ui-components/input/InputSelect";
import TextInput from "../../../ui-components/input/TextInput";
import { ValidationErrors } from "./SetupCustomerDialog";

export function generatePassword() {
  let password: string;
  do {
    password = (Math.random() * 9999).toFixed(0).padStart(4, "0");
  } while (password[0] === "0");
  return password;
}

const GeneralForm = ({
  premiseName,
  password,
  company,
  premiseGroup,
  setPremiseName,
  setPassword,
  setCompany,
  setPremiseGroup,
  premiseGroups,
  validationErrors,
  products,
  setProducts,
  daypass,
  setDaypass,
  showDaypass,
  scopedCompany,
}: {
  premiseName: string;
  password: string;
  company: string;
  premiseGroup: string;
  setPremiseName: (name: string) => void;
  setPassword: (password: string) => void;
  setCompany: (company: string) => void;
  setPremiseGroup: (premiseGroup: string) => void;
  premiseGroups: IOrganizationUnit[];
  validationErrors: ValidationErrors;
  products: NendaProduct[];
  setProducts: (products: NendaProduct[]) => void;
  daypass: boolean;
  setDaypass: (daypass: boolean) => void;
  showDaypass: boolean;
  scopedCompany: Company;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const companies = useSelector(getCompanies);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPremiseName(event.target.value);
  };

  const handleSetPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const [notificationOpen, setNotificationOpen] = useState(false);
  const notificationRef = useRef(null);

  const handleCopyButtonClick = () => {
    setNotificationOpen(true);
    if (!password) return;
    navigator.clipboard.writeText(password);
    // Close the notification after 3 seconds
    setTimeout(() => {
      setNotificationOpen(false);
    }, 3000);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handlePremiseGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (event.target.value) {
      case "Signage":
        setProducts([NendaProduct.SIGNAGE]);
        break;
      case "Care":
        setProducts([NendaProduct.STREAMING_APP]);
        break;
      case "Hotels":
        setProducts([NendaProduct.STREAMING_APP]);
        break;
      default:
        break;
    }
    setPremiseGroup(event.target.value);
  };

  const handleCompanyChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setCompany(value);
  };

  const handleProductChange = (value: NendaProduct) => {
    if (products.includes(value)) {
      setProducts(products.filter((product) => product !== value));
    } else {
      setProducts([...products, value]);
    }
  };

  const handleDaypassChange = (value: boolean) => {
    setDaypass(value);
  };

  const handleGeneratePassword = () => {
    const newPass = generatePassword();
    setPassword(newPass);
  };

  return (
    <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={isMobile ? "column" : "row"}
        px={"0.5rem"}
      >
        <Typography variant="body1">
          {t("customerportal.pages.premises.create_modal.premise_name")}
        </Typography>
        <Stack direction={"row"} spacing={"0.8rem"} alignItems={"center"}>
          <TextInput
            variant="standard"
            title="Name"
            error={!!validationErrors.name}
            placeholder={
              t(
                "customerportal.pages.premises.create_modal.premise_name"
              ) as string
            }
            value={premiseName}
            onChange={handleNameChange}
          />
        </Stack>
      </Stack>
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={isMobile ? "column" : "row"}
        px={"0.5rem"}
      >
        <Typography variant="body1">
          {t("customerportal.pages.premises.create_modal.password")}
        </Typography>
        <Stack direction={"row"} spacing={"0.8rem"} alignItems={"center"}>
          <ContentCopy
            sx={{ width: "1.1rem", cursor: "pointer" }}
            onClick={handleCopyButtonClick}
            ref={notificationRef}
          />
          <SyncOutlined
            onClick={handleGeneratePassword}
            sx={{ width: "1.1rem", cursor: "pointer" }}
          />
          <Popover
            open={notificationOpen}
            onClose={handleNotificationClose}
            disableScrollLock={true}
            anchorEl={notificationRef.current}
            sx={(theme) => ({
              "& .MuiPopover-paper": {
                color: theme.palette.common.white + " !important",
              },
            })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {t("common.copied")}
          </Popover>
          <TextInput
            onChange={handleSetPassword}
            value={password}
            error={!!validationErrors.password}
            placeholder={
              t("customerportal.pages.premises.create_modal.password") as string
            }
            variant="standard"
            title="Password"
          />
        </Stack>
      </Stack>
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={isMobile ? "column" : "row"}
        px={"0.5rem"}
      >
        <Typography variant="body1">
          {t("customerportal.pages.premises.create_modal.company")}
        </Typography>
        <Stack direction={"row"} spacing={"0.8rem"} alignItems={"center"}>
          <Autocomplete
            freeSolo
            disabled={!!scopedCompany?._id}
            autoSelect={true}
            id="company-select"
            value={company || ""}
            sx={{
              minWidth: "11.5rem",
              display: "flex",
              "& .MuiTextField-root": {
                margin: 0,
              },
            }}
            options={companies.map((c) => c.name)}
            onChange={(
              _e: SyntheticEvent<Element, Event>,
              value: string | null
            ) => {
              setCompany(value || "");
            }}
            renderInput={(params) => (
              <TextInput
                {...params}
                error={!!validationErrors.company}
                placeholder={
                  t(
                    "customerportal.pages.premises.create_modal.company"
                  ) as string
                }
                variant="standard"
                onChange={handleCompanyChange}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={isMobile ? "column" : "row"}
        px={"0.5rem"}
      >
        <Typography variant="body1">
          {t("customerportal.pages.premises.create_modal.premise_group")}
        </Typography>
        <Stack direction={"row"} spacing={"0.8rem"} alignItems={"center"}>
          <InputSelect
            variant="standard"
            defaultValue={premiseGroup || "Care"}
            displayEmpty
            error={!!validationErrors.premiseGroup}
            id="premiseGroup-select"
            value={premiseGroup}
            sx={{
              minWidth: "11.5rem",
            }}
            onChange={handlePremiseGroupChange}
            renderValue={(value) => {
              const selectedGroup = premiseGroups.find((c) => c._id === value);
              if (!value)
                return (
                  <Typography
                    variant="body1"
                    sx={{ opacity: !value ? 0.5 : 1 }}
                  >
                    {t(
                      "customerportal.pages.premises.create_modal.premise_group"
                    )}
                  </Typography>
                );
              return selectedGroup?.name || "";
            }}
          >
            <MenuItem disabled value="">
              <strong>
                {t("customerportal.pages.premises.create_modal.premise_group")}
              </strong>
            </MenuItem>
            {premiseGroups.map((c) => (
              <MenuItem
                key={c._id}
                value={c._id}
                sx={{ textTransform: "capitalize" }}
              >
                {c.name}
              </MenuItem>
            ))}
          </InputSelect>
        </Stack>
      </Stack>
      {showDaypass && (
        <Stack
          justifyContent={"space-between"}
          flexDirection={"row"}
          alignItems={"center"}
          px={"0.5rem"}
        >
          <Typography variant="body1">
            {t("customerportal.pages.premises.create_modal.daypass")}
          </Typography>
          <Stack
            direction={"row"}
            spacing={"0.8rem"}
            alignSelf={"center"}
            my="1rem"
          >
            <Switch
              onChange={() => handleDaypassChange(!daypass)}
              checked={daypass}
            />
          </Stack>
        </Stack>
      )}
      <Stack
        justifyContent={"space-between"}
        flexDirection={"column"}
        px={"0.5rem"}
      >
        <Typography variant="body1">
          {t("customerportal.pages.premises.create_modal.products")}
        </Typography>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={"0.8rem"}
          alignSelf={isMobile ? "flex-end" : "center"}
          my="1rem"
        >
          {Object.values(NendaProduct).map((product) => (
            <FormControlLabel
              key={product}
              sx={{
                "&.MuiFormControl-root": {
                  margin: "0",
                },
                outline: validationErrors.products ? "1px solid red" : "none",
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.7rem",
                },
              }}
              label={capitalizeString(product).replace("_", " ")}
              labelPlacement="start"
              control={
                <Switch
                  onChange={() => handleProductChange(product)}
                  checked={products.includes(product)}
                />
              }
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default GeneralForm;
