import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { SyntheticEvent } from "react";

export type ToggleItem = {
  label: string;
  value: boolean;
  onChange: (e: SyntheticEvent<Element, Event>, checked: boolean) => void;
  disabled?: boolean;
  children?: ToggleItem[];
};

const DynamicToggleList = ({
  title,
  toggles,
}: {
  toggles: ToggleItem[];
  title?: string;
}) => {
  return (
    <Box sx={{ my: "0.5rem" }}>
      {title && <Typography variant="h6">{title}</Typography>}
      <Box>
        {toggles.map((toggle) => (
          <Box key={toggle.label}>
            <FormControlLabel
              checked={toggle.value || false}
              label={toggle.label}
              onChange={(e, checked) => toggle.onChange(e, checked)}
              control={<Switch />}
            />
            {toggle.children && (
              <Box sx={{ pl: "2rem" }}>
                {toggle.children && (
                  <DynamicToggleList toggles={toggle.children} />
                )}
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DynamicToggleList;
