import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { t } from "i18next";
import {
  IApplicationsPage,
  IFrontLayerOptions,
  IFrontlayerConfiguration,
} from "@nendaTypes/NendaTypes";
import Applications from "./Applications";

const ApplicationPageConfig = ({
  state,
  handleChange,
  frontLayerConfigOptions,
  upload,
}: {
  state: IFrontlayerConfiguration["applications"];
  handleChange: (mutatedState: IApplicationsPage) => void;
  frontLayerConfigOptions: IFrontLayerOptions;
  upload: (file: File, imageCategory: string) => void;
}) => {
  const applicationPageBackgroundUpload = (file: File) => {
    upload(file, "applicationPageBackground");
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t("customerportal.settings.premise_settings.frontlayer.applications")}
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          display: "flex",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            dipslay: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
          },
          gap: "2rem",
        })}
      >
        <Applications
          config={state}
          availableApps={frontLayerConfigOptions.availableDeviceApps}
          onConfigChange={handleChange}
          onUpload={applicationPageBackgroundUpload}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ApplicationPageConfig;
