import { PlayArrow, ZoomIn } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { memo, useMemo, useState } from "react";
import Lightbox, { Slide } from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import { AssetType } from "../../../../../types/NendaTypes";

type Props = {
  size?: "small" | "medium" | "large";
  width?: string;
  url: string;
  type: AssetType;
  thumbnail?: string;
};

const ClickableImagePreview = ({
  url,
  type,
  width = "100%",
  thumbnail,
}: Props) => {
  const theme = useTheme();
  const [openImage, setOpenImage] = useState(false);
  const toggleLightBox = () => setOpenImage(!openImage);
  const isVideo = type === AssetType.VIDEO;
  const previewUrl = isVideo ? url : thumbnail || url;
  const slides: Slide[] = useMemo(() => {
    return isVideo
      ? [
          {
            type: "video",
            width: 1280,
            height: 720,
            poster: url,
            sources: [
              {
                src: url,
                type: "video/mp4",
              },
            ],
          },
        ]
      : [
          {
            src: url,
            width: 1280,
            height: 720,
            type: "image",
          },
        ];
  }, [isVideo, url]);
  const render = useMemo(
    () => ({
      buttonPrev: () => null,
      buttonNext: () => null,
    }),
    []
  );
  if (!url) return null;
  return (
    <Box
      onClick={toggleLightBox}
      sx={{
        width,
        aspectRatio: "16/9",
        position: "relative",
        overflow: "hidden",
        display: "flex",
      }}
    >
      {url && type !== "video" ? (
        <img
          src={previewUrl}
          loading="lazy"
          style={{
            width: "100%",
            borderRadius: "0.2rem",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      ) : (
        <video
          src={previewUrl}
          style={{
            width: "100%",
            borderRadius: "0.2rem",
            objectFit: "contain",
            aspectRatio: "16/9",
          }}
        />
      )}
      {url && (
        <Lightbox
          plugins={type === "video" ? [Video] : []}
          video={{ autoPlay: true, playsInline: true }}
          open={openImage}
          close={toggleLightBox}
          render={render}
          slides={slides}
        />
      )}
      <Box
        onClick={toggleLightBox}
        sx={{
          position: "absolute",
          inset: 0,
          opacity: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "opacity 0.2s ease",
          background: "transparent",
          "&:hover": {
            opacity: 1,
            background: "rgba(0,0,0,0.5)",
          },
        }}
      >
        {type !== AssetType.VIDEO ? (
          <ZoomIn
            sx={{
              fontSize: "3rem",
              color: theme.palette.common.white,
            }}
          />
        ) : (
          <PlayArrow
            sx={{
              fontSize: "3rem",
              color: theme.palette.common.white,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(ClickableImagePreview);
