import { Check, OndemandVideo } from "@mui/icons-material";
import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  AssetType,
  IAsset,
  ILeanAsset,
} from "../../../../../../../types/NendaTypes";
import { useVideoDuration } from "../../../../../../hooks/useVideoFileInformation";

import { clipString } from "../../../../../../utils/string";
import { formatDurationInSeconds } from "../../../../../../utils/timeUtil";
import { CustomerPortalState } from "../../../../store";
import {
  selectCurrentNavigationLevel,
  selectNavigationScope,
} from "../../../../store/reducers/workspaceReducer";
import UploadAssetModal from "../../../AssetLibrary/UploadAssetModal";
import { PROMOTION_UPLOAD_REQUEST_ID } from "../../../Premise/Signage/Asset/CreateAssetForm";
import { CreatePromotionFormState } from "../CreateEditPromotionModal";

const LIMIT_SELECTION_TO_ONE_ASSET = true;
const DEFAULT_DURATION = 5;

const SelectAssets = ({
  state,
  setState,
  setActiveStep,
  assets,
  isValidStepClick,
}: {
  state: CreatePromotionFormState;
  setState: Dispatch<SetStateAction<CreatePromotionFormState>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  assets: IAsset[];
  isValidStepClick: (step: number) => boolean;
}) => {
  const assetState = state;
  const theme = useTheme();
  const scope = useSelector(selectNavigationScope);
  const resource = useSelector(selectCurrentNavigationLevel);
  const uploadedAssets = useSelector(
    (state: CustomerPortalState) => state.company.uploadedFiles
  );
  const latestUploadedAssetUrl = uploadedAssets[PROMOTION_UPLOAD_REQUEST_ID];
  const latestUploadedAsset = assets.find(
    (a) => a.locator === latestUploadedAssetUrl
  );
  const { duration } = useVideoDuration(latestUploadedAsset);

  const handleSelect = (id: string, duration?: number) => {
    if (LIMIT_SELECTION_TO_ONE_ASSET) {
      setState({
        ...assetState,
        media: [{ id, duration: duration || DEFAULT_DURATION }],
      });

      if (assetState.media.length <= 0 && isValidStepClick(1)) {
        setActiveStep(2);
      }
      return;
    }

    const selected = assetState.media.find((m) => m.id === id);
    if (selected) {
      const media = assetState.media.filter((m) => m.id !== id);
      setState({
        ...assetState,
        media,
      });
    } else {
      setState({
        ...assetState,
        media: [...assetState.media, { id, duration: undefined }],
      });
    }
  };

  useEffect(() => {
    if (
      latestUploadedAsset &&
      latestUploadedAsset.type === AssetType.VIDEO &&
      duration
    ) {
      setState({
        ...assetState,
        media: [
          {
            id: latestUploadedAsset._id,
            duration: duration,
          },
        ],
      });
    } else if (
      latestUploadedAsset &&
      latestUploadedAsset.type === AssetType.IMAGE
    ) {
      setState({
        ...assetState,
        media: [
          {
            id: latestUploadedAsset._id,
            duration: DEFAULT_DURATION,
          },
        ],
      });
    }
  }, [latestUploadedAsset, duration]);

  // Convert the different assets to the same format
  const items = useCallback(() => {
    type AssetItem = IAsset & { createdAt: Date };
    const assetItems: AssetItem[] = [...assets] as any;
    const sortedAssets = assetItems?.sort((a, b) => {
      const aCreatedAt = a.createdAt;
      const bCreatedAt = b.createdAt;

      return new Date(bCreatedAt).getTime() - new Date(aCreatedAt).getTime();
    });
    return sortedAssets.map((a) => {
      return {
        name: a?.name,
        _id: a?._id,
        locator: a?.thumbnailLocator || a?.locator,
        type: a?.type,
        mimeType: a?.mimeType,
      };
    }) as ILeanAsset[];
  }, [JSON.stringify(assets), resource]);

  return (
    <Box sx={{ overflowX: "hidden", width: "100%" }}>
      <Grid
        container
        columns={{ xs: 2, md: 5 }}
        sx={{
          padding: "0.5rem",
          height: "100%",
          minHeight: "10rem",
          maxHeight: "20rem",
          overflowY: "scroll",
          overflowX: "hidden",
          borderRadius: "0.3rem 0.3rem 0 0",
          width: "100%",
          mb: "1rem",
          background: theme.palette.grey[100],
          outline: `1px solid ${theme.palette.grey[200]}`,
          boxShadow: theme.shadows[24],

          "&::-webkit-scrollbar": {
            backgroundColor: "transparent",
            position: "absolute",
          },
          "&::-webkit-scrollbar-thumb": {
            background: theme.palette.transparent.blue,
            borderRadius: "0.5rem",
          },

          [theme.breakpoints.down("md")]: {
            padding: 0,
            minHeight: "initial",
            maxHeight: "30rem",
            overflowY: "initial",
          },
        }}
      >
        {items().map((item, i) => (
          <Grid item xs={1} key={item._id}>
            <AssetItem
              key={item._id + i}
              item={item}
              state={state}
              setState={setState}
              handleSelect={handleSelect}
              selectedItems={assetState.media.map((m) => m.id)}
            />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ py: "0.5rem" }}>
        <UploadAssetModal
          company={scope.company || ""}
          premise={scope.premise}
        />
      </Box>
    </Box>
  );
};

type AssetItemProps = {
  item: ILeanAsset;
  state: CreatePromotionFormState;
  setState: Dispatch<SetStateAction<CreatePromotionFormState>>;
  handleSelect: (id: string, duration?: number) => void;
  selectedItems?: string[];
  editEnabled?: boolean;
  size?: "small" | "large";
};
const AssetItem = ({
  item,
  handleSelect,
  editEnabled = false,
  state,
  setState,
}: AssetItemProps) => {
  const theme = useTheme();
  const { duration } = useVideoDuration(item);

  const onSelect = () => {
    if (item.type === AssetType.VIDEO && duration) {
      handleSelect(item._id, duration);
      return;
    } else if (item.type === AssetType.IMAGE && !duration) {
      handleSelect(item._id, DEFAULT_DURATION);
      return;
    }
    console.error("Invalid file type or duration");
  };

  const isSelected = state.media.some((m) => m.id === item._id);

  const mimeType = item.type;

  useEffect(() => {
    if (duration) {
      setState({
        ...state,
        media: state.media.map((m) => {
          if (m.id === item._id) {
            return {
              ...m,
              duration: duration.toFixed(0),
            };
          }
          return m;
        }),
      });
    }
  }, [duration, item._id]);

  return (
    <Box
      key={"assetItem" + item._id + item.name}
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "0.3rem",
        height: "12rem",
        position: "relative",
        gap: "0.5rem",
        cursor: "pointer",
        m: "0.5rem",
        outline:
          isSelected && !editEnabled
            ? `4px solid ${theme.palette.primary.light}`
            : "none",
        "&:hover": {
          background: !editEnabled ? theme.palette.grey[100] : "none",
        },
      }}
      onClick={onSelect}
    >
      {isSelected && (
        <Box
          sx={{
            background: theme.palette.transparent.darkBlue,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            inset: 0,
          }}
        >
          <Check sx={{ fill: "white" }} />
        </Box>
      )}
      <Box
        sx={{
          height: "8rem",
          width: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: theme.palette.transparent.blue,
        }}
      >
        {item.locator && item.type !== "video" ? (
          <img
            src={item.locator}
            alt={item.name}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "0.2rem",
              objectFit: "cover",
            }}
          />
        ) : (
          <OndemandVideo />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "25%",
          width: "100%",
          textOverflow: "clip",
          whiteSpace: "nowrap",
          flexDirection: "column",
          padding: "0.3rem",
        }}
      >
        <Tooltip title={item.name}>
          <Typography variant="caption">{clipString(item.name, 15)}</Typography>
        </Tooltip>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: theme.palette.grey[500],
          }}
        >
          <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
            {mimeType}
          </Typography>
          {duration && (
            <Typography
              variant="caption"
              fontSize={"0.7rem"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {duration && formatDurationInSeconds(duration)}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SelectAssets;
