import { Slider, Stack, Typography } from "@mui/material";
import { t } from "i18next";

const MAX = 24;
const MIN = 1;

const DurationSlider = ({
  selectedDuration,
  setSelectedDuration,
}: {
  selectedDuration: number;
  setSelectedDuration: (value: number) => void;
}) => {
  const handleChange = (_: Event, newValue: number | number[]) => {
    setSelectedDuration(newValue as number);
  };

  return (
    <Stack gap="4rem">
      <Stack direction={"row"} gap="1rem" alignItems={"center"}>
        <Typography variant="body1">{t("common.1h")}</Typography>
        <Slider
          step={1}
          value={selectedDuration}
          valueLabelFormat={
            selectedDuration + " " + (selectedDuration === 1 ? "hr" : "hrs")
          }
          valueLabelDisplay="on"
          min={MIN}
          max={MAX}
          onChange={handleChange}
        />
        <Typography variant="body1">{t("common.24h")}</Typography>
      </Stack>
    </Stack>
  );
};

export default DurationSlider;
