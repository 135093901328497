import { store } from "@client/components/CustomerPortal/store";
import { batchSetContentOverride } from "@client/components/CustomerPortal/store/reducers/screenReducer";
import DefaultDialog from "@client/components/CustomerPortal/ui-components/dialog/dialog";
import {
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  IContentOverride,
  IRoom,
  ScheduleContentRefType,
} from "@nendaTypes/NendaTypes";
import { addHours } from "date-fns";
import { useState } from "react";
import { FOREVER_END_DATE } from "../../Bookings/create/components/DateRangeSelect";
import DurationSlider from "./DurationSlider";
import PlayContentModalScreenSelect from "./SelectScreens";
import { t } from "i18next";
import { useSelector } from "react-redux";
import {
  selectOverrideContent,
  SetContentOverrideModal,
} from "@client/components/CustomerPortal/store/reducers/customizationReducer";

const PlayContentModal = ({ screens }: { screens: IRoom[] }) => {
  const selectedContent = useSelector(selectOverrideContent);
  const [step, setStep] = useState(1);
  const [duration, setDuration] = useState(1);
  const [selectedScreens, setSelectedScreens] = useState<string[]>([]);
  const dialogOpen = !!selectedContent.id && !!selectedContent.contentRefType;
  const [mode, setMode] = useState<ChannelTakeoverMode>(
    ChannelTakeoverMode.Duration
  );

  const contentRefName =
    selectedContent.contentRefType === ScheduleContentRefType.CONTENT_CHANNEL
      ? t("common.content_channel")
      : t("common.digital_signage_playlist");

  const onClose = () => {
    setSelectedScreens([]);
    setDuration(1);
    setStep(1);
    store.dispatch(SetContentOverrideModal());
  };

  const handleNext = () => {
    setStep(step + 1);
  };
  const handleBack = () => {
    setStep(step - 1);
  };

  const indefiniteDuration = mode === ChannelTakeoverMode.Forever;

  const saveChannelOverride = () => {
    if (!selectedContent.id || !selectedContent.contentRefType) {
      return;
    }
    const endDate = indefiniteDuration
      ? FOREVER_END_DATE
      : (addHours(new Date(), duration).toISOString() as any);
    const params: IContentOverride = {
      content: selectedContent.id,
      contentRefType: selectedContent.contentRefType,
      from: new Date().toISOString() as any,
      to: endDate,
    };
    store.dispatch(
      batchSetContentOverride(
        selectedScreens.map((co) => ({
          contentOverride: { ...params },
          screenId: co,
        }))
      )
    );
  };

  const handleSave = ({ isConfirmed }: { isConfirmed: boolean }) => {
    if (isConfirmed && selectedScreens.length) {
      saveChannelOverride();
    }
    store.dispatch(SetContentOverrideModal());
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <DefaultDialog
        open={dialogOpen}
        onClose={onClose}
        title={`Play - ${contentRefName}`}
        defaultSaveButton={false}
        customActions={[
          {
            label: "Back",
            disabled: step <= 1,
            onClick: handleBack,
            variant: "outlined",
            visible: step > 0,
          },
          {
            label: "Next",
            disabled: false,
            onClick: handleNext,
            variant: "contained",
            visible: step < 2,
          },
          {
            label: "Confirm",
            disabled: step !== 2 || selectedScreens.length <= 0,
            onClick: () => handleSave({ isConfirmed: true }),
            variant: "contained",
            visible: step === 2,
          },
        ]}
      >
        <>
          {step === 1 && (
            <>
              <Box sx={{ mb: "3rem", mt: "0.5rem" }}>
                <Box
                  onClick={() => setMode(ChannelTakeoverMode.Duration)}
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    borderRadius: "0.2rem",
                    cursor: "pointer",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      background:
                        mode === ChannelTakeoverMode.Duration
                          ? theme.palette.transparent.blue
                          : theme.palette.grey[100],
                    },
                    background:
                      mode === ChannelTakeoverMode.Duration
                        ? theme.palette.transparent.blue
                        : "transparent",
                    outline:
                      mode === ChannelTakeoverMode.Duration
                        ? `2px solid ${theme.palette.primary.light}`
                        : "none",
                  }}
                >
                  <Box
                    sx={{
                      width: isMobile ? "100%" : "30%",
                      p: "1rem",
                      alignItems: "center",
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    {mode === ChannelTakeoverMode.Duration ? (
                      <RadioButtonCheckedOutlined
                        sx={{ color: theme.palette.primary.light }}
                      />
                    ) : (
                      <RadioButtonUncheckedOutlined
                        sx={{ color: theme.palette.primary.light }}
                      />
                    )}
                    <Typography variant="h6" fontSize={"1rem"}>
                      {t(
                        "customerportal.pages.content_channels.temporarily_play_content"
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: isMobile ? "100%" : "70%",
                      p: "1rem",
                      pt: "3rem",
                    }}
                  >
                    <DurationSlider
                      selectedDuration={duration}
                      setSelectedDuration={setDuration}
                    />
                    <Typography variant="body1">
                      {t(
                        "customerportal.pages.content_channels.temporarily_play_description",
                        { contentType: contentRefName.toLowerCase() }
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  onClick={() => setMode(ChannelTakeoverMode.Forever)}
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    borderRadius: "0.2rem",
                    cursor: "pointer",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      background:
                        mode === ChannelTakeoverMode.Forever
                          ? theme.palette.transparent.blue
                          : theme.palette.grey[100],
                    },
                    background:
                      mode === ChannelTakeoverMode.Forever
                        ? theme.palette.transparent.blue
                        : "transparent",
                    outline:
                      mode === ChannelTakeoverMode.Forever
                        ? `2px solid ${theme.palette.primary.light}`
                        : "none",
                  }}
                >
                  <Box
                    sx={{
                      width: isMobile ? "100%" : "30%",
                      p: "1rem",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      pt: "2rem",
                      pb: "2rem",
                    }}
                  >
                    {mode === ChannelTakeoverMode.Forever ? (
                      <RadioButtonCheckedOutlined
                        sx={{ color: theme.palette.primary.light }}
                      />
                    ) : (
                      <RadioButtonUncheckedOutlined
                        sx={{ color: theme.palette.primary.light }}
                      />
                    )}
                    <Typography variant="h6" fontSize={"1rem"}>
                      {t(
                        "customerportal.pages.content_channels.play_content_indefinitely"
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: isMobile ? "100%" : "70%",
                      p: "1rem",
                    }}
                  >
                    <Typography variant="body1">
                      {t(
                        "customerportal.pages.content_channels.play_content_indefinitely_description",
                        { contentType: contentRefName.toLowerCase() }
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {step === 2 && (
            <PlayContentModalScreenSelect
              selectedScreens={selectedScreens}
              setSelectedScreens={setSelectedScreens}
              screens={screens}
            />
          )}
        </>
      </DefaultDialog>
    </>
  );
};

enum ChannelTakeoverMode {
  Duration = "duration",
  Forever = "forever",
}

export default PlayContentModal;
