import { Epic, ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, mergeMap } from "rxjs/operators";
import { SetError } from "./errorReducer";
import { CustomerPortalState } from "..";
import { companyService } from "../../../../http/companyService";
import {
  AssetSource,
  Company,
  ITag,
  LoadingStatus,
} from "../../../../../types/NendaTypes";
import { SetNotification } from "./notificationReducer";
import {
  CreateCompanyAsset,
  CreatePremiseAsset,
  UpdateCompanyAsset,
  UpdatePremiseAsset,
} from "./assetReducer";
import {
  IListResponse,
  ICompanyListRequest,
} from "../../../../../types/RequestTypes";
import { FileUploadResponse } from "../../../../../server/services/fileService";

export interface CompanyState {
  companies: any[];
  paginatedCompanies: IListResponse<Company>;
  status: {
    createCompany: LoadingStatus;
    updateCompany: LoadingStatus;
    deleteCompany: LoadingStatus;
    getCompanies: LoadingStatus;
    getCompany: LoadingStatus;
    assetUpload: Record<string, Status>;
  };
  uploadedFiles: Record<string, string>;
}

export enum COMPANY_ACTION {
  GET_COMPANY = "GET_COMPANY",
  GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE",
  GET_COMPANIES = "GET_COMPANIES",
  GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE",
  CREATE_COMPANY = "CREATE_COMPANY",
  CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS",
  CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE",
  UPDATE_COMPANY = "UPDATE_COMPANY",
  UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS",
  UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE",
  DELETE_COMPANY = "DELETE_COMPANY",
  DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS",
  DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE",
  UPLOAD_ASSET_FILE = "UPLOAD_ASSET_FILE",
  UPLOAD_ASSET_FILE_SUCCESS = "UPLOAD_ASSET_FILE_SUCCESS",
  UPLOADED_ASSET_CLEAR = "UPLOADED_ASSET_CLEAR",
  UPLOAD_ASSET_FILE_FAILURE = "UPLOAD_ASSET_FILE_FAILURE",
  SET_COMPANY = "SET_COMPANY",
}

type Status = "idle" | "loading" | "succeeded" | "failed";

export const DEFAULT_REQUEST_ID = "default-request";

export interface CompanyAction {
  type: COMPANY_ACTION;
  payload?: any;
}

export const initialCompanyState: CompanyState = {
  companies: [],
  paginatedCompanies: {
    data: [],
    filteredCount: 0,
    totalCount: 0,
    page: 1,
    pageSize: 2,
  },
  status: {
    createCompany: LoadingStatus.IDLE,
    updateCompany: LoadingStatus.IDLE,
    deleteCompany: LoadingStatus.IDLE,
    getCompanies: LoadingStatus.IDLE,
    getCompany: LoadingStatus.IDLE,
    assetUpload: {},
  },
  uploadedFiles: {},
};

export function GetCompany(companyId: string): CompanyAction {
  return { type: COMPANY_ACTION.GET_COMPANY, payload: companyId };
}

function GetCompanySuccess(response: Company): CompanyAction {
  return {
    type: COMPANY_ACTION.GET_COMPANY_SUCCESS,
    payload: response,
  };
}

function GetCompanyFailure(error: any): CompanyAction {
  return {
    type: COMPANY_ACTION.GET_COMPANY_FAILURE,
    payload: error.response ? error.response.message : error.message,
  };
}

export function GetCompanies(payload?: ICompanyListRequest): CompanyAction {
  return { type: COMPANY_ACTION.GET_COMPANIES, payload };
}

function GetCompaniesSuccess(response: Company[]): CompanyAction {
  return {
    type: COMPANY_ACTION.GET_COMPANIES_SUCCESS,
    payload: response,
  };
}

function GetCompaniesFailure(error: any): CompanyAction {
  return {
    type: COMPANY_ACTION.GET_COMPANIES_FAILURE,
    payload: error.response ? error.response.message : error.message,
  };
}

export function CreateCompany(company: Company): CompanyAction {
  return {
    type: COMPANY_ACTION.CREATE_COMPANY,
    payload: {
      company,
    },
  };
}

export function CreateCompanySuccess(response: Company): CompanyAction {
  return {
    type: COMPANY_ACTION.CREATE_COMPANY_SUCCESS,
    payload: response,
  };
}

export function SetCompany(company: Company): CompanyAction {
  return {
    type: COMPANY_ACTION.SET_COMPANY,
    payload: company,
  };
}

function CreateCompanyFailure(error: any): CompanyAction {
  return {
    type: COMPANY_ACTION.CREATE_COMPANY_FAILURE,
    payload: error.response ? error.response.message : error.message,
  };
}

export function UpdateCompany(
  companyId: string,
  company: Company
): CompanyAction {
  return {
    type: COMPANY_ACTION.UPDATE_COMPANY,
    payload: {
      companyId,
      company,
    },
  };
}

function UpdateCompanySuccess(response: Company): CompanyAction {
  return {
    type: COMPANY_ACTION.UPDATE_COMPANY_SUCCESS,
    payload: response,
  };
}

function UpdateCompanyFailure(error: any): CompanyAction {
  return {
    type: COMPANY_ACTION.UPDATE_COMPANY_FAILURE,
    payload: error.response ? error.response.message : error.message,
  };
}

export function DeleteCompany(companyId: string): CompanyAction {
  return {
    type: COMPANY_ACTION.DELETE_COMPANY,
    payload: {
      companyId,
    },
  };
}

function DeleteCompanySuccess(response: Company): CompanyAction {
  return {
    type: COMPANY_ACTION.DELETE_COMPANY_SUCCESS,
    payload: response,
  };
}

function DeleteCompanyFailure(error: any): CompanyAction {
  return {
    type: COMPANY_ACTION.DELETE_COMPANY_FAILURE,
    payload: error.response ? error.response.message : error.message,
  };
}

export function ClearUploadedFiles(requestId: string): CompanyAction {
  return {
    type: COMPANY_ACTION.UPLOADED_ASSET_CLEAR,
    payload: {
      requestId,
    },
  };
}

interface IUploadFileAndCreateAsset {
  companyId: string;
  image: any;
  scope: string;
  assetType: string;
  assetName: string;
  premiseId?: string;
  requestId: string;
}

export function UploadFileAndCreateAsset({
  companyId,
  image,
  scope,
  assetType,
  assetName,
  premiseId,
  requestId,
}: IUploadFileAndCreateAsset) {
  return {
    type: COMPANY_ACTION.UPLOAD_ASSET_FILE,
    payload: {
      companyId,
      premiseId,
      image,
      scope,
      assetType,
      assetName,
      createAsset: true,
      requestId,
    },
  };
}

export function UploadFile(
  companyId: string,
  image: any,
  scope: string,
  assetType: string,
  requestId = DEFAULT_REQUEST_ID
): CompanyAction {
  return {
    type: COMPANY_ACTION.UPLOAD_ASSET_FILE,
    payload: {
      companyId,
      image,
      scope,
      assetType,
      createAsset: false,
      requestId,
    },
  };
}

function UploadFileSuccess(response: any, requestId: string): CompanyAction {
  return {
    type: COMPANY_ACTION.UPLOAD_ASSET_FILE_SUCCESS,
    payload: { response, requestId },
  };
}

function UploadFileFailure(error: any, requestId: string): CompanyAction {
  return {
    type: COMPANY_ACTION.UPLOAD_ASSET_FILE_FAILURE,
    payload: { error, requestId },
  };
}

// selectors
export const getCompanies = (state: CustomerPortalState): any[] => {
  return state.company.companies;
};

export const getPaginatedCompanies = (
  state: CustomerPortalState
): IListResponse<Company> => {
  return state.company.paginatedCompanies;
};

export const getCompany = (
  state: CustomerPortalState,
  companyId: string
): Company => {
  return state.company.companies.find((x) => x._id === companyId);
};

export const getCompanyTags = (
  state: CustomerPortalState,
  companyId: string
) => {
  const tags = state.company.companies.find((x) => x._id === companyId)
    ?.tags as ITag[];
  return [...new Set(tags)];
};

export const selectAdvertisers = (state: CustomerPortalState) => {
  return state.company.companies.filter((x) => x.type === "advertiser");
};

export const selectGetCompaniesStatus = (
  state: CustomerPortalState
): Status | undefined => state.company.status.getCompanies;

export const getAssetUploadStatus = (
  state: CustomerPortalState,
  requestId = DEFAULT_REQUEST_ID
): Status => {
  return state.company.status.assetUpload[requestId];
};

export const getUploadedFile = (
  state: CustomerPortalState,
  requestId = DEFAULT_REQUEST_ID
): string => {
  return state.company.uploadedFiles[requestId];
};

export default function companyReducer(
  state: CompanyState = initialCompanyState,
  action: CompanyAction
): CompanyState {
  switch (action.type) {
    case COMPANY_ACTION.GET_COMPANY:
      return {
        ...state,
        status: { ...state.status, getCompany: LoadingStatus.LOADING },
      };
    case COMPANY_ACTION.GET_COMPANY_SUCCESS: {
      const companyToUpdate = state.companies.find(
        (c) => c._id === action.payload._id
      );

      if (companyToUpdate) {
        return {
          ...state,
          companies: state.companies.map((c) =>
            c._id === action.payload._id ? action.payload : c
          ),
          status: {
            ...state.status,
            getCompany: LoadingStatus.SUCCEEDED,
          },
        };
      } else {
        return {
          ...state,
          companies: [...state.companies, action.payload],
        };
      }
    }
    case COMPANY_ACTION.GET_COMPANIES:
      return {
        ...state,
        status: { ...state.status, getCompanies: LoadingStatus.LOADING },
      };
    case COMPANY_ACTION.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload.data,
        paginatedCompanies: {
          ...action.payload,
          data: action.payload.data,
        },
        status: { ...state.status, getCompanies: LoadingStatus.SUCCEEDED },
      };
    case COMPANY_ACTION.CREATE_COMPANY:
      return {
        ...state,
        status: { ...state.status, createCompany: LoadingStatus.LOADING },
      };
    case COMPANY_ACTION.CREATE_COMPANY_SUCCESS: {
      return {
        ...state,
        status: { ...state.status, createCompany: LoadingStatus.SUCCEEDED },
        companies: [...state.companies, action.payload],
        paginatedCompanies: {
          ...state.paginatedCompanies,
          data: [...state.paginatedCompanies.data, action.payload],
        },
      };
    }
    case COMPANY_ACTION.SET_COMPANY: {
      return {
        ...state,
        companies: [...state.companies, action.payload],
        paginatedCompanies: {
          ...state.paginatedCompanies,
          data: [...state.paginatedCompanies.data, action.payload],
        },
      };
    }
    case COMPANY_ACTION.UPDATE_COMPANY:
      return {
        ...state,
        status: { ...state.status, updateCompany: LoadingStatus.LOADING },
      };
    case COMPANY_ACTION.UPDATE_COMPANY_SUCCESS: {
      const newState = { ...state };

      newState.companies = newState.companies.map((c) => {
        if (c._id == action.payload._id) return action.payload;
        else return c;
      });
      return {
        ...newState,
        status: { ...state.status, updateCompany: LoadingStatus.SUCCEEDED },
      };
    }
    case COMPANY_ACTION.DELETE_COMPANY:
      return {
        ...state,
        status: { ...state.status, deleteCompany: LoadingStatus.LOADING },
      };
    case COMPANY_ACTION.DELETE_COMPANY_SUCCESS: {
      const newState = { ...state };
      newState.companies = newState.companies.filter(
        (c) => c._id != action.payload._id
      );
      return {
        ...newState,
        status: { ...state.status, deleteCompany: LoadingStatus.SUCCEEDED },
      };
    }
    case COMPANY_ACTION.UPLOAD_ASSET_FILE: {
      const updatedStatus = { ...state.status.assetUpload };
      updatedStatus[action.payload.requestId || DEFAULT_REQUEST_ID] =
        LoadingStatus.LOADING;

      return {
        ...state,
        status: {
          ...state.status,
          assetUpload: updatedStatus,
        },
      };
    }
    case COMPANY_ACTION.UPLOAD_ASSET_FILE_SUCCESS: {
      const requestId = action.payload.requestId || DEFAULT_REQUEST_ID;
      const updatedStatus = { ...state.status.assetUpload };
      updatedStatus[requestId] = LoadingStatus.SUCCEEDED;

      const updatedUploadedFiles = { ...state.uploadedFiles };
      updatedUploadedFiles[requestId] = action.payload.response.fileUrl;

      return {
        ...state,
        status: {
          ...state.status,
          assetUpload: updatedStatus,
        },
        uploadedFiles: updatedUploadedFiles,
      };
    }
    case COMPANY_ACTION.UPLOAD_ASSET_FILE_FAILURE: {
      const updatedStatus = { ...state.status.assetUpload };
      updatedStatus[action.payload.requestId || DEFAULT_REQUEST_ID] = "failed";
      return {
        ...state,
        status: {
          ...state.status,
          assetUpload: updatedStatus,
        },
      };
    }
    case COMPANY_ACTION.UPLOADED_ASSET_CLEAR: {
      const requestId = action.payload.requestId;
      return {
        ...state,
        uploadedFiles: {
          ...state.uploadedFiles,
          [requestId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}

// Epics
const getCompany$: Epic = (action$) => {
  return action$.pipe(
    ofType(COMPANY_ACTION.GET_COMPANY),
    switchMap((a: CompanyAction) => {
      return companyService.getCompany(a.payload).pipe(
        mergeMap((response) => {
          return [GetCompanySuccess(response)];
        }),
        catchError((err) => of(GetCompanyFailure(err)))
      );
    })
  );
};

const getCompanyFailed$: Epic = (action$) => {
  return action$.pipe(
    ofType(COMPANY_ACTION.GET_COMPANY_FAILURE),
    map((a) => SetError(a.payload))
  );
};

const getCompanies$: Epic = (action$) => {
  return action$.pipe(
    ofType(COMPANY_ACTION.GET_COMPANIES),
    switchMap((a) => {
      return companyService.getCompanies(a.payload).pipe(
        mergeMap((response) => {
          return [GetCompaniesSuccess(response)];
        }),
        catchError((err) => of(GetCompaniesFailure(err)))
      );
    })
  );
};

const getCompaniesFailed$: Epic = (action$) => {
  return action$.pipe(
    ofType(COMPANY_ACTION.GET_COMPANIES_FAILURE),
    map((a) => SetError(a.payload))
  );
};

const createCompany$ = (action$: Observable<CompanyAction>) =>
  action$.pipe(
    ofType(COMPANY_ACTION.CREATE_COMPANY),
    switchMap((action) =>
      companyService.createCompany(action.payload.company).pipe(
        mergeMap((createdCompany) => [
          CreateCompanySuccess(createdCompany),
          SetNotification("Company created!"),
        ]),
        catchError((error) => of(CreateCompanyFailure(error)))
      )
    )
  );

const createCompanyFailed$ = (action$: Observable<CompanyAction>) =>
  action$.pipe(
    ofType(COMPANY_ACTION.UPDATE_COMPANY_FAILURE),
    map((action) => SetError(action.payload))
  );

const updateCompany$ = (action$: Observable<CompanyAction>) =>
  action$.pipe(
    ofType(COMPANY_ACTION.UPDATE_COMPANY),
    switchMap((action) =>
      companyService
        .updateCompany(action.payload.companyId, action.payload.company)
        .pipe(
          mergeMap((updatedCompany) => [
            UpdateCompanySuccess(updatedCompany),
            SetNotification("Company updated!"),
          ]),
          catchError((error) => of(UpdateCompanyFailure(error)))
        )
    )
  );

const updateCompanyFailed$ = (action$: Observable<CompanyAction>) =>
  action$.pipe(
    ofType(COMPANY_ACTION.UPDATE_COMPANY_FAILURE),
    map((action) => SetError(action.payload))
  );

const deleteCompany$ = (action$: Observable<CompanyAction>) =>
  action$.pipe(
    ofType(COMPANY_ACTION.DELETE_COMPANY),
    switchMap((action) =>
      companyService.deleteCompany(action.payload.companyId).pipe(
        mergeMap((deletedCompany) => [
          DeleteCompanySuccess(deletedCompany),
          SetNotification("Company deleted!"),
        ]),
        catchError((error) => of(DeleteCompanyFailure(error)))
      )
    )
  );

const deleteCompanyFailed$ = (action$: Observable<CompanyAction>) =>
  action$.pipe(
    ofType(COMPANY_ACTION.DELETE_COMPANY_FAILURE),
    map((action) => SetError(action.payload))
  );

const uploadFile$: Epic = (action$) => {
  return action$.pipe(
    ofType(COMPANY_ACTION.UPLOAD_ASSET_FILE),
    switchMap((a: CompanyAction) => {
      return companyService
        .uploadMediaFile(
          a.payload.companyId,
          a.payload.image,
          a.payload.scope,
          a.payload.assetType
        )
        .pipe(
          mergeMap((response: FileUploadResponse) => {
            if (a.payload.createAsset) {
              return [
                a.payload.premiseId
                  ? CreatePremiseAsset(a.payload.premiseId, {
                      locator: response.fileUrl,
                      thumbnailLocator: response.thumbnailUrl,
                      name: a.payload.assetName || a.payload.image.name,
                      source: AssetSource.NENDA,
                      type: a.payload.assetType,
                      company: a.payload.companyId,
                      product: a.payload.scope,
                      fileSize: response.fileSize,
                      mimeType: response.mimeType,
                    })
                  : CreateCompanyAsset(a.payload.companyId, {
                      locator: response.fileUrl,
                      thumbnailLocator: response.thumbnailUrl,
                      name: a.payload.assetName || a.payload.image.name,
                      source: AssetSource.NENDA,
                      type: a.payload.assetType,
                      company: a.payload.companyId,
                      product: a.payload.scope,
                      fileSize: response.fileSize,
                      mimeType: response.mimeType,
                    }),
                UploadFileSuccess(response, a.payload.requestId),
              ];
            } else if (a.payload.updateAsset) {
              return [
                a.payload.premiseId
                  ? UpdatePremiseAsset({
                      premiseId: a.payload.premiseId,
                      assetId: a.payload.assetId,
                      product: a.payload.scope,
                      asset: {
                        locator: response.fileUrl,
                        name: a.payload.assetName || a.payload.image.name,
                        source: AssetSource.NENDA,
                        type: a.payload.assetType,
                      },
                    })
                  : UpdateCompanyAsset({
                      companyId: a.payload.companyId,
                      assetId: a.payload.assetId,
                      product: a.payload.scope,
                      asset: {
                        locator: response.fileUrl,
                        name: a.payload.assetName || a.payload.image.name,
                        source: AssetSource.NENDA,
                        type: a.payload.assetType,
                      },
                    }),
                UploadFileSuccess(response, a.payload.requestId),
              ];
            }
            return [
              UploadFileSuccess(response, a.payload.requestId),
              SetNotification("File uploaded!"),
            ];
          }),
          catchError((err) => of(UploadFileFailure(err, a.payload.requestId)))
        );
    })
  );
};

const uploadFileFailed$: Epic = (action$) => {
  return action$.pipe(
    ofType(COMPANY_ACTION.UPLOAD_ASSET_FILE_FAILURE),
    map(() => SetError("Failed to upload file"))
  );
};

export const companyEpics = [
  getCompany$,
  getCompanyFailed$,
  getCompanies$,
  getCompaniesFailed$,
  createCompany$,
  createCompanyFailed$,
  updateCompany$,
  updateCompanyFailed$,
  deleteCompany$,
  deleteCompanyFailed$,
  uploadFile$,
  uploadFileFailed$,
];
