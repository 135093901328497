import { TextField, TextFieldProps, TextFieldVariants } from "@mui/material";

const TextInput = ({ variant = "outlined", ...props }) => {
  const propsWithDefaultValues: TextFieldProps = {
    ...props,
    InputProps: {
      disableUnderline: true,
      ...props.InputProps,
      disabled: props.disabled,
    },
  };

  if (variant === "outlined") {
    if (propsWithDefaultValues.InputProps) {
      // outlined variant does not support disableUnderline
      delete propsWithDefaultValues.InputProps.disableUnderline;
    }
  }

  return (
    <TextField
      variant={variant as TextFieldVariants}
      {...propsWithDefaultValues}
      sx={{
        my: 2,
        ...props.sx,
      }}
    />
  );
};

export type InputError = {
  field: string;
  message: string;
};

export default TextInput;
