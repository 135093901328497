import React from "react";
import { useSelector } from "react-redux";
import { CreatePremiseButton } from "../../components/CreatePremiseButton/CreatePremiseButton";
import { CreatePremiseGroupButton } from "../../components/CreatePremiseGroupButton/CreatePremiseGroupButton";
import { Grid } from "../../components/Grid/Grid";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { SectionWrapper } from "../../components/UI/SectionWrapper/SectionWrapper";
import { AppState } from "../../types/redux";
import { Link } from "react-router-dom";
import { organizationUnitService } from "../../http/organizationUnit.service";
import { BaseConfigEditor } from "../../components/OrganizationUnit/BaseConfigEditor";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export const OrganizationUnitsOverview: React.FC = () => {
  const organizationUnits = useSelector(
    (state: AppState) => state.organizationUnits?.organizationUnits
  );

  const [customerBaseConfig, setCustomerBaseConfig] = React.useState("");

  const saveConfig = async () => {
    try {
      await organizationUnitService
        .updateCustomerBaseConfig(customerBaseConfig)
        .toPromise();
      organizationUnitService
        .getCustomerBaseConfig()
        .toPromise()
        .then((config) =>
          setCustomerBaseConfig(JSON.stringify(config, null, 2))
        );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <PageHeader>
        <h2>Premises overview</h2>
        <CreatePremiseGroupButton />
        <CreatePremiseButton />
        <Link to={`/organizationunits/report-all`}>
          <Button variant="outlined" color="secondary">
            Report all
          </Button>
        </Link>
      </PageHeader>
      <SectionWrapper>
        <BaseConfigEditor
          configContent={customerBaseConfig}
          handleChange={setCustomerBaseConfig}
          handleSave={saveConfig}
        />
      </SectionWrapper>
      <SectionWrapper>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            Premise Groups (
            {organizationUnits?.filter((o) => o.type === "hotelChain").length})
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              items={organizationUnits
                .filter((o) => o.type === "hotelChain")
                .map((hotelChain) => ({
                  name: hotelChain.name,
                  id: hotelChain._id,
                  link: `/organizationunits/hotelchain/${hotelChain._id}`,
                }))}
            />
          </AccordionDetails>
        </Accordion>
      </SectionWrapper>
      <SectionWrapper>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            Premises (
            {organizationUnits?.filter((o) => o.type === "hotel").length})
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              items={organizationUnits
                .filter((o) => o.type === "hotel")
                .map((hotel) => ({
                  name: hotel.name,
                  id: hotel._id,
                  link: `/organizationunits/hotelchain/${hotel.parent}/hotel/${hotel._id}`,
                }))}
            />
          </AccordionDetails>
        </Accordion>
      </SectionWrapper>
    </>
  );
};
