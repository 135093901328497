import React, { useEffect } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import "./AutocompleteTags.scss";

interface IAutocompleteTagsComponentProps {
  onSelect(newValue: string): void;
  tags: Array<any>;
  carouselTagIds: Array<string>;
  clearSearchTerm: boolean;
}

export const AutocompleteTagsComponent: React.FC<
  IAutocompleteTagsComponentProps
> = ({ onSelect, tags, carouselTagIds, clearSearchTerm }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  useEffect(() => {
    setSearchTerm("");
  }, [clearSearchTerm]);

  function onInputChange(e) {
    setSearchTerm(e.target.value);
  }

  if (!tags || tags.length === 0) return null;

  return (
    <>
      <div className="input-group">
        <input className="form-control" type="text" onChange={onInputChange} />
      </div>
      <ul className="list-group">
        {tags
          .filter((tag) => {
            if (searchTerm === "") return false;
            return (
              tag.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              tag.tagType?.toLowerCase().includes(searchTerm.toLowerCase())
            );
          })
          .map((tag) => (
            <li
              className="list-group-item d-flex justify-content-between align-items-center"
              key={tag.value}
            >
              {tag.tagType}: {tag.name} ({tag.value})
              <button
                className={`btn btn-secondary float-right ${
                  carouselTagIds.includes(tag.tagId) ? "btn-success" : ""
                }`}
                onClick={() => {
                  onSelect(tag.value);
                  setSearchTerm("");
                }}
              >
                {!carouselTagIds.includes(tag.tagId) ? <FaPlus /> : <FaCheck />}
              </button>
            </li>
          ))}
      </ul>
    </>
  );
};
