import { ArrowBack, Create, LibraryAdd } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import {
  IContentChannel,
  IDailyContentPlaylist,
  ISignagePlaylist,
} from "../../../../../types/NendaTypes";
import { selectPlaylists } from "../../store/reducers/dailyContentPlaylistReducer";
import { selectNavigatedPremiseId } from "../../store/reducers/workspaceReducer";
import CreateEditPlaylist from "../DailyPlaylist/CreateEditPlaylist";
import ExistingPlaylistList from "./components/ExistingPlaylistList";

const AssignPlaylistModalContent = ({
  onSetPlaylist,
  playlist,
  setPlaylist,
  selectedPlaylistId,
  signagePlaylists,
  contentChannels,
}: {
  onSetPlaylist: (id: string) => void;
  playlist: IDailyContentPlaylist;
  setPlaylist: (value: any) => void;
  selectedPlaylistId: string | null;
  signagePlaylists: ISignagePlaylist[];
  contentChannels: IContentChannel[];
}) => {
  const premise = useSelector(selectNavigatedPremiseId);
  const playlists = useSelector(selectPlaylists);

  if (premise !== playlist.premise) {
    setPlaylist((prev) => ({ ...prev, premise: premise }));
  }

  const [pickMode, setPickMode] = useState<
    "pick-existing" | "create_new" | null
  >(null);

  const handlePickMode = (mode: "pick-existing" | "create_new" | null) => {
    setPickMode(mode);
  };

  const handleGoBack = () => {
    setPickMode(null);
  };

  if (pickMode === "pick-existing") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          cursor: "pointer",
        }}
      >
        <ArrowBackButton onClick={handleGoBack} />
        <Typography variant="h6">
          {t("customerportal.pages.schedule.pick_existing_playlist")}
        </Typography>
        <ExistingPlaylistList
          playlists={playlists}
          onPlaylistSelect={onSetPlaylist}
          selectedPlaylistId={selectedPlaylistId}
        />
      </Box>
    );
  }

  if (pickMode === "create_new") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <ArrowBackButton onClick={handleGoBack} />
        <CreateEditPlaylist
          playlistState={playlist}
          setPlaylistState={setPlaylist}
          contentChannels={contentChannels}
          signagePlaylists={signagePlaylists}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "10rem",
        height: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box>
        <Typography variant="h6">
          {t("customerportal.pages.schedule.assign_playlist")}
        </Typography>
      </Box>
      <Grid container columns={{ xs: 1, md: 2 }} spacing={2}>
        <Grid item xs={1}>
          <PickTypeButton
            title={"Choose an already existing playlist"}
            icon={<LibraryAdd />}
            onClick={() => handlePickMode("pick-existing")}
          />
        </Grid>
        <Grid item xs={1}>
          <PickTypeButton
            title={"Create new playlist"}
            icon={<Create />}
            onClick={() => handlePickMode("create_new")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ArrowBackButton = ({ onClick }) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
      onClick={() => onClick(null)}
    >
      <ArrowBack sx={{ height: "1rem" }} />
      Back
    </Box>
  );
};

const PickTypeButton = ({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon: ReactNode;
  onClick: () => void;
}) => {
  return (
    <Box
      onClick={onClick}
      sx={(theme) => ({
        border: `0.5px solid ${theme.palette.grey[300]}`,
        borderRadius: "8px",
        display: "flex",
        cursor: "pointer",
        flexDirection: "column",
        height: "100%",
        minHeight: "10rem",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        gap: "1rem",
        p: "1rem 2rem",
        "&:hover": {
          background: theme.palette.transparent.blue,
        },
      })}
    >
      {icon}
      <Typography variant="body2" textAlign={"center"}>
        {title}
      </Typography>
    </Box>
  );
};

export default AssignPlaylistModalContent;
