import { ArrowBack } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(-1)}
      variant="text"
      color="primary"
      startIcon={<ArrowBack />}
    >
      <Typography variant="body2" sx={{ pt: 0.2 }}>
        {t("common.back")}
      </Typography>
    </Button>
  );
};

export default BackButton;
