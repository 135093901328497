import {
  IDailyContentPlaylist,
  ScheduleContentRefType,
} from "@nendaTypes/NendaTypes";
import { SetStateAction } from "react";
import PlaylistTimeline from "../../DailyPlaylist/PlaylistTimeline";
import ChannelRangeSelect from "../../DailyPlaylist/ChannelRangeSelect";
import SmallContentChannelCarousel from "../SmallContentChannelCarousel";
import { playlistToContentTimelineIntervals } from "@client/utils/timeline";
import { t } from "i18next";
import { Box } from "@mui/material";

const MobilePlaylistScheduler = ({
  playlistState,
  setPlaylistState,
  handleAddContent,
}: {
  playlistState: IDailyContentPlaylist;
  setPlaylistState: (value: SetStateAction<IDailyContentPlaylist>) => void;
  handleAddContent: (
    contentId: string,
    contentRefType: ScheduleContentRefType
  ) => void;
}) => {
  return (
    <Box>
      {playlistState && (
        <PlaylistTimeline
          playlist={playlistToContentTimelineIntervals(playlistState)}
          height="4rem"
        />
      )}
      <ChannelRangeSelect
        timeline={playlistState.timeline}
        handleChange={setPlaylistState}
      />
      <SmallContentChannelCarousel
        handleAddContent={handleAddContent}
        channelSelectTitle={t(
          "customerportal.pages.daily_playlists.add_channel"
        )}
      />
    </Box>
  );
};

export default MobilePlaylistScheduler;
