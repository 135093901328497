import { Box, useTheme } from "@mui/material";
import DraggableTimelineItem from "./DraggableTimelineItem";
import { useDroppable } from "@dnd-kit/core";
import {
  IContentChannel,
  ISignagePlaylist,
  ITimeLineItem,
} from "@nendaTypes/NendaTypes";
import { TimelineItemWithId } from "./DragAndDropPlaylist";
import { mergeRefs } from "@client/utils/refs";

const DroppableTimelineContainer = ({
  timeline,
  contentChannels,
  onTimelineChange,
  containerWidth,
  containerRef,
  signagePlaylists,
}: {
  timeline: TimelineItemWithId[];
  contentChannels: IContentChannel[];
  onTimelineChange: (timeline: ITimeLineItem[]) => void;
  containerWidth: number;
  containerRef: React.RefObject<HTMLDivElement>;
  signagePlaylists: ISignagePlaylist[];
}) => {
  const theme = useTheme();
  const { setNodeRef: dropNodeRef } = useDroppable({
    id: "timeline-droppable",
  });

  return (
    <Box
      ref={mergeRefs(dropNodeRef, containerRef)}
      sx={{
        width: "100%",
        height: "8rem",
        background: theme.palette.transparent.blue,
        position: "relative",
      }}
    >
      {containerWidth > 0 &&
        timeline.map((item) => {
          return (
            <DraggableTimelineItem
              key={item.id}
              item={item}
              containerWidth={containerWidth}
              allItems={timeline}
              contentChannels={contentChannels}
              signagePlaylists={signagePlaylists}
              onItemChange={onTimelineChange}
            />
          );
        })}
    </Box>
  );
};

export default DroppableTimelineContainer;
