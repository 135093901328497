import { Box, Typography } from "@mui/material";
import {
  Company,
  IOrganizationUnit,
  IRoom,
} from "../../../../../../../types/NendaTypes";
import { getInheritedSignageSlotValue } from "../../screenCard/EditScreen";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { CustomerPortalState } from "../../../../store";
import { selectDefaultContentForScreen } from "@client/components/CustomerPortal/store/reducers/screenReducer";

const ScreenListItemInfo = ({
  screen,
  company,
  premise,
}: {
  screen: IRoom;
  company?: Company;
  premise?: IOrganizationUnit;
}) => {
  const defaultContent = useSelector((state: CustomerPortalState) =>
    selectDefaultContentForScreen(state, screen._id)
  );
  const promotionsEnabled =
    premise?.signageConfig?.arePromotionsEnabled &&
    screen.signage.config?.arePromotionsEnabled;
  return (
    <Box sx={{ mt: "-0.5rem" }}>
      {promotionsEnabled && (
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: "0.5rem",
          }}
        >
          <Typography
            variant="caption"
            sx={(theme) => ({
              fontSize: "0.6rem",
              textTransform: "uppercase",
              color: theme.palette.grey[600],
            })}
          >
            {t("customerportal.pages.settings.screens.signage_slots_per_hour") +
              ": "}
          </Typography>
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontSize: "0.6rem",
              textTransform: "uppercase",
              color: theme.palette.grey[800],
            })}
          >
            {screen?.signage.config?.numberOfHourlySignageSlots !== undefined
              ? screen?.signage.config?.numberOfHourlySignageSlots
              : getInheritedSignageSlotValue({
                  premise,
                  company,
                })}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          gap: "0.5rem",
        }}
      >
        <Typography
          variant="caption"
          sx={(theme) => ({
            fontSize: "0.6rem",
            textTransform: "uppercase",
            color: theme.palette.grey[600],
          })}
        >
          {t("customerportal.pages.settings.screens.default_content") + ": "}
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({
            fontSize: "0.6rem",
            textTransform: "uppercase",
            color: theme.palette.grey[800],
          })}
        >
          {defaultContent?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default ScreenListItemInfo;
