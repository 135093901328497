import { push } from "react-router-redux";
import { map } from "rxjs/operators";
import {
  ContentOrder,
  IAsset,
  IDailyContentPlaylist,
  IFrontlayerConfiguration,
  IOrganizationUnit,
  IRoom,
  IRoomType,
  ISignagePlaylist,
  ISignagePromotion,
  NendaProduct,
} from "../../types/NendaTypes";
import {
  IAssetListRequest,
  IOrganizationUnitListRequest,
  IRoomListRequest,
} from "../../types/RequestTypes";
import { CreateRoomSetsProps } from "../components/CustomerPortal/store/reducers/customerSetupReducer";
import { fromJson } from "../models/modelHelpers";
import { OrganizationUnit } from "../models/organizationUnit";
import { store } from "../redux/store";
import { Service, httpClient } from "./client";

export interface UpdateAssetProps {
  premiseId?: string;
  companyId?: string;
  assetId: string;
  product: NendaProduct;
  asset: Partial<IAsset>;
}

export interface CreateRoomSetProps {
  roomSets: CreateRoomSetsProps[];
  hotelId: string;
  hotelChainId: string;
}
class OrganizationUnitService extends Service {
  getOrganizationUnits() {
    return httpClient.get("/organizationunits", this.authHeader());
  }

  getPremises(listRequest?: IOrganizationUnitListRequest) {
    const query = httpClient.listRequestToQuery(listRequest);

    return httpClient.get(
      "/organizationunits/premises" + query,
      this.authHeader()
    );
  }

  /**
   * @deprecated
   * getOrganizationUnitsAsClassInstances is deprecated. Use getOrganizationUnits instead.
   * This is beacuse the OrganizationUnit model is stored in the redux store as a class instance
   * and not as a plain object which is not compatible with the default middlewares of redux-toolkit
   * and the best practice of only storing serializable data in state.
   **/
  getOrganizationUnitsAsClassInstances() {
    return httpClient.get("/organizationunits", this.authHeader()).pipe(
      map((json) => {
        return json.map((j: any) => fromJson(OrganizationUnit, j));
      })
    );
  }
  createOrganizationUnit(
    data: Partial<IOrganizationUnit>,
    notifyEmailAddress?: string
  ) {
    const payload = {
      carousels: [],
      ...data,
    };
    return httpClient
      .post(
        `/organizationunits${
          notifyEmailAddress ? `/?notify=${notifyEmailAddress}` : ""
        }`,
        payload,
        this.authHeader()
      )
      .pipe(
        map((response) => {
          switch (data.type) {
            case "hotelChain":
              store.dispatch(
                push(`/organizationunits/hotelchain/${response._id}`)
              );
              break;
            case "hotel":
              store.dispatch(
                push(
                  `/organizationunits/hotelchain/${data.parent}/hotel/${response._id}`
                )
              );
              break;
            default:
              break;
          }
          return response;
        })
      );
  }
  addCarouselToOrganizationUnit(
    organizationUnitId: string,
    carouselId: string
  ) {
    return httpClient.put(
      `/organizationunits/${organizationUnitId}/carousels/${carouselId}`,
      {},
      this.authHeader()
    );
  }
  removeCarouselfromOrganizationUnit(
    organizationUnitId: string,
    carouselId: string
  ) {
    return httpClient.delete(
      `/organizationunits/${organizationUnitId}/carousels/${carouselId}`,
      this.authHeader()
    );
  }
  updateOrganizationUnit(
    organizationUnitId: string,
    payload: Partial<IOrganizationUnit>
  ) {
    return httpClient.put(
      `/organizationunits/${organizationUnitId}`,
      payload,
      this.authHeader()
    );
  }
  updateOrganizationUnits(
    organizationUnitIds: Array<string>,
    payload: Partial<IOrganizationUnit>
  ) {
    const data = {
      ids: organizationUnitIds,
      payload: payload,
    };
    return httpClient.put(
      `/organizationunits/updatemany`,
      data,
      this.authHeader()
    );
  }
  createRooms(payload: any) {
    return httpClient.post(
      "/organizationunits/rooms/bulk",
      payload,
      this.authHeader()
    );
  }
  createRoomRanges(payload: CreateRoomSetProps) {
    return httpClient.post(
      "/organizationunits/rooms/bulk/roomSets",
      payload,
      this.authHeader()
    );
  }
  createRoom(payload: Partial<IRoom>) {
    return httpClient.post(
      "/organizationunits/rooms",
      payload,
      this.authHeader()
    );
  }
  deleteOrganizationUnit(id: string) {
    return httpClient
      .delete(`/organizationunits/${id}`, this.authHeader())
      .pipe(
        map((ou) => {
          store.dispatch(
            push(
              `/organizationunits/hotelchain/${ou.hotelChain}/hotel/${ou.hotel}`
            )
          );
          return ou;
        })
      );
  }
  getRoomsForHotel(id: string, listRequest?: IRoomListRequest) {
    const listRequestQuery = httpClient.listRequestToQuery(listRequest);
    return httpClient.get(
      `/organizationunits/hotel/${id}/rooms${listRequestQuery}`,
      this.authHeader()
    );
  }
  getRoomsForHotels(ids: string[]) {
    return httpClient.get(
      `/organizationunits/rooms?ids=${ids.join("&ids=")}`,
      this.authHeader()
    );
  }
  updateRoom(id: string, payload: Partial<IRoom>) {
    return httpClient.put(
      `/organizationunits/rooms/${id}`,
      payload,
      this.authHeader()
    );
  }
  deleteRoom(id: string) {
    return httpClient
      .delete(`/organizationunits/rooms/${id}`, this.authHeader())
      .pipe(
        map((room) => {
          store.dispatch(
            push(
              `/organizationunits/hotelchain/${room.hotelChain}/hotel/${room.hotel}`
            )
          );
          return room;
        })
      );
  }
  createRoomType(organizationUnitId: string, payload: Partial<IRoomType>) {
    return httpClient.post(
      `/organizationunits/${organizationUnitId}/roomTypes`,
      payload,
      this.authHeader()
    );
  }
  updateRoomType(
    organizationUnitId: string,
    roomTypeId: string,
    payload: Partial<IRoomType>
  ) {
    return httpClient.patch(
      `/organizationunits/${organizationUnitId}/roomTypes/${roomTypeId}`,
      payload,
      this.authHeader()
    );
  }
  deleteRoomType(organizationUnitId: string, roomTypeId: string) {
    return httpClient
      .delete(
        `/organizationunits/${organizationUnitId}/roomTypes/${roomTypeId}`,
        this.authHeader()
      )
      .pipe(
        map((res) => {
          store.dispatch(
            push(`/organizationunits/hotelchain/${organizationUnitId}`)
          );
          return res;
        })
      );
  }
  resyncRoomType(organizationUnitId, roomTypeId) {
    return httpClient.post(
      `/organizationunits/${organizationUnitId}/roomTypes/${roomTypeId}/resync`,
      {},
      this.authHeader()
    );
  }

  updateRoomTypeAllRooms(organizationUnitId: string, roomTypeId: string) {
    return httpClient.post(
      `/organizationunits/hotel/${organizationUnitId}/rooms/roomtype`,
      { roomTypeId: roomTypeId },
      this.authHeader()
    );
  }

  uploadImage(
    organizationUnitId: string,
    image: Blob,
    scope: string,
    assetType: string
  ) {
    const formData = new FormData();
    formData.append("scope", scope);
    formData.append("assetType", assetType);
    formData.append("file", image);
    return httpClient.put(
      `/organizationunits/${organizationUnitId}/file`,
      formData,
      this.authHeader(),
      false
    );
  }

  convertPdfToImage(organizationUnitId: string, image: Blob, scope: string) {
    const formData = new FormData();
    formData.append("scope", scope);
    formData.append("file", image);
    return httpClient.post(
      `/organizationunits/${organizationUnitId}/file/convert-pdf`,
      formData,
      this.authHeader(),
      false
    );
  }

  getAssets(premiseId: string, listRequest?: IAssetListRequest) {
    const query = httpClient.listRequestToQuery(listRequest);

    return httpClient.get(
      `/organizationunits/${premiseId}/asset` + query,
      this.authHeader()
    );
  }

  createAsset(premiseId: string, asset: Partial<IAsset>) {
    return httpClient.post(
      `/organizationunits/${premiseId}/asset`,
      { ...asset },
      this.authHeader()
    );
  }

  updateAsset({ premiseId, assetId, product, asset }: UpdateAssetProps) {
    return httpClient.patch(
      `/organizationunits/${premiseId}/asset/${product}/${assetId}`,
      { asset },
      this.authHeader()
    );
  }

  deleteAsset(premiseId: string, product: NendaProduct, assetId: string) {
    return httpClient.delete(
      `/organizationunits/${premiseId}/asset/${product}/${assetId}`,
      this.authHeader()
    );
  }

  getCustomerBaseConfig() {
    return httpClient.get(`/organizationunits/config`, this.authHeader());
  }

  updateCustomerBaseConfig(configValue) {
    return httpClient.put(
      `/organizationunits/config`,
      configValue,
      this.authHeader()
    );
  }

  getBusinessUnitBaseConfig(organizationUnitId: string) {
    return httpClient.get(
      `/organizationunits/${organizationUnitId}/config`,
      this.authHeader()
    );
  }

  updateBusinessUnitBaseConfig(
    organizationUnitId: string,
    configValue: object
  ) {
    return httpClient.put(
      `/organizationunits/${organizationUnitId}/config`,
      configValue,
      this.authHeader()
    );
  }

  getFrontlayerConfig(premiseId: string) {
    return httpClient.get(
      `/organizationunits/${premiseId}/frontlayerConfig`,
      this.authHeader()
    );
  }
  updateFrontlayerConfig(
    premiseId: string,
    frontlayerConfigId: string,
    data: Partial<IFrontlayerConfiguration>,
    unsetFields?: string[]
  ) {
    return httpClient.patch(
      `/organizationunits/${premiseId}/frontlayerConfig/${frontlayerConfigId}`,
      { data, unsetFields: unsetFields || [] },
      this.authHeader()
    );
  }
  createFrontlayerConfig(premiseId: string, data: IFrontlayerConfiguration) {
    return httpClient.post(
      `/organizationunits/${premiseId}/frontlayerConfig`,
      data,
      this.authHeader()
    );
  }

  getDailyContentPlaylists(organizationUnitId: string) {
    return httpClient.get(
      `/organizationunits/${organizationUnitId}/dailycontentplaylist`,
      this.authHeader()
    );
  }

  createDailyContentPlaylist(payload: Partial<IDailyContentPlaylist>) {
    return httpClient.post(
      `/organizationunits/${payload.premise}/dailycontentplaylist`,
      payload,
      this.authHeader()
    );
  }

  updateDailyContentPlaylist(payload: IDailyContentPlaylist) {
    return httpClient.patch(
      `/organizationunits/${payload.premise}/dailycontentplaylist/${payload._id}`,
      payload,
      this.authHeader()
    );
  }
  deleteDailyContentPlaylist(organizationUnitId: string, playlistId: string) {
    return httpClient.delete(
      `/organizationunits/${organizationUnitId}/dailycontentplaylist/${playlistId}`,
      this.authHeader()
    );
  }
  getPromotions(organizationUnitId: string) {
    return httpClient.get(
      `/organizationunits/${organizationUnitId}/signagepromotion`,
      this.authHeader()
    );
  }
  getPromotion(organizationUnitId: string, promotionId: string) {
    return httpClient.get(
      `/organizationunits/${organizationUnitId}/signagepromotion/${promotionId}`,
      this.authHeader()
    );
  }
  createPromotion(
    organizationUnitId: string,
    promotion: ISignagePromotion,
    applyToScreens: string[]
  ) {
    return httpClient.post(
      `/organizationunits/${organizationUnitId}/signagepromotion`,
      { promotion, applyToScreens },
      this.authHeader()
    );
  }
  updatePromotion(
    organizationUnitId: string,
    promotionId: string,
    promotionData: Partial<ISignagePromotion>,
    applyToScreens: string[]
  ) {
    return httpClient.patch(
      `/organizationunits/${organizationUnitId}/signagepromotion/${promotionId}`,
      { promotionData, applyToScreens },
      this.authHeader()
    );
  }
  deletePromotion(organizationUnitId: string, promotionId: string) {
    return httpClient.delete(
      `/organizationunits/${organizationUnitId}/signagepromotion/${promotionId}`,
      this.authHeader()
    );
  }

  getContentByPremiseId(premiseId: string) {
    return httpClient.get(
      `/organizationunits/${premiseId}/contentPackages`,
      this.authHeader()
    );
  }

  createContentOrder(premiseId: string, data: ContentOrder) {
    return httpClient.post(
      `/organizationunits/${premiseId}/contentOrder`,
      data,
      this.authHeader()
    );
  }
  getSignagePlaylists(premiseId: string) {
    return httpClient.get(
      `/organizationunits/${premiseId}/signageplaylist`,
      this.authHeader()
    );
  }
  updateSignagePlaylist(playlist: ISignagePlaylist) {
    return httpClient.patch(
      `/organizationunits/${playlist.premise}/signageplaylist/${playlist._id}`,
      playlist,
      this.authHeader()
    );
  }
  createSignagePlaylist(premiseId: string, playlist: ISignagePlaylist) {
    return httpClient.post(
      `/organizationunits/${premiseId}/signageplaylist`,
      playlist,
      this.authHeader()
    );
  }
  deleteSignagePlaylist(premiseId: string, playlistId: string) {
    return httpClient.delete(
      `/organizationunits/${premiseId}/signageplaylist/${playlistId}`,
      this.authHeader()
    );
  }
}

export const organizationUnitService = new OrganizationUnitService();
