import { IAdPermission } from "@nendaTypes/NendaTypes";

export const derivedAdPermissions = (
  defaultPermissions: IAdPermission,
  overridePermissions: IAdPermission
): IAdPermission => {
  // Start with a copy of defaultPermissions as  base
  const derived: IAdPermission = JSON.parse(JSON.stringify(defaultPermissions));

  if (!overridePermissions) {
    return derived;
  }
  const validCategories = ["external", "signage"];
  const validPermissions = ["fullTakeoverAllowed", "bannerAllowed"];

  for (const category of validCategories) {
    for (const permission of validPermissions) {
      const defaultValue = defaultPermissions[category]?.[permission];
      const overrideValue = overridePermissions[category]?.[permission];

      if (defaultValue === undefined || overrideValue === undefined) {
        continue;
      }

      // Override only true to false, never false to true
      derived[category][permission] = defaultValue && overrideValue;
    }
  }

  return derived;
};
