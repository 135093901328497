import PropTypes from "prop-types";

// material-ui
import { Divider, List, Typography, useTheme } from "@mui/material";

// project imports
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

import { useSelector } from "react-redux";
import {
  NavigationLevel,
  NendaProduct,
  ResourcePermissions,
  Role,
} from "../../../../../../../types/NendaTypes";
import PermissionsGate from "../../../../components/PermissionGate";
import { checkPermission } from "../../../../components/helpers/permissions";
import { CustomerPortalState } from "../../../../store";
import {
  getPremises,
  selectPremiseById,
} from "../../../../store/reducers/organizationUnitReducer";
import {
  selectCurrentNavigationLevel,
  selectNavigatedPremiseId,
} from "../../../../store/reducers/workspaceReducer";
import { MenuItem } from "../../../menuItems";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

type NavGroupProps = {
  item: MenuItem;
};

export const validateRules = (
  item: MenuItem,
  products: NendaProduct[],
  level: NavigationLevel,
  role: Role | undefined,
  permissions: ResourcePermissions
) => {
  const hasPermissions = item.restriction
    ? checkPermission(role, item.restriction, permissions)
    : true;

  const hasAvailableProduct =
    !item.products ||
    item.products.length === 0 ||
    item.products?.some((product) => products.includes(product));

  const isCorrectLevel =
    !item.level || item.level.length === 0 || item.level?.includes(level);

  return hasAvailableProduct && isCorrectLevel && hasPermissions;
};

const NavGroup = ({ item }: NavGroupProps) => {
  const premises = useSelector(getPremises);
  const premiseId = useSelector(selectNavigatedPremiseId);
  const scopeLevel = useSelector(selectCurrentNavigationLevel);
  const premise = useSelector((state: CustomerPortalState) =>
    selectPremiseById(state, premiseId || "")
  );

  const products =
    scopeLevel === NavigationLevel.PREMISE
      ? premise?.nendaProducts
      : [...new Set(premises.flatMap((premise) => premise.nendaProducts))];

  const theme = useTheme();
  // menu list collapse & items
  const items = item.children?.map((menu) => {
    let menuItem;
    switch (menu.type) {
      case "collapse":
        menuItem = (
          <NavCollapse
            key={menu.id}
            menu={menu}
            level={1}
            products={products || []}
            navigationLevel={scopeLevel}
          />
        );
        break;
      case "item":
        menuItem = (
          <NavItem
            key={menu.id}
            item={menu}
            level={1}
            products={products || []}
            navigationLevel={scopeLevel}
          />
        );
        break;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
    return menu.restriction ? (
      <PermissionsGate key={menu.id} restriction={menu.restriction}>
        {menuItem}
      </PermissionsGate>
    ) : (
      menuItem
    );
  });

  return (
    <>
      <List
        sx={{
          "& .MuiCollapse-root": {
            display: "flex",
            backgroundColor: theme.palette.background.default,
            padding: "0.8rem 0.5rem 0 0.5rem",
            borderRadius: "0 0 0.5rem 0.5rem",
            margin: "-1rem 0.1rem 0.5rem 0.1rem",
            "& .MuiList-root::after": {
              display: "none",
            },
          },
        }}
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
