// project imports
import { isMobile } from "react-device-detect";
import {
  CustomizationActions,
  CUSTOMOMIZATION_ACTIONS,
  MenuOpenAction,
  SetMenuAction,
  SetSettingsAction,
  ToggleMenuAction,
} from "../../../../types/redux";
import { Action } from "redux";
import { ScheduleContentRefType } from "@nendaTypes/NendaTypes";
import { CustomerPortalState } from "..";

// action - state management
export enum CalendarRange {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}
export type CalendarMode =
  | CalendarRange.DAY
  | CalendarRange.WEEK
  | CalendarRange.MONTH;

export interface CustomizationState {
  settingsIsOpen: boolean;
  isOpen: Array<string>;
  opened: boolean;
  promotions: {
    promotionModalIsOpen: boolean;
    editPromotionId: string | undefined;
  };
  contentOverride: {
    overrideId: string | undefined;
    contentRefType: ScheduleContentRefType | undefined;
  };
}

export const initialState: CustomizationState = {
  settingsIsOpen: false,
  isOpen: [], // for active default menu
  opened: true,
  promotions: {
    promotionModalIsOpen: false,
    editPromotionId: "",
  },
  contentOverride: {
    overrideId: "",
    contentRefType: undefined,
  },
};

export function SetEditPromotionId(id: string) {
  return {
    type: CUSTOMOMIZATION_ACTIONS.SET_EDIT_PROMOTION_ID,
    bookingId: id,
  };
}

export function SetContentOverrideModal(
  id?: string,
  refType?: ScheduleContentRefType
) {
  return {
    type: CUSTOMOMIZATION_ACTIONS.TOGGLE_CONTENT_OVERRIDE_MODAL,
    overrideId: id,
    refType,
  };
}

export function UnSetEditPromotionId() {
  return {
    type: CUSTOMOMIZATION_ACTIONS.SET_EDIT_PROMOTION_ID,
  };
}
export function OpenSettings(): SetSettingsAction {
  return { type: CUSTOMOMIZATION_ACTIONS.SETTINGS_TOGGLE, opened: true };
}

export function CloseSettings(): SetSettingsAction {
  return { type: CUSTOMOMIZATION_ACTIONS.SETTINGS_TOGGLE, opened: false };
}

export function TogglePromotionModal(): Action<CUSTOMOMIZATION_ACTIONS.TOGGLE_PROMOTION_MODAL> {
  return { type: CUSTOMOMIZATION_ACTIONS.TOGGLE_PROMOTION_MODAL };
}

export function OpenMenu(): SetMenuAction {
  return { type: CUSTOMOMIZATION_ACTIONS.SET_MENU, opened: true };
}

export function CloseMenu(): SetMenuAction {
  return { type: CUSTOMOMIZATION_ACTIONS.SET_MENU, opened: false };
}
export function ToggleMenu(): ToggleMenuAction {
  return {
    type: CUSTOMOMIZATION_ACTIONS.MENU_TOGGLE,
  };
}

export function RegisterOpenItem(id: string): MenuOpenAction {
  return { type: CUSTOMOMIZATION_ACTIONS.MENU_OPEN, id };
}

//Selector

export const selectOverrideContent = (state: CustomerPortalState) => {
  return {
    id: state.customization.contentOverride?.overrideId,
    contentRefType: state.customization.contentOverride?.contentRefType,
  };
};

const customizationReducer = (
  state = initialState,
  action: CustomizationActions
): CustomizationState => {
  let id;
  switch (action.type) {
    case CUSTOMOMIZATION_ACTIONS.TOGGLE_CONTENT_OVERRIDE_MODAL:
      return {
        ...state,
        contentOverride: {
          ...state.contentOverride,
          contentRefType: action.refType || undefined,
          overrideId: action.overrideId || undefined,
        },
      };
    case CUSTOMOMIZATION_ACTIONS.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
        opened: isMobile ? false : state.opened,
      };
    case CUSTOMOMIZATION_ACTIONS.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case CUSTOMOMIZATION_ACTIONS.MENU_TOGGLE:
      return {
        ...state,
        opened: !state.opened,
      };
    case CUSTOMOMIZATION_ACTIONS.SETTINGS_TOGGLE:
      return {
        ...state,
        settingsIsOpen: action.opened,
      };
    case CUSTOMOMIZATION_ACTIONS.TOGGLE_PROMOTION_MODAL:
      return {
        ...state,
        promotions: {
          ...state.promotions,
          promotionModalIsOpen: !state.promotions.promotionModalIsOpen,
          editPromotionId: state.promotions.promotionModalIsOpen
            ? ""
            : state.promotions.editPromotionId,
        },
      };
    case CUSTOMOMIZATION_ACTIONS.SET_EDIT_PROMOTION_ID:
      return {
        ...state,
        promotions: {
          ...state.promotions,
          editPromotionId: action.bookingId || undefined,
        },
      };
    default:
      return state;
  }
};

export default customizationReducer;
