import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { sv } from "date-fns/locale";
import { t } from "i18next";
import { Dispatch, SetStateAction, useCallback } from "react";
import { AssetType, IAsset } from "../../../../../../../types/NendaTypes";
import TextInput from "../../../../ui-components/input/TextInput";
import {
  CreatePromotionFormState,
  MediaBooking,
} from "../CreateEditPromotionModal";
import DateRangeSelect from "./DateRangeSelect";
import DaySelect from "./DaySelect";
import PrioritySelect from "./PrioritySelect";
import { ScheduleRule } from "./StepperForm";
import TimeRangeSelect from "./TimeRangeSelect";
import ClickableImagePreview from "@client/components/CustomerPortal/ui-components/media/ClickableImagePreview";

export type CreatePromotionStateProps = {
  state: CreatePromotionFormState;
  setState: Dispatch<SetStateAction<CreatePromotionFormState>>;
};

type SelectRuleFormProps = {
  appliedRules: ScheduleRule[];
  assets: IAsset[];
  validationRules: {
    maxDuration: number;
    minDuration: number;
  };
  onToggleRuleApplied: (rule: ScheduleRule, isApplied: boolean) => void;
} & CreatePromotionStateProps;

const SelectRulesForm: React.FC<SelectRuleFormProps> = ({
  state,
  appliedRules,
  setState,
  onToggleRuleApplied,
  assets,
  validationRules,
}) => {
  const handlePriorityChange = (checked: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isHighPriority: checked,
    }));
  };

  const selectedAsset = assets.find((a) => a._id == state?.media[0]?.id);

  const handleSetDuration = (e) => {
    setState({
      ...state,
      media: state.media.map((m) => {
        if (m.id === selectedAsset?._id) {
          return {
            ...m,
            duration: Number(Number(e.target.value).toFixed(0)),
          };
        }
        return m;
      }),
    });
  };
  const theme = useTheme();

  const totalDuration = useCallback(() => {
    if (!state.media.length) return 0;
    const total = (state.media as MediaBooking[]).reduce(
      (acc, asset) => acc + (asset.duration || 0),
      0
    );
    return total;
  }, [state.media]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
          <Typography sx={{ py: "0.5rem" }} variant="h6" fontSize={"1rem"}>
            {t("common.file.media_duration")}
          </Typography>
          <Grid container columns={{ xs: 1, md: 2 }} sx={{ width: "100%" }}>
            <Grid item xs={1} md={2}>
              <Box
                sx={{
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <AssetDurationSelect
                  setDuration={handleSetDuration}
                  currentDuration={state.media[0]?.duration || 0}
                  disabled={selectedAsset?.type === "video" ? true : false}
                  asset={selectedAsset}
                  validationRules={validationRules}
                  totalDuration={totalDuration}
                />
              </Box>
            </Grid>
          </Grid>
          <Typography sx={{ py: "0.5rem" }} variant="h6" fontSize={"1rem"}>
            {t("customerportal.pages.promotions.date_and_time_range")}
          </Typography>
          <Grid container columns={{ xs: 1, md: 2 }}>
            <Grid item xs={1}>
              <Box
                sx={{
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  display: "flex",
                  height: "100%",
                }}
              >
                <DateRangeSelect
                  state={state}
                  setState={setState}
                  onToggleRuleApplied={onToggleRuleApplied}
                  isCustomSelection={appliedRules.includes("date")}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={{
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  display: "flex",
                  height: "100%",
                }}
              >
                <TimeRangeSelect
                  state={state}
                  setState={setState}
                  onToggleRuleApplied={onToggleRuleApplied}
                  isCustomSelection={appliedRules.includes("time")}
                />
              </Box>
            </Grid>
          </Grid>
          <Typography sx={{ py: "0.5rem" }} variant="h6" fontSize={"1rem"}>
            {t("customerportal.pages.promotions.days_of_week")}
          </Typography>
          <Grid container columns={{ xs: 1, md: 2 }}>
            <Grid item xs={1}>
              <Box
                sx={{
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  display: "flex",
                  height: "100%",
                }}
              >
                <DaySelect state={state} setState={setState} />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={{
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  display: "flex",
                  height: "100%",
                }}
              >
                <PrioritySelect
                  isSelected={!!state.isHighPriority}
                  onChange={handlePriorityChange}
                />
              </Box>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

const AssetDurationSelect = ({
  setDuration,
  currentDuration,
  disabled,
  asset,
  validationRules,
  totalDuration,
}: {
  setDuration: (value: number) => void;
  currentDuration: number;
  disabled?: boolean;
  asset?: IAsset;
  totalDuration: () => number;
  validationRules: {
    maxDuration: number;
    minDuration: number;
  };
}) => {
  const { maxDuration, minDuration } = validationRules;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        py: "1rem",
      }}
    >
      <Grid container columns={{ xs: 1, md: 2 }} sx={{ width: "100%" }}>
        <Grid item xs={2}>
          <Box sx={{ pr: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "2rem",
                py: isMobile ? "1rem" : "0",
              }}
            >
              <ClickableImagePreview
                url={asset?.locator || ""}
                type={asset?.type || AssetType.IMAGE}
                thumbnail={asset?.thumbnailLocator}
                width="30%"
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.grey[700] }}
                >
                  {t("common.file.duration_in_seconds")}
                </Typography>
                <TextInput
                  disabled={disabled}
                  variant={"standard"}
                  id="duration"
                  type="number"
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      maxWidth: "8ch",
                      transform: "translateY(-1rem)",
                      "& input": {
                        padding: "0.2rem 0.5rem !important",
                      },
                    },
                  }}
                  value={Number(currentDuration)?.toFixed(0)}
                  autoComplete="off"
                  onChange={setDuration}
                />
              </Box>
            </Box>
            <Box>
              <Typography variant="body1">
                {!asset
                  ? t("form.validation.promotions.number_of_assets.zero")
                  : totalDuration() < minDuration
                  ? t(
                      "form.validation.promotions.number_of_assets.min_duration",
                      { duration: minDuration }
                    )
                  : totalDuration() > maxDuration
                  ? t(
                      "form.validation.promotions.number_of_assets.max_duration",
                      { duration: maxDuration }
                    )
                  : ""}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectRulesForm;
