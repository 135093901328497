import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Resource, Scope } from "../../../../../types/NendaTypes";
import AddEditChannel from "../../components/Content/AddEditChannel";
import ChannelList from "../../components/Content/ChannelList";
import PermissionsGate from "../../components/PermissionGate";
import { CustomerPortalState, store } from "../../store";
import {
  AddContentChannel,
  DeleteContentChannel,
  GetContentChannels,
  selectContentChannels,
} from "../../store/reducers/contentChannelReducer";

const ContentChannels = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    store.dispatch(GetContentChannels());
  }, []);

  const channels = useSelector((state: CustomerPortalState) => {
    return selectContentChannels(state);
  });

  const handleAddChannel = (channel) => {
    store.dispatch(AddContentChannel(channel));
  };

  const handleDeleteChannel = (channelId) => {
    store.dispatch(DeleteContentChannel(channelId));
  };

  const sortedChannels = [...channels].sort(
    (a, b) => a.sortOrder - b.sortOrder
  );

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.Ads,
        scopes: [Scope.CanAdministrate],
      }}
    >
      <React.Fragment>
        <Typography
          mb={2}
          variant="h3"
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          {t("customerportal.pages.content_channels.title")}
        </Typography>
        <ChannelList channels={sortedChannels} onDelete={handleDeleteChannel} />
        <Box>
          <Typography
            mt={2}
            mb={1}
            variant="h6"
            sx={{ display: "flex", justifyContent: "left" }}
          >
            Add new content channel
          </Typography>
          <AddEditChannel
            onAdd={handleAddChannel}
            showPlaylist={true}
            categories={channels
              .map((c) => c.category)
              .filter((v, i, a) => a.indexOf(v) === i)}
          />
        </Box>
      </React.Fragment>
    </PermissionsGate>
  );
};

export default ContentChannels;
