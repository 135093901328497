import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { t } from "i18next";
import { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IContentChannel,
  IDailyContentPlaylist,
  ISignagePlaylist,
  ITimeInterval,
  ITimeLineItem,
  ScheduleContentRefType,
} from "../../../../../types/NendaTypes";
import { store } from "../../store";
import {
  selectError,
  setError,
} from "../../store/reducers/dailyContentPlaylistReducer";
import { SetNotification } from "../../store/reducers/notificationReducer";
import HelpWidget from "../../ui-components/display/HelpWidget";
import TextInput from "../../ui-components/input/TextInput";
import DragAndDropPlaylist from "../ContentChannels/DragAndDropTimeline/DragAndDropPlaylist";
import MobilePlaylistScheduler from "../ContentChannels/DragAndDropTimeline/MobilePlaylistScheduler";
import DailyTimeScale from "./DailyTimeScale";
import { findAvailableSlot } from "./helpers/findSlot";
import { isAllTimeIntervalsValidWithoutOverlaps } from "./helpers/validation";

export const contentTypes = [
  { name: "Content Channels", value: ScheduleContentRefType.CONTENT_CHANNEL },
  { name: "Digital Signage", value: ScheduleContentRefType.SIGNAGE_PLAYLIST },
];

export type ContentCategoryTypes = {
  name: string;
  value: ScheduleContentRefType;
};

const CreateEditPlaylist = ({
  playlistState,
  setPlaylistState,
  signagePlaylists,
  contentChannels,
}: {
  playlistState: IDailyContentPlaylist;
  setPlaylistState: (value: SetStateAction<IDailyContentPlaylist>) => void;
  signagePlaylists: ISignagePlaylist[];
  contentChannels: IContentChannel[];
}) => {
  const error = useSelector(selectError);
  const [selectedCategory, setSelectedCategory] =
    useState<ContentCategoryTypes>(contentTypes[0]);

  useEffect(() => {
    if (playlistState) {
      const isValid = isAllTimeIntervalsValidWithoutOverlaps(
        playlistState.timeline.map((t) => t.interval)
      );
      if (!isValid) {
        store.dispatch(
          setError({
            message: t("customerportal.pages.daily_playlists.overlap_error"),
            type: "info",
          })
        );
      }
      if (isValid && error?.message) {
        store.dispatch(
          setError({
            message: "",
            type: null,
          })
        );
      }
    }
  }, [JSON.stringify(playlistState.timeline)]);

  const firstFreeInterval = findAvailableSlot(
    playlistState?.timeline?.map((t) => t.interval),
    60,
    240
  );
  const handleAddContent = (
    contentId: string,
    contentRefType: ScheduleContentRefType
  ) => {
    if (!firstFreeInterval) {
      return store.dispatch(
        setError({
          message: t("customerportal.pages.daily_playlists.no_free_slots"),
          type: "info",
        })
      );
    }
    setPlaylistState((prevState) => {
      const newTimeline = [...prevState.timeline];
      newTimeline.push({
        content: contentId,
        contentRefType,
        interval: firstFreeInterval as ITimeInterval,
      });
      return { ...prevState, timeline: newTimeline };
    });
    return;
  };

  const handleChangeName = (e) => {
    setPlaylistState((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleTimelineChange = (timeline: ITimeLineItem[]) => {
    setPlaylistState((prevState) => ({
      ...prevState,
      timeline,
    }));
  };
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (error?.message) {
      store.dispatch(SetNotification(error.message, "warning"));
    }
  }, [error]);

  return (
    <Stack gap={"1rem"} sx={{ position: "relative" }}>
      <Box>
        <Box sx={{ px: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={isMobile ? "0" : "1rem"}
            >
              <Typography variant="subtitle1">
                {t("customerportal.pages.daily_playlists.playlist_name")}
              </Typography>
              <TextInput
                variant="standard"
                value={playlistState?.name}
                onChange={handleChangeName}
                sx={{
                  minWidth: "20rem !important",
                  width: isMobile ? "100%" : "20rem",
                }}
                placeholder={t(
                  "customerportal.pages.daily_playlists.playlist_name_placeholder"
                )}
              />
            </Stack>
            <Box sx={{ display: isMobile ? "none" : "block" }}>
              <HelpWidget
                title={t(
                  "customerportal.pages.daily_playlists.helper_widget_title"
                )}
                details={t(
                  "customerportal.pages.daily_playlists.helper_widget_description"
                )}
                placement="bottom-start"
              />
            </Box>
          </Box>
          <Stack direction={"column"} gap="0.5rem">
            <Typography variant="subtitle1">
              {t("customerportal.pages.daily_playlists.timeline_preview")}
            </Typography>

            <Box>
              <Box sx={{ px: "0.5rem" }}>
                <DailyTimeScale />
              </Box>
              {isMobile ? (
                <MobilePlaylistScheduler
                  playlistState={playlistState}
                  setPlaylistState={setPlaylistState}
                  handleAddContent={handleAddContent}
                />
              ) : (
                <DragAndDropPlaylist
                  onTimelineChange={handleTimelineChange}
                  signagePlaylists={signagePlaylists}
                  timeline={playlistState.timeline}
                  contentChannels={contentChannels}
                  onAddContent={handleAddContent}
                  contentCategory={selectedCategory}
                  setContentCategory={setSelectedCategory}
                />
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default CreateEditPlaylist;
