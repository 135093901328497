import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerPortalEpic, CustomerPortalState } from "..";
import {
  ISignagePlaylist,
  LoadingStatus,
  NavigationLevel,
} from "../../../../../types/NendaTypes";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { companyService } from "@client/http/companyService";
import { handleError } from "./errorReducer";
import { of } from "rxjs";
import { organizationUnitService } from "@client/http/organizationUnit.service";
import { SetNotification } from "./notificationReducer";
import { selectCurrentNavigationLevel } from "./workspaceReducer";
import { createSelector } from "@reduxjs/toolkit";

export interface SignagePlaylistState {
  companyPlaylists: ISignagePlaylist[];
  premisePlaylists: ISignagePlaylist[];
  loadingStatus: LoadingStatus;
}

export const initialSignagePlaylistState: SignagePlaylistState = {
  companyPlaylists: [],
  premisePlaylists: [],
  loadingStatus: LoadingStatus.IDLE,
};

const signagePlaylistSlice = createSlice({
  name: "signagePlaylists",
  initialState: initialSignagePlaylistState,
  reducers: {
    getCompanySignagePlaylists(
      _state: SignagePlaylistState,
      _action: PayloadAction<string>
    ) {
      return { ..._state, loadingStatus: LoadingStatus.LOADING };
    },
    getCompanySignagePlaylistsSuccess(
      state: SignagePlaylistState,
      action: { payload: ISignagePlaylist[] }
    ) {
      state.companyPlaylists = action.payload;
      state.loadingStatus = LoadingStatus.SUCCEEDED;
    },
    getCompanySignagePlaylistsFailure(state: SignagePlaylistState) {
      state.loadingStatus = LoadingStatus.FAILED;
    },
    getPremiseSignagePlaylists(
      _state: SignagePlaylistState,
      _action: PayloadAction<string>
    ) {
      return { ..._state, loadingStatus: LoadingStatus.LOADING };
    },
    getPremiseSignagePlaylistsSuccess(
      state: SignagePlaylistState,
      action: { payload: ISignagePlaylist[] }
    ) {
      state.premisePlaylists = action.payload;
      state.loadingStatus = LoadingStatus.SUCCEEDED;
    },
    getPremiseSignagePlaylistsFailure(state: SignagePlaylistState) {
      state.loadingStatus = LoadingStatus.FAILED;
    },
    createCompanySignagePlaylist(
      state: SignagePlaylistState,
      _action: PayloadAction<ISignagePlaylist>
    ) {
      state.loadingStatus = LoadingStatus.LOADING;
    },
    createCompanySignagePlaylistSuccess(
      state: SignagePlaylistState,
      action: { payload: ISignagePlaylist }
    ) {
      state.loadingStatus = LoadingStatus.SUCCEEDED;
      state.companyPlaylists = [...state.companyPlaylists, action.payload];
    },
    createCompanySignagePlaylistFailure(state: SignagePlaylistState) {
      state.loadingStatus = LoadingStatus.FAILED;
    },
    createPremiseSignagePlaylist(
      state: SignagePlaylistState,
      _action: PayloadAction<ISignagePlaylist>
    ) {
      state.loadingStatus = LoadingStatus.LOADING;
    },
    createPremiseSignagePlaylistSuccess(
      state: SignagePlaylistState,
      action: { payload: ISignagePlaylist }
    ) {
      state.loadingStatus = LoadingStatus.SUCCEEDED;
      state.premisePlaylists = [...state.premisePlaylists, action.payload];
    },
    createPremiseSignagePlaylistFailure(state: SignagePlaylistState) {
      state.loadingStatus = LoadingStatus.FAILED;
    },
    updateCompanySignagePlaylist(
      state: SignagePlaylistState,
      _action: PayloadAction<ISignagePlaylist>
    ) {
      state.loadingStatus = LoadingStatus.LOADING;
    },
    updateCompanySignagePlaylistSuccess(
      state: SignagePlaylistState,
      action: { payload: ISignagePlaylist }
    ) {
      state.loadingStatus = LoadingStatus.SUCCEEDED;
      state.companyPlaylists = state.companyPlaylists.map((playlist) =>
        playlist._id === action.payload._id ? action.payload : playlist
      );
    },
    updateCompanySignagePlaylistFailure(state: SignagePlaylistState) {
      state.loadingStatus = LoadingStatus.FAILED;
    },
    updatePremiseSignagePlaylist(
      state: SignagePlaylistState,
      _action: PayloadAction<ISignagePlaylist>
    ) {
      state.loadingStatus = LoadingStatus.LOADING;
    },
    updatePremiseSignagePlaylistSuccess(
      state: SignagePlaylistState,
      action: { payload: ISignagePlaylist }
    ) {
      state.loadingStatus = LoadingStatus.SUCCEEDED;
      state.premisePlaylists = state.premisePlaylists.map((playlist) =>
        playlist._id === action.payload._id ? action.payload : playlist
      );
    },
    updatePremiseSignagePlaylistFailure(state: SignagePlaylistState) {
      state.loadingStatus = LoadingStatus.FAILED;
    },
    deleteCompanySignagePlaylist(
      state: SignagePlaylistState,
      _action: PayloadAction<{ companyId: string; id: string }>
    ) {
      state.loadingStatus = LoadingStatus.LOADING;
    },
    deleteCompanySignagePlaylistSuccess(
      state: SignagePlaylistState,
      action: { payload: string }
    ) {
      state.loadingStatus = LoadingStatus.SUCCEEDED;
      state.companyPlaylists = state.companyPlaylists.filter(
        (playlist) => playlist._id !== action.payload
      );
    },
    deleteCompanySignagePlaylistFailure(state: SignagePlaylistState) {
      state.loadingStatus = LoadingStatus.FAILED;
    },
    deletePremiseSignagePlaylist(
      state: SignagePlaylistState,
      _action: PayloadAction<{ premiseId: string; id: string }>
    ) {
      state.loadingStatus = LoadingStatus.LOADING;
    },
    deletePremiseSignagePlaylistSuccess(
      state: SignagePlaylistState,
      action: { payload: string }
    ) {
      state.loadingStatus = LoadingStatus.SUCCEEDED;
      state.premisePlaylists = state.premisePlaylists.filter(
        (playlist) => playlist._id !== action.payload
      );
    },
    deletePremiseSignagePlaylistFailure(state: SignagePlaylistState) {
      state.loadingStatus = LoadingStatus.FAILED;
    },
  },
});

// Selectors
export const selectSignagePlaylists = (
  state: CustomerPortalState
): ISignagePlaylist[] => {
  return [
    ...state.digitalSignagePlaylists.companyPlaylists,
    ...state.digitalSignagePlaylists.premisePlaylists,
  ];
};
export const selectCompanySignagePlaylists = createSelector(
  (state: CustomerPortalState) =>
    state.digitalSignagePlaylists.companyPlaylists,
  (_: CustomerPortalState, companyId: string) => companyId,
  (companyPlaylists, companyId) =>
    companyPlaylists.filter((playlist) => playlist.company === companyId)
);

export const selectPremiseSignagePlaylists = createSelector(
  (state: CustomerPortalState) =>
    state.digitalSignagePlaylists.premisePlaylists,
  (_: CustomerPortalState, premiseId: string) => premiseId,
  (premisePlaylists, premiseId) =>
    premisePlaylists.filter((playlist) => playlist.premise === premiseId)
);

export const selectSignagePlaylistsByNavigationLevel = createSelector(
  (state: CustomerPortalState) => state,
  (state) => {
    const navigationLevel = selectCurrentNavigationLevel(state);
    const scope = state.workspace.scope;
    const companyPlaylists = scope.company
      ? selectCompanySignagePlaylists(state, scope.company)
      : [];
    const premisePlaylists = scope.premise
      ? selectPremiseSignagePlaylists(state, scope.premise)
      : [];
    return navigationLevel === NavigationLevel.PREMISE
      ? [...companyPlaylists, ...premisePlaylists].flat()
      : companyPlaylists;
  }
);

export const selectSignagePlaylistsLoadingStatus = (
  state: CustomerPortalState
): LoadingStatus => {
  return state.digitalSignagePlaylists.loadingStatus;
};

export const selectSignagePlaylistById = (
  state: CustomerPortalState,
  id: string
): ISignagePlaylist | undefined => {
  return selectSignagePlaylists(state).find((playlist) => playlist._id === id);
};
export const selectSignagePlaylistLoadingStatus = (
  state: CustomerPortalState
): LoadingStatus => {
  return state.digitalSignagePlaylists.loadingStatus;
};

// Epics
const getCompanySignagePlaylists$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(signagePlaylistSlice.actions.getCompanySignagePlaylists.match),
    switchMap((action: PayloadAction<string>) => {
      return companyService.getSignagePlaylists(action.payload).pipe(
        mergeMap((playlists: ISignagePlaylist[]) => {
          return [
            signagePlaylistSlice.actions.getCompanySignagePlaylistsSuccess(
              playlists
            ),
          ];
        }),
        catchError((error: any) => {
          return of([
            signagePlaylistSlice.actions.getCompanySignagePlaylistsFailure(
              error
            ),
            handleError(error),
          ]);
        })
      );
    })
  );
};

const getPremiseSignagePlaylists$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(signagePlaylistSlice.actions.getPremiseSignagePlaylists.match),
    switchMap((action: PayloadAction<string>) => {
      return organizationUnitService.getSignagePlaylists(action.payload).pipe(
        mergeMap((playlists: ISignagePlaylist[]) => {
          return [
            signagePlaylistSlice.actions.getPremiseSignagePlaylistsSuccess(
              playlists
            ),
          ];
        }),
        catchError((error: any) => {
          return of([
            signagePlaylistSlice.actions.getPremiseSignagePlaylistsFailure(
              error
            ),
            handleError(error),
          ]);
        })
      );
    })
  );
};

const updateCompanySignagePlaylist$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(signagePlaylistSlice.actions.updateCompanySignagePlaylist.match),
    switchMap((action: PayloadAction<ISignagePlaylist>) => {
      return companyService.updateSignagePlaylist(action.payload).pipe(
        mergeMap((playlist: ISignagePlaylist) => {
          return [
            signagePlaylistSlice.actions.updateCompanySignagePlaylistSuccess(
              playlist
            ),
            SetNotification("Playlist updated successfully"),
          ];
        }),
        catchError((error: any) => {
          return of([
            signagePlaylistSlice.actions.updateCompanySignagePlaylistFailure(
              error
            ),
            handleError(error),
          ]);
        })
      );
    })
  );
};
const updatePremiseSignagePlaylist$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(signagePlaylistSlice.actions.updatePremiseSignagePlaylist.match),
    switchMap((action: PayloadAction<ISignagePlaylist>) => {
      return organizationUnitService.updateSignagePlaylist(action.payload).pipe(
        mergeMap((playlist: ISignagePlaylist) => {
          return [
            signagePlaylistSlice.actions.updatePremiseSignagePlaylistSuccess(
              playlist
            ),
            SetNotification("Playlist updated successfully"),
          ];
        }),
        catchError((error: any) => {
          return of([
            signagePlaylistSlice.actions.updatePremiseSignagePlaylistFailure(
              error
            ),
            handleError(error),
          ]);
        })
      );
    })
  );
};
const createPremiseSignagePlaylist$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(signagePlaylistSlice.actions.createPremiseSignagePlaylist.match),
    switchMap((action: PayloadAction<ISignagePlaylist>) => {
      return organizationUnitService
        .createSignagePlaylist(action.payload.premise, action.payload)
        .pipe(
          mergeMap((playlist: ISignagePlaylist) => {
            return [
              signagePlaylistSlice.actions.createPremiseSignagePlaylistSuccess(
                playlist
              ),
              SetNotification("Playlist created successfully"),
            ];
          }),
          catchError((error: any) => {
            return of([
              signagePlaylistSlice.actions.createPremiseSignagePlaylistFailure(
                error
              ),
              handleError(error),
            ]);
          })
        );
    })
  );
};
const createCompanySignagePlaylist$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(signagePlaylistSlice.actions.createCompanySignagePlaylist.match),
    switchMap((action: PayloadAction<ISignagePlaylist>) => {
      return companyService.createSignagePlaylist(action.payload).pipe(
        mergeMap((playlist: ISignagePlaylist) => {
          return [
            signagePlaylistSlice.actions.createCompanySignagePlaylistSuccess(
              playlist
            ),
            SetNotification("Playlist created successfully"),
          ];
        }),
        catchError((error: any) => {
          return of([
            signagePlaylistSlice.actions.createCompanySignagePlaylistFailure(
              error
            ),
            handleError(error),
          ]);
        })
      );
    })
  );
};
const deleteCompanySignagePlaylist$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(signagePlaylistSlice.actions.deleteCompanySignagePlaylist.match),
    switchMap((action: PayloadAction<{ companyId: string; id: string }>) => {
      return companyService
        .deleteSignagePlaylist(action.payload.companyId, action.payload.id)
        .pipe(
          mergeMap(() => {
            return [
              signagePlaylistSlice.actions.deleteCompanySignagePlaylistSuccess(
                action.payload.id
              ),
              SetNotification("Playlist deleted successfully"),
            ];
          }),
          catchError((error: any) => {
            return of([
              signagePlaylistSlice.actions.deleteCompanySignagePlaylistFailure(
                error
              ),
              handleError(error),
            ]);
          })
        );
    })
  );
};
const deletePremiseSignagePlaylist$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(signagePlaylistSlice.actions.deletePremiseSignagePlaylist.match),
    switchMap((action: PayloadAction<{ premiseId: string; id: string }>) => {
      return organizationUnitService
        .deleteSignagePlaylist(action.payload.premiseId, action.payload.id)
        .pipe(
          mergeMap(() => {
            return [
              signagePlaylistSlice.actions.deletePremiseSignagePlaylistSuccess(
                action.payload.id
              ),
              SetNotification("Playlist deleted successfully"),
            ];
          }),
          catchError((error: any) => {
            return of([
              signagePlaylistSlice.actions.deletePremiseSignagePlaylistFailure(
                error
              ),
              handleError(error),
            ]);
          })
        );
    })
  );
};

export const signagePlaylistEpics = [
  getCompanySignagePlaylists$,
  getPremiseSignagePlaylists$,
  updateCompanySignagePlaylist$,
  updatePremiseSignagePlaylist$,
  createPremiseSignagePlaylist$,
  createCompanySignagePlaylist$,
  deleteCompanySignagePlaylist$,
  deletePremiseSignagePlaylist$,
];
export const {
  getCompanySignagePlaylists,
  getPremiseSignagePlaylists,
  updateCompanySignagePlaylist,
  updateCompanySignagePlaylistFailure,
  updateCompanySignagePlaylistSuccess,
  updatePremiseSignagePlaylist,
  updatePremiseSignagePlaylistFailure,
  updatePremiseSignagePlaylistSuccess,
  createCompanySignagePlaylist,
  createPremiseSignagePlaylist,
  deleteCompanySignagePlaylist,
  deletePremiseSignagePlaylist,
} = signagePlaylistSlice.actions;

export default signagePlaylistSlice.reducer;
