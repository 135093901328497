import { Box } from "@mui/material";
import CircularLoadingIndicator from "./CircularLoadingIndicator";

const CenteredLoadingIndicator = () => {
  return (
    <Box
      style={{
        position: "absolute",
        left: "50%",
        top: "50vh",
        transform: " translate(-50%, -50%)",
      }}
    >
      <CircularLoadingIndicator />
    </Box>
  );
};

export default CenteredLoadingIndicator;
